/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import useAxios from "axios-hooks";
import React, { useContext, useEffect, useState } from "react";
import { getLangCode } from "../../Helpers/getLangCode";
import { Context } from "../../Context";
import TabMenu from "../../TabMenu";
import { useLangTabs } from "./tabs";
import { BASE_URL } from "../../constants";
import Footer from "../../Footer";
import { headerStyle, style } from "./style";
import GoBack from "../../UI/GoBack";
import GoalKeeper from "./Positions/GoalKeeper";
import Defender from "./Positions/Defenders";
import Midfielders from "./Positions/Midfielders";
import Forwards from "./Positions/ForwardPlayers";
import spinner from'../../../assets/images/loading.gif'

function Players({ headless = false }) {
  const {
    language: { language },
  } = useContext(Context);
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  const [filter, setFilter] = useState("all");
  const tabs = useLangTabs();
  const langCode = getLangCode(language);
  const [players, setPlayers] = useState([]);
  const [goalKeepers, setGoalkeepers] = useState([]);
  const [hoverStates, setHoverStates] = useState({});
  const [playerData, setPlayerData] = useState();
  const [categoryId, setCategoryId] = useState("1");
  // const [loading, setLoading] = useState(false);
  const transformed = false;
  let [{ data, loading }] = useAxios({
    url: BASE_URL + `/players?countryid=${langCode}`,
    // url: BASE_URL + `/players?countryid=${langCode}&view=GROUP`,
    method: "GET",
    responseType: "json",
  });

  // let [{ dataFem }] = useAxios({
  //   url: BASE_URL + `/players?countryid=${langCode}&category_id=`,
  //   method: "GET",
  //   responseType: "json",
  // });

  useEffect(() => {
    console.log("loading", loading);
  }, [loading]);

  // useEffect(async () => {
  //   setLoading(true);
  //   const response = await axios
  //     .get(`${BASE_URL}players?countryid=${langCode}`)
  //     .then((res) => setPlayerData(res.data))
  //     .then(() => setLoading(false))
  //     .catch((err) => console.log(err));
  //   return response;
  // }, []);

  // Maneja el estado cuando cambia data o filter
  useEffect(() => {
    window.scrollTo(0, 0);
    // Si tenemos data del backend
    if (data) {
      // Creamos un array de objetos con el estado de hover de cada player
      setHoverStates(() => {
        let hoverStore = {};
        data.map((player) => {
          hoverStore[player.id] = false;
        });
        return hoverStore;
      });

      // Copiamos en tempdata el data recibido del servidor para no mutarlo sin querer
      let tempData = data;
      // let tempDataFem = dataFem;
      // Actuamos en base al filtro actual
      switch (filter) {
        case "all":
          setCategoryId("1");
          setPlayers(
            tempData.sort((a, b) => {
              if (a.name + a.surname > b.name + b.surname) {
                return 1;
              }
              if (b.name + b.surname > a.name + a.surname) {
                return -1;
              }
              return 0;
            })
          );
          break;

        case "feminine":
          setCategoryId("2");

          setPlayers(
            tempData.sort((a, b) => {
              if (a.name + a.surname > b.name + b.surname) {
                return 1;
              }
              if (b.name + b.surname > a.name + a.surname) {
                return -1;
              }
              return 0;
            })
          );
          break;

        default:
          break;
      }
      tempData = data;
    }
  }, [data, filter]);

  useEffect(() => {
    console.log("players", players);
  }, [players]);


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simula una carga asincrónica del componente
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);


  return data ? (
    <>
      <div className="playersHeader" css={headerStyle}>
        <div className="playersHeaderImage">
          <div className="playerHeaderTitle">
            <span className="span">{dictionary.players_title}</span>
          </div>
        </div>
        <div className="playersBackgroundImg">


          <>
          <div className="players" style={{minHeight:"300px"}} css={style}>
  {isLoading ? (
    <img src={spinner} className="spinner-container" alt="Loading spinner"  height={300}/>
  ) : (
    <>
      <GoalKeeper
        
        players={players.filter((item) => item.position === "goalkeeper")}
        hoverStates={hoverStates}
        setHoverStates={setHoverStates}
        data={data}
        loading={loading}
      />
      <div style={{ height: 140 }} />
      {players.filter((item) => item.position === "defender").length > 0 && (
        <>
          <Defender
            players={players.filter((item) => item.position === "defender")}
            hoverStates={hoverStates}
            setHoverStates={setHoverStates}
            data={data}
            loading={loading}
          />
          <div style={{ height: 140 }} />
        </>
      )}
      {players.filter((item) => item.position === "midfielder").length > 0 && (
        <>
          <Midfielders
            players={players.filter((item) => item.position === "midfielder")}
            hoverStates={hoverStates}
            setHoverStates={setHoverStates}
            data={data}
            loading={loading}
          />
          <div style={{ height: 140 }} />
        </>
      )}
      {players.filter((item) => item.position === "forward").length > 0 && (
        <>
          <Forwards
            players={players.filter((item) => item.position === "forward")}
            hoverStates={hoverStates}
            setHoverStates={setHoverStates}
            data={data}
            loading={loading}
          />
          <div style={{ height: 100 }} />
        </>
      )}
    </>
  )}
          </div>
</>

            

        </div>
      </div>

      <Footer />
    </>
  ) : null;
}

export default Players;
