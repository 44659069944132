import { css } from "@emotion/react";
import { flexGap } from "../../../Helpers/flexGap";

const achievementsStyle = (t) => css`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${t.backgroundColorHigh};
  border-radius: ${t.borderRadius};
  box-shadow: ${t.defaultShadow};
  padding: 2rem;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;

  .category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    transition: ${t.transition};
    :hover {
      background-color: ${t.dark
        ? t.backgroundColorLighter
        : t.inputBackgroundColor};
      border-radius: ${t.borderRadius};
    }
  }
  .layover {
    position: absolute;
    width: 100%;
    top: 0;
    padding: 2rem;
    background-color: ${t.backgroundColorHigh};

    svg {
      cursor: pointer;
      fill: ${t.inputBackgroundColor};
      transition: ${t.transition};
      width: 100%;
      height: 100%;
      max-height: 30px;
      max-width: 30px;
      position: absolute;
      right: 1rem;
      top: 1rem;
      :hover {
        fill: ${t.accentColor2};
      }
    }

    & > ul {
      & > li {
        margin-bottom: 1rem;
        :last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .inner-achievements {
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  .title-row {
    display: flex;
    justify-content: center;
    h2 {
      margin: 0;
    }
    svg {
      flex: 1 0 50px;
      fill: ${t.accentColor2};
      width: 100%;
      height: 100%;
      max-width: 50px;
      max-height: 50px;
      margin-right: 1rem;
    }
  }
`;
export default achievementsStyle;
