import { css } from "@emotion/react";
import { flexGap } from "./Helpers/flexGap";

export const style = (theme) => css`
  ${flexGap("1rem")}
  justify-content: center;
  /* width: 100%; */
  /* min-width: max-content; */
  /* margin-top: 1rem; */
  .tab {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: ${theme.borderRadius};
    background-color: ${theme.inputBackgroundColor};
    padding: 0.5rem 1rem;
    width: fit-content;
    /* max-width: fit-content; */
    transition: all ease-in-out 0.3s;
    svg {
      fill: ${theme.textColor};
      height: 35px;
      width: 35px;
      max-width: fit-content;
      margin-right: 1rem;
    }
    :hover {
      background-color: ${theme.inputHoverColor};
      transform: translateY(-5px);
    }
    p {
      margin: 0;
      //active-filter shifting on making the font bold fix
      ::before {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }
  .active-tab {
    background-color: ${theme.accentColor2};

    p {
      color: white;
      font-weight: bold;
    }
    svg {
      fill: white;
      overflow: visible;
      /* stroke-width: 10px; */
      stroke: white;
    }
    :hover {
      background-color: ${theme.accentColor2};
      transform: translateY(0);
    }
  }
`;
