/** @jsxRuntime classic */
/** @jsx jsx */

import CountryFlag from "./CountryFlag";
import { languageSelect } from "./topbarStyle";
import { jsx } from "@emotion/react";
import { useContext, useState, useRef } from "react";
import { Context } from "../Context";
import { useLocalStorage, useClickAway } from "react-use";
import { useTransition, animated } from "@react-spring/web";
import loadLanguage from "../Helpers/useLoadLanguage";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { fadeTransition } from "../Helpers/transitions";

function LanguageSelect() {
  const [localLanguage, setLocalLanguage] = useLocalStorage("language");
  const {
    language: { language, setLanguage },
    dictionary: { setDictionary },
  } = useContext(Context);

  const [languageOpen, setLanguagee] = useState(false);
  const transition = useTransition(languageOpen, fadeTransition);
  const languages = [
    { value: "English", txt: "English" },
    { value: "Spanish", txt: "Español" },
    { value: "Italian", txt: "Italiano" },
    { value: "French", txt: "Français" },
    { value: "Portuguese", txt: "Português" },
    { value: "German", txt: "Deutsche" },
    { value: "Chinese", txt: "中国" },
    { value: "Japanese", txt: "日本語" },
    { value: "Russian", txt: "Русский" },
  ];
  const ref = useRef();
  // //this click away is not workin
  // useClickAway(ref, () => {
  //   //setOpen(!isOpen);
  //   setLanguagee((prev) => !prev);
  // });
  // console.log(languageOpen, "lan open");
  function test_lang(lang) {
    let lenguaje;
    languages.map(({ value, txt }) => {
      if (lang == value) {
        return (lenguaje = txt);
      }
    });
    return lenguaje;
  }

  return (
    <div role="button" css={languageSelect} tabIndex="0">
      <div
        className="current-language"
        onClick={() => {
          setLanguagee(!languageOpen);
        }}
      >
        {
          <CountryFlag
            withAnimation={false}
            lang={language}
            key={language}
            height="15"
          ></CountryFlag>
        }{" "}
        &nbsp;
        <span
          style={{
            color: "#79c942",
          }}
        ></span>
        {languageOpen ? (
          <GoChevronDown></GoChevronDown>
        ) : (
          <GoChevronUp></GoChevronUp>
        )}
      </div>
      {transition(
        (style, state) =>
          state && (
            <animated.div style={style} className="options-menu">
              {languages.map(({ value, txt }) => {
                if (value === language) {
                  return;
                }
                return (
                  <div
                    tabIndex="0"
                    key={value}
                    ref={ref}
                    className="option"
                    onClick={() => {
                      setLanguagee(false);
                      setLanguage(value);
                      setLocalLanguage(value);
                      setDictionary(loadLanguage(value));
                    }}
                  >
                    <CountryFlag
                      withAnimation={false}
                      lang={value}
                      height="15"
                    ></CountryFlag>
                    {txt}
                  </div>
                );
              })}
            </animated.div>
          )
      )}
    </div>
  );
}

export default LanguageSelect;
