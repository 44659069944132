/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { homeStyle } from "./homeStyle";
import { Link } from "react-router-dom";
import heroImgDark from "../../../assets/images/hero_dark.jpg";
import adrianImg from "../../../assets/images/adrian.png";
import fenixImg from "../../../assets/images/fenix.png";
import { ReactComponent as QuotesIcon } from "../../../assets/reviews/quotes.svg";
import FifaLogo from "../../../assets/fifa/FIFA-Logo.webp";
// import { ReactComponent as RFEFLogo } from "../../../assets/SVG/rfefLogo.svg";
import { ReactComponent as EliteLogo } from "../../../assets/SVG/logo.svg";
// import useServicesData from "../Services/useServicesData";
import defaultImage from "../../../assets/coaches_shadow/shadow_juan_carlos.png";
import defaultImage2 from "../../../assets/players_shadow/player.png";
// import ServiceCard from "../Services/ServiceCard";
import spinner from "../../../assets/images/loading.gif";
import useAxios from "axios-hooks";
import { BASE_URL, BASE_URL_IMG } from "../../constants";
import { getLangCode } from "../../Helpers/getLangCode";
import { Context } from "../../Context";
import React, { useContext, useEffect, useState } from "react";
import LazyComponent from "./LazyComponent";
import LoadingImg from "../../UI/LoadingImg";
import useMasonrySpring from "../../Hooks/useMasonrySpring";
import PlayerCard from "../Players/PlayerCard";
import { style } from "../Players/style";
// import { Carousel } from "./Carousel";
import Cards from "./Card";
import axios from "axios";

function Home() {
  const {
    language: { language },
    // theme: { theme },
    dictionary: { dictionary },
  } = useContext(Context);
  const langCode = getLangCode(language);
  // const services = useServicesData();
  const [players, setPlayers] = useState([]);
  const [playerData, setPlayerData] = useState();
  const [loading, setLoading] = useState(false);
  let [{ data: coaches }] = useAxios({
    url: BASE_URL + `/coaches?countryid=${langCode}`,
    method: "GET",
    responseType: "json",
  });

  const getPlayerData = async () => {
    await axios
      .get(`${BASE_URL}players?countryid=${langCode}`)
      .then((res) => setPlayerData(res.data))
      .then(() => setLoading(false))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPlayerData();
  }, []);

  // useEffect(async () => {
  //   setLoading(true);
  //   const response = await axios
  //     .get(`${BASE_URL}players?countryid=${langCode}`)
  //     .then((res) => setPlayerData(res.data))
  //     .then(() => setLoading(false))
  //     .catch((err) => console.log(err));
  //   return response;
  // }, []);
  let [{ data }] = useAxios({
    url: BASE_URL + `/players?countryid=${langCode}`,
    method: "GET",
    responseType: "json",
  });

  let tempData = data;
  useEffect(() => {
    if (data) {
      setPlayers(tempData.map((player) => player));
    }
  }, [data, tempData]);
  //scroll to top when loading
  // window.scrollTo(0, 0);
  const transformed = true;
  const [ref, heights, transition, containerHeight] = useMasonrySpring(
    players,
    400,
    25
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simula una carga asincrónica del componente
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <div css={homeStyle}>
        <div className="your-agency">
          <h1>{dictionary.home_title}</h1>
          <h3>{dictionary.home_subtitle}</h3>
          <div className="img-container">
            <img src={heroImgDark} alt="" />
          </div>
        </div>

        <div className="no-limits">
          <LazyComponent animationName={"fadeInLeft"}>
            <div className="our-services">{dictionary.home_services_ours}</div>
            <div className="services-container">
              <div className="services">
                <Cards />
              </div>
            </div>
          </LazyComponent>
        </div>

        <div className="academy-phone">
          <LazyComponent animationName={"fadeInLeft"}>
            <div className="acad-wrapper">
              <div className="acad-style1 acad-up">
                <span className="acad-header">
                  {dictionary.home_academy_header}
                </span>
                <br />
                <span className="acad-style2">
                  ELITE SOCCER 18
                  <span className="greeny">
                    {" "}
                    <i> ACADEMY</i>
                  </span>
                </span>
                <span className="acad-style1">
                  <br />
                  VALENCIA
                </span>
              </div>

              <div className="acad-style1 down">
                <a
                  href="https://academy.elitesoccer18.com"
                  target="_blank"
                  className="button join"
                >
                  {dictionary.home_academy_join}
                </a>
              </div>
              <div className="acad-style1 deepdown">
                {dictionary.home_academy_slogan1}
                <br />
                {dictionary.home_academy_slogan2}
                <span className="greeny">
                  {" "}
                  {dictionary.home_academy_slogan3}
                </span>
                .
              </div>
            </div>
          </LazyComponent>
        </div>

        <div className="academy">
          <LazyComponent animationName={"fadeInLeft"}>
            <div className="upper-acad-container">
              <div className="acad-title">
                <span className="acad-style1">
                  {dictionary.home_academy_header}
                </span>
                <div className="acad-style2">
                  ELITE SOCCER 18
                  <span className="greeny">
                    <i> ACADEMY</i>
                  </span>
                </div>{" "}
                <span className="acad-style1 valencia">VALENCIA</span>
              </div>
            </div>

            <div className="lower-acad-container">
              <div className="lower-phrase">
                <span className="acad-style1">
                  {dictionary.home_academy_slogan1} <br />{" "}
                  {dictionary.home_academy_slogan2}{" "}
                  <span className="greeny">
                    {dictionary.home_academy_slogan3}
                  </span>
                  .
                </span>
                <br />
                <br />
              </div>
              <div className="but-acad">
                <a
                  href="https://academy.elitesoccer18.com"
                  target="_blank"
                  className="button join"
                  rel="noreferrer"
                >
                  {dictionary.home_academy_join}
                </a>
              </div>
            </div>
            {/*             <div className="scc18-container">
              <img src={logo} alt="SCC18" />
            </div>
            <div className="acad-wrapper">
              <div className="acad-style1">
                Football Soccer Academy in Spain
                <span className="acad-style2">ELITE SOCCER 18 ACADEMY</span>
              </div>
              <div className="acad-style1 down">VALENCIA</div>
              <div className="acad-style1 deepdown">
                We train you.
                <br /> We find you
                <span className="pro-clubs"> Pro Clubs in Spain</span>.
                <Link to="/" className="button join">
                  Join
                </Link>
              </div>
            </div> */}
          </LazyComponent>
        </div>

        {/* <div className="players" css={style}>
          <div className="inner"> */}

        <div className="our-coaches2">
          <div className="inner">
            <h1 className="title-players">
              {dictionary.home_coaches_title}{" "}
              <span style={{ color: "#79c942" }}>
                {dictionary.home_players}
              </span>
            </h1>

            <div className="spinner">
              {isLoading ? (
                <img src={spinner} alt="Loading spinner" />
              ) : (
                <div className="players">
                  {players &&
                    players.map((player) => {
                      let name = player.name.trim();
                      name = name.replace(/\s/g, "");
                      let surname = player.surname.trim();
                      surname = surname.replace(/\s/g, "");
                      return (
                        <Link
                          key={player.id}
                          className="player-card"
                          to={`/players/${player.id}-${name}-${surname}#playerProfile`}
                        >
                          <div
                            className="text-container"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="coachName">
                              {" "}
                              <span>{player.name}</span>{" "}
                            </div>
                            <div className="coachSurname">
                              {" "}
                              <span>{player.surname}</span>
                            </div>
                          </div>
                          <div className="player-container">
                            <LoadingImg
                              baseUrl={
                                player.img_profile_url
                                  ? BASE_URL_IMG + player.img_profile_url
                                  : defaultImage2
                              }
                            />
                          </div>
                        </Link>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="our-coaches">
          <div className="inner">
            <h1 className="title-coaches">
              {dictionary.home_coaches_title}{" "}
              <span style={{ color: "#79c942" }}>
                {dictionary.home_coaches}
              </span>
            </h1>

            <div className="spinner">
              {isLoading ? (
                <img src={spinner} alt="Loading spinner" />
              ) : (
                <div className="players">
                  {coaches &&
                    coaches.map((coach) => {
                      let name = coach.name.trim();
                      name = name.replace(/\s/g, "");
                      let surname = coach.surname.trim();
                      surname = surname.replace(/\s/g, "");
                      return (
                        <Link
                          key={coach.id}
                          className="player-card"
                          to={`/coaches/${coach.id}-${name}-${surname}#coachProfile`}
                        >
                          <div
                            className="text-container"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="coachName">
                              {" "}
                              <span>{coach.name}</span>{" "}
                            </div>
                            <div className="coachSurname">
                              {" "}
                              <span>{coach.surname}</span>
                            </div>
                          </div>
                          <div className="coach-container">
                            <LoadingImg
                              baseUrl={
                                coach.img_profile_url
                                  ? BASE_URL_IMG + coach.img_profile_url
                                  : defaultImage
                              }
                            />
                          </div>
                        </Link>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="quote">
          <LazyComponent animationName={"fadeInRight"}>
            <div className="inner">
              <div className="img-container">
                <img src={adrianImg} alt="Adrian Márquez Valía" />
              </div>
              <hr />
              <figure>
                <blockquote>
                  <p>{dictionary.home_quote}</p>
                  <QuotesIcon></QuotesIcon>
                </blockquote>

                <figcaption>
                  <p className="adrianName">Adrián Márquez Valía</p>
                  <p className="adrianCargo">{dictionary.home_adrian_place}</p>
                </figcaption>
                <Link to="/about-us" className="button">
                  {dictionary.home_about_us_btn}
                </Link>
              </figure>
            </div>
          </LazyComponent>
        </div>

        <div className="rfefWrapper">
          <div className="textWrapper">
            <span className="top">{dictionary.home_ball_title1}</span>

            <div className="rfef">
              <a
                className="inner column"
                href="https://agents.fifa.com/home"
                target="_blank"
                rel="noreferrer"
              >
                <div className="title">
                  <img src={FifaLogo} style={{ height: "200px" }} />

                  <h2>
                    Agente Oficial <nobr>FIFA</nobr>
                  </h2>
                </div>

                <div className="info">
                  <div className="text">
                    <h3>
                      <b>Elite Sports Soccer 18</b>
                    </h3>
                    {/* <h3>Adrián Márquez Valía</h3> */}
                  </div>
                  <div className="dates">
                    <div>
                      <p>{dictionary.home_begin_license}</p>
                      <p>01/10/2023</p>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <p>ID 202309-3775</p>
                  {/* <p>{dictionary.home_intermediaries}</p> */}
                  <p>Adrián Márquez Valía</p>
                  {/* <img src={fenixImg} loading="lazy" alt="Fénix Logo" /> */}
                </div>
              </a>
            </div>
            <span className="bottom">{dictionary.home_ball_title2}</span>
          </div>
        </div>

        <div className="shout-out">
          <LazyComponent animationName={"fadeInRight"}>
            <a
              href="https://www.elitesports17.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="inner column">
                <div className="innerText">
                  <h2>{dictionary.home_link_17_title}</h2>
                </div>

                <div className="innerLinkText">
                  <p>{dictionary.home_link_17_txt}</p>
                </div>
              </div>

              <div className="img-container">
                <EliteLogo></EliteLogo>
              </div>
            </a>
          </LazyComponent>
        </div>
      </div>
    </>
  );
}

export default Home;
