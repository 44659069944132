import { css } from "@emotion/react";

export const navbarHover = (hover, height) =>
  hover
    ? (theme) => css`
        transition: ${theme.transition};
        top: ${height}px;
      `
    : null;

export const navbarDynamic = (compact, height, home) => (theme) =>
  css`
    top: ${compact ? 0 : height}px;
    position: fixed;
    transition: ${theme.transition};
    background-color: ${compact
      ? theme.backgroundColor
      : home
      ? "transparent"
      : theme.backgroundColor};
    border-bottom: ${compact
      ? `2px solid ${theme.inputBackgroundColor}`
      : `2px solid ${theme.inputBackgroundColor}00`};

    nav {
      ${home &&
      css`
        a {
          color: ${compact ? theme.textColor : "white"} !important;
        }
      `}
      .logo {
        transition: ${theme.transition};
        max-width: ${compact ? "85px" : "125px"};
      }
    }
  `;

export const headerStyle = (theme) => css`
  label: header;
  height: 300px;
  position: relative;
  z-index: 2;
  transition: ${theme.transition};

  &.home {
    width: 100%;
    background-color: transparent;

    .mobile-nav {
      transition: ${theme.transition};

      background-image: linear-gradient(
        0deg,
        ${theme.backgroundColorLighter} 0%,
        transparent 100%
      );
      background-repeat: no-repeat;
      background-position-y: 100%, bottom;
      background-size: 100% 0%;
      &.open {
        transition: ${theme.transition};
        background-size: 100% 100%;
        .navBar {
          a {
            color: ${theme.textColor};
          }
        }
        .hamburger-react {
          color: ${theme.textColor};
        }
      }
    }

    a {
      color: ${theme.textColor};
      transition: max-width ease-in-out 0.3s;

      svg {
        .svg-fill {
          fill: white;
          &--inverse {
            fill: black;
          }
        }
      }
    }
    .nav-container {
      .hamburger-react {
        color: ${theme.textColor};
      }
    }
  }
`;

export const navigationStyle = (theme) => css`
  label: nav-bar;
  width: 100%;
  z-index: 1;

  nav {
    width: 100%;
    max-width: ${theme.contentMaxWidth};
    padding: 0 ${theme.padding};
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: ${theme.transition};
  }
  a {
    color: ${theme.textColor};
    position: relative;
    text-decoration: none;
  }
`;

const transition = "all ease-in-out .2s";
export const mobileNavbarHover = (hover, height) =>
  hover
    ? (theme) => css`
        transition: ${theme.transition};
        top: ${height}px;
      `
    : null;
export const mobileNavbarDynamic = (compact, height, home) => (theme) =>
  css`
    top: ${compact ? 0 : height}px;
    border: solid red 0px;

    position: fixed;
    transition: ${theme.transition};
    background-color: ${compact ? theme.backgroundColor : "transparent"};
    border-bottom: ${compact
      ? `4px solid ${theme.inputBackgroundColor}`
      : `4px solid ${theme.inputBackgroundColor}00`};

    .top-bar {
      ${home &&
      css`
        a {
          color: ${compact ? theme.textColor : "white"} !important;
        }
        .hamburger-react {
          color: ${compact ? theme.textColor : "white"} !important;
        }
      `}
      .logo {
        transition: ${theme.transition};
        max-width: ${compact ? "85px" : "125px"};
      }
    }
  `;

export const mobileNavBar = (theme) => css`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  label: mobile-nav;
  .top-bar {
    z-index: 2;
    padding: 0 ${theme.padding};
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 700px) {
      padding: 0 ${theme.paddingM};
    }
  }
  .navBar {
    position: absolute;
    width: 100%;
    top: 45%;
    left: 0;
    height: auto;
    padding: 25px 0;
    padding-top: 10%;
    background-image: linear-gradient(
      0deg,
      ${theme.backgroundColorLighter} 0%,
      ${theme.backgroundColorLighter} 90%,
      transparent 100%
    );
    overflow: hidden;
  }
`;
