/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { phoneStyle } from "./style";
import "react-phone-input-2/lib/style.css";
import CustomPhoneInput from "./CustomPhoneInput";
import { useContext } from "react";
import { Context } from "../../Context";
function Phone() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  return (
    <div css={phoneStyle}>
      <h3>{dictionary.contact_call_us}</h3>
      <a href="tel:+34641880697" className="telLink">
        +34 641 880 697
      </a>
      <div className="call-you-container">
        <h3>{dictionary.contact_want_us_to_call}</h3>
        <form action="">
          <CustomPhoneInput withLabel={false} />
          <button type="submit">
            {dictionary.contact_want_us_to_call_btn}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Phone;
