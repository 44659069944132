/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import { buttonStyle, buttonTypes } from "./buttonStyle";

function Button({ type, link, children, clickHandle }) {
  return (
    // href={`${link}`}
    <a
      {...(clickHandle ? { onClick: clickHandle } : null)}
      {...(link ? { href: `${link}` } : null)}
      css={[buttonStyle, buttonTypes[type]]}
    >
      <div>{children}</div>
    </a>
  );
}

export default Button;
