import { useContext, useEffect, useState } from "react";
import {
  RiAuctionFill,
  RiBodyScanFill,
  RiBriefcase4Fill,
  RiCopyrightFill,
  RiFootballFill,
  RiHospitalFill,
  RiNewspaperFill,
  RiTimeFill,
  RiVideoFill,
} from "react-icons/ri/";
import { Context } from "../../Context";

function useServicesData() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      {
        title: dictionary.services_legal_advisory_title,

        icon: RiAuctionFill,
        size: "5rem",
        text_light: dictionary.services_legal_advisory_txt_light,
      },
      {
        title: dictionary.services_sports_advice_title,

        icon: RiFootballFill,
        size: "5rem",
        text_light: dictionary.services_sports_advice_txt_light,
      },
      {
        title: dictionary.services_representation_title,

        icon: RiBodyScanFill,
        size: "5rem",
        text_light: dictionary.services_representation_txt_light,
      },
      {
        title: dictionary.services_profesionalism_title,

        icon: RiBriefcase4Fill,
        size: "5rem",
        text_light: dictionary.services_profesionalism_txt_light,
      },
      {
        title: dictionary.services_image_rights_title,

        icon: RiCopyrightFill,
        size: "5rem",
        text_light: dictionary.services_image_rights_txt_light,
      },
      {
        title: dictionary.services_marketing_title,

        icon: RiNewspaperFill,
        size: "5rem",
        text_light: dictionary.services_marketing_txt_light,
      },
      {
        title: dictionary.services_availability_title,

        icon: RiTimeFill,
        size: "5rem",
        text_light: dictionary.services_availability_txt_light,
      },
      {
        title: dictionary.services_medical_service_title,

        icon: RiHospitalFill,
        size: "5rem",
        text_light: dictionary.services_medical_service_txt_light,
      },
      {
        title: dictionary.services_video_title,

        icon: RiVideoFill,
        size: "5rem",
        text_light: dictionary.services_video_txt_light,
      },
    ]);
  }, [dictionary]);
  return data;
}

export default useServicesData;
