import { css } from "@emotion/react";

export const partnerStyles = (theme) => css`
  margin-top: -2rem;
  margin: auto;
  width: 92%;
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
  }
  .slick-next:before {
    margin-left: -5px;
  }
  .right-arrow,
  .left-arrow {
    color: rgba(5, 116, 187);
    font-size: 40px;
    background-color: white;
    border-radius: 50%;
    padding: 0.6rem;
    transition: transform 0.3s ease-out;
    border: 1px solid;
  }

  .right-arrow:hover,
  .left-arrow:hover {
    transform: scale(130%);
    background-color: white;
  }

  .partners {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 2px;
    padding-bottom: 30px;
  }
  .slick-slider {
    width: 70rem;
    margin: auto;
  }
  .MuiButtonBase-root:hover,
  .MuiButtonBase-root:active {
    background-color: transparent;
  }
  .MuiTouchRipple-root {
    overflow: visible;
  }
  button:active {
    box-shadow: none !important;
    transform: none;
    outline: none;
  }

  .slick-dots {
    width: 100%;
  }
  .slick-dots li {
    width: 20px;
  }
  .slick-dots button {
    width: 50px;
    padding: 30px;
    font-size: 0;
  }
  .slick-dots li button:before {
    font-size: 12px;
    line-height: 30px;
    color: ${theme.dark ? "#689DDE" : "#313841"};
  }
  .slick-dots li.slick-active button:before {
    color: ${theme.dark ? "#689DDE" : "#313841"};
  }

  @media (max-width: 1300px) {
    a {
      margin: 0;
      padding: 0;
    }
    .partners {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
    .slick-next:before {
      margin-left: -10px;
    }
    .slick-slider {
      margin: auto;
      width: 40rem;
    }
  }

  @media (max-width: 700px) {
    .partners {
      width: 100%;
      min-width: 100vw;
      margin: auto;
      margin: -0.5rem;
    }
    .right-arrow,
    .left-arrow {
      color: rgba(5, 116, 187);
      font-size: 20px;
      background-color: white;
      border-radius: 50%;
      padding: 0.2rem;
    }
    .left-arrow {
      margin-right: -1rem;
    }
    .right-arrow {
      margin-left: -1rem;
    }
    .slick-slider {
      margin-left: -1rem;
      padding: 0;
    }
    .slick-dots li button:before {
      font-size: 12px;
      line-height: 60px;
    }
  }
`;
