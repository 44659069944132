/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import React, { useContext, useState } from "react";
import Email from "./Email";
import Phone from "./Phone";
import { contactContainer, saver } from "./style";
import { Context } from "../../Context";
import TabMenu from "../../TabMenu";
import { RiMailFill } from "react-icons/ri";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
  AiFillLinkedin,
  AiFillYoutube,
  AiFillClockCircle,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { GoLocation } from "react-icons/go";
import { FaPhoneAlt } from "react-icons/fa";
import { useLocalStorage } from "react-use";

function ContactUs() {
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage(
    "cookiesAccepted",
    null
  );
  const {
    dictionary: { dictionary },
  } = useContext(Context);

  let data = [
    {
      value: "Email",
      name: dictionary.contact_email,
      icon: <RiMailFill />,
    },
  ];

  window.scrollTo(0, 0);

  

  const [selectedTab, setTab] = useState("Email");
  function SwitchTab(selectedTab) {
    switch (selectedTab) {
      case "Email":
        return <Email></Email>;
      // case "Phone":
      //   return <Phone></Phone>;
      default:
        break;
    }
  }
  return (
    <>
      <div className="saver" css={saver}></div>
      <div css={contactContainer}>
        <div className="contactUsHeaderImg">
          <div className="contactUsHeaderTitle">
            <span className="span">{dictionary.contact_title}</span>
          </div>
        </div>
        {/* <TabMenu current={selectedTab} setMenu={setTab} tabs={data} /> */}

        <div className="contactUsWrapper">
          {SwitchTab(selectedTab)}

          <div className="contactUsInner">
            <div className="contactUsInfo">
              <div className="contactUsText">
                <h3 style={{ color: "#fff" }}>{dictionary.contact_adress}</h3>
                <div className="contactUsAddress">
                  <GoLocation /> C/Daniel Balaciart 2, <br />
                  &nbsp; &nbsp; &nbsp;{dictionary.contact_oficina} 12,
                  <br />
                  &nbsp; &nbsp;&nbsp;46020, Valencia.
                </div>

                <div className="contactUsPhone">
                  <FaPhoneAlt /> +34 641 88 06 97
                </div>

                <div className="contactUsHorario">
                  <p>
                    <AiFillClockCircle /> {dictionary.date_open_min}
                  </p>
                </div>
              </div>

              <div className="social-links">
                <p>{dictionary.follow_us}</p>
                <div>
                  <Link
                    to={{ pathname: "https://www.facebook.com/EliteSoccer18" }}
                    target="_blank"
                  >
                    <AiFillFacebook 
                    onMouseOver={({target})=>target.style.color= "#0078FF"}      
                    onMouseOut={({target})=>target.style.color="white"} 
                    className="social-media-icons" />
                  </Link>
                  <Link
                    to={{
                      pathname:
                        "https://www.instagram.com/elitesports17sl/?hl=es",
                    }}
                    target="_blank"
                  >
                    <AiFillInstagram 
                    onMouseOver={({target})=>target.style.color= "#F58529"}      
                    onMouseOut={({target})=>target.style.color="white"}                
                    className="social-media-icons" />
                  </Link>
                  <Link
                    to={{ pathname: "https://twitter.com/elitesports17" }}
                    target="_blank"
                  >
                    <AiFillTwitterSquare 
                    onMouseOver={({target})=>target.style.color="#1DA1F2"}
                    onMouseOut={({target})=>target.style.color="white"}  
                    className="social-media-icons" />
                  </Link>

                  <Link
                    to={{
                      pathname:
                        "https://www.linkedin.com/company/elite-sports-17-sl/?originalSubdomain=es",
                    }}
                    target="_blank"
                  >
                    <AiFillLinkedin 
                    onMouseOver={({target})=>target.style.color="#006192"}
                    onMouseOut={({target})=>target.style.color="white"}                   
                    className="social-media-icons" />
                  </Link>

                  <Link
                    to={{
                      pathname:
                        "https://www.youtube.com/channel/UCKHUrEYYNvUq5Aqy9kOGA1g",
                    }}
                    target="_blank"
                    className="youtube-link"
                  >
                    <AiFillYoutube 
                        className="social-media-icons"
                        onMouseOver={({target})=>target.style.color="red"}
                        onMouseOut={({target})=>target.style.color="white"}
                    />
                  </Link>
                </div>
              </div>
            </div>
            {cookiesAccepted === true && (
              <div>
                <div className="contactUsFrame">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3079.5482298829893!2d-0.35875488463233934!3d39.479533679485066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6048a20c4d7d9d%3A0x36c11f69dc893b9b!2sCarrer%20de%20Daniel%20Balaciart%2C%202%2C%2046020%20Val%C3%A8ncia!5e0!3m2!1sen!2ses!4v1620724247380!5m2!1sen!2ses"
                    loading="lazy"
                    title="maps"
                    style={{
                      minHeight: "583px",
                      width: "100%",
                      border: "none",
                    }}
                  ></iframe>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
