/** @jsxRuntime classic */
/** @jsx jsx */

import useAxios from "axios-hooks";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Context } from "../../../Context";
import { style } from "./style";
import { getLangCode } from "../../../Helpers/getLangCode";
import CoachBody from "./CoachBody";
import CoachHeader from "./CoachHeader";
import { css, jsx } from "@emotion/react";
import { useMedia } from "react-use";
import { BASE_URL } from "../../../constants";
import Footer from "../../../Footer";

const CoachPage = () => {
  const {
    language: { language },
  } = useContext(Context);

  const mobile = useMedia("(max-width: 700px)");
  const langCode = getLangCode(language);

  const { id } = useParams();
  const [player, setPlayerData] = useState();
  const [{ data, loading, error }] = useAxios({
    url: BASE_URL + `/coaches?id=${id.split("-", 1)[0]}&countryid=${langCode}`,
    method: "GET",
    responseType: "json",
  });
  useEffect(() => {
    if (data) {
      setPlayerData(data);
    }
    return () => {
      document.title = `Elite Soccer 18`;
    };
  }, [data, loading]);

  return player ? (
    <>
      <div className="player-page" css={style}>
        <CoachHeader
          player={player}
          language={language}
          mobile={mobile}
          id={id}
        />
        <CoachBody player={player} mobile={mobile} />
      </div>
      <Footer />
    </>
  ) : null;
};

export default CoachPage;
