import React, { useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
// import { Button } from "./Styles";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 700) {
      setVisible(true);
    } else if (scrolled <= 700) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <button
      style={{
        position: "fixed",
        display: "flex",
        backgroundColor: "transparent",
        border: "none",
        width: "10%",
        left: "50%",
        bottom: "40px",
        height: "20px",
        fontSize: "3rem",
        zIndex: "1",
        color: "#79c942",
        cursor: "pointer",
      }}
    >
      <FaArrowCircleUp
        onClick={scrollToTop}
        style={{
          display: visible ? "inline" : "none",
        }}
      />
    </button>
  );
};

export default ScrollButton;
