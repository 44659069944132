/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import { animated } from "@react-spring/web";
import React, { memo, useContext } from "react";
import { Context } from "../../../Context";
import YoutubeEmbed from "../../../YoutubeEmbed";
import { playerGalleryStyle } from "../../Coaches/Coach Page/style";

const PlayerVideoGallery = ({ style, player, animating }) => {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  return (
    <animated.div style={style} css={playerGalleryStyle}>
      <h2>{dictionary.coach_videos_title}</h2>
      <div className="videos">
        {player.videos.map((url) => {
          return (
            <YoutubeEmbed
              key={url}
              embedID={url.split("v=")[1].split("&")[0]}
            />
          );
        })}
      </div>
    </animated.div>
  );
};

export default memo(PlayerVideoGallery);
