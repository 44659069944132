import { css } from "@emotion/react";

export const linksContainer = css`
  label: link-container;
  display: flex;
  margin: 0 0 0 2rem;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  flex-grow: 1;
  width: 100%;

  @media (max-width: 750px) {
    flex-direction: column;
    margin: 0;
    align-items: center;
    /* height: 400px; */
  }
`;
