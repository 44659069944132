import Header from "./Header/Header";

import Main from "./Main";
import { BrowserRouter as Router } from "react-router-dom";
import { global, darkTheme, lightTheme } from "./Styles/Themes";
import { ThemeProvider, Global } from "@emotion/react";
import { base, reset, telInputStyle, typography } from "./Styles/Global";
import { useLocalStorage } from "react-use";
import { useMemo, useState } from "react";
import { Context } from "./Context";
import Cookies from "./Cookies";
import loadLanguage from "./Helpers/useLoadLanguage";
// import useGeoLanguage from "./Helpers/GetCountry";
import WhatsappIcon from "./WhatsappIcon";
import ScrollButton from "./UI/ScrollTopBtn";

function App() {
  // const [currentLang, currentDictionary] = useLoadLanguage();

  const [localTheme, setLocalTheme] = useLocalStorage("theme", "dark");
  const [theme, setTheme] = useState(
    localTheme === "light" ? lightTheme : localTheme === "dark" && darkTheme
  );

  const [localLanguage, setLocalLanguage] = useLocalStorage(
    "language",
    "English"
  );
  const [language, setLanguage] = useState(localLanguage);

  const [hover, setHover] = useState(true);
  const [dictionary, setDictionary] = useState(loadLanguage(language));
  return (
    <Context.Provider
      value={{
        localLanguage: { localLanguage, setLocalLanguage },
        language: { language, setLanguage },
        localTheme: { localTheme, setLocalTheme },
        theme: { theme, setTheme },
        dictionary: { dictionary, setDictionary },
        hoverMenu: { hover, setHover },
      }}
    >
      <ThemeProvider theme={{ ...global, ...theme }}>
        <Global styles={[reset, typography, base, telInputStyle]} />
        <Router>
          <WhatsappIcon></WhatsappIcon>
          <Header></Header>
          <Cookies></Cookies>
          <Main></Main>
          <ScrollButton />
        </Router>
      </ThemeProvider>
    </Context.Provider>
  );
}

export default App;
