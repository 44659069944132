import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated, config } from "@react-spring/web";
import { IoArrowBack } from "react-icons/io5";
import { Context } from "../Context";

const GoBack = ({ path }) => {
  const [hover, setHover] = useState(false);
  const {
    dictionary: { dictionary },
  } = useContext(Context);

  const spring = useSpring({
    from: {
      transform: "translateX(0)",
    },
    to: {
      transform: hover ? "translateX(-10px)" : "translateX(0px)",
    },
    config: config.gentle,
    onStart: () => setTimeout(() => setHover(false), 150),
    onRest: () => setHover(false),
  });
  return (
    <div className="go-back-btn">
      <Link
        to={path}
        className="back-button"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <animated.div className="icon-container" style={spring}>
          <IoArrowBack size="1.5rem"></IoArrowBack>
        </animated.div>
        <p>{dictionary.player_go_back}</p>
      </Link>
    </div>
  );
};

export default GoBack;
