import { css } from "@emotion/react";
import URWDINregular from "../../fonts/URWDIN-Regular.ttf";
import URWDINbold from "../../fonts/URWDIN-Bold.ttf";

export const typography = css`
  @font-face {
    font-family: "URWDIN";
    src: local("URWDIN-Bold"), url(${URWDINbold}) format("truetype");
    font-weight: bold;
    font-display: swap;
  }

  @font-face {
    font-family: "URWDIN";
    src: local("URWDIN-Regular"), url(${URWDINregular}) format("truetype");
    font-weight: normal;
    font-display: swap;
  }
`;

export const reset = (theme) => css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  *,
  body,
  button,
  input,
  textarea,
  select {
    :focus-visible {
      outline: none;
      border-radius: ${theme.borderRadius};
      transition: all ease-in-out 0.2s;
      box-shadow: 0 0 0 2px ${theme.accentColor};
    }
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  fieldset,
  input,
  textarea,
  p,
  blockquote,
  th,
  td {
    margin: 0;
    padding: 0;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  fieldset,
  img {
    border: 0;
  }
  address,
  caption,
  cite,
  code,
  dfn,
  em,
  th,
  var {
    font-style: normal;
    font-weight: normal;
  }
  ol,
  ul {
    list-style: none;
  }
  caption,
  th {
    text-align: left;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }
  q:before,
  q:after {
    content: "";
  }
  abbr,
  acronym {
    border: 0;
  }
`;

export const base = (theme) => css`
  ::selection {
    background: ${theme.accentColor};
  }
  ::-moz-selection {
    background: ${theme.accentColor};
  }
  ::-webkit-selection {
    background: ${theme.accentColor};
  }
  html {
    max-width: 100%;
  }
  body {
    overflow-x: hidden;
    background-color: ${theme.backgroundColor};
    font-family: "URWDIN", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: normal;
    color: ${theme.textColor};
    min-height: 100vh;

    transition: ${theme.transition};
    transition-property: color, background-color, opacity;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    -webkit-tap-highlight-color: transparent !important;
    outline: none;

    h1 {
      // font-size: ${theme.h1};
      font-size:90px;
      font-weight: bold;
      line-height: 100%;
      @media(max-width: 600px){
        font-size:4.5rem;
      }
    }
    h2 {
      font-size: ${theme.h2};
      font-weight: bold;
      margin-bottom: 0.5rem;
      position: relative;
      width: fit-content;
    }
    h3 {
      font-size: ${theme.h3};
    }
    p {
      line-height: 170%;
      margin-bottom: ${theme.p};
    }

    a {
      text-decoration: underline;
      color: ${theme.textColor};
    }
  }
  hr {
    width: 100%;
    height: 0;
    border: none;
    border-top: solid 2px ${theme.accentColor};
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
  }
`;
export const telInputStyle = (theme) => css``;
