import React, { useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
function ScrollToTopOnMount() {
  smoothscroll.polyfill();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return null;
}

export default ScrollToTopOnMount;
