import cn from "../Languages/cn.json";
import gb from "../Languages/en.json";
import it from "../Languages/it.json";
import es from "../Languages/es.json";
import fr from "../Languages/fr.json";
import pt from "../Languages/pt.json";
import de from "../Languages/de.json";
import ru from "../Languages/ru.json";
import jp from "../Languages/jp.json";
import useGeoLanguage from "./GetCountry";
import { useEffect } from "react";

function loadLanguage(lang) {
  // let selectedLanguage = useGeoLanguage();
  let dictionary;

  switch (lang) {
    case "English":
      dictionary = gb;
      break;
    case "Italian":
      dictionary = it;
      break;
    case "Spanish":
      dictionary = es;
      break;
    case "French":
      dictionary = fr;
      break;
    case "Portuguese":
      dictionary = pt;
      break;
    case "Chinese":
      dictionary = cn;
      break;
    case "German":
      dictionary = de;
      break;
    case "Japanese":
      dictionary = jp;
      break;
    case "Russian":
      dictionary = ru;
      break;
    default:
      dictionary = gb;
      break;
  }
  return dictionary;
}
export default loadLanguage;
