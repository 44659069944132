/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import { useContext } from "react";
import { Context } from "../../../Context";
import { playerCareerStyle } from "./style";

function PlayerCareer({ player }) {
  const {dictionary:{dictionary}} = useContext(Context)
  return (
    <div css={playerCareerStyle}>
      <div className="career">
        <h3>
          <b>{dictionary.player_career}</b>
        </h3>
        <ul>
          {player.career.map((item) => {
            return (
              <li key={item.club + "-" + item.season}>
                <b>{item.club}</b> {item.season}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="career">
        <h3>
          <b>{dictionary.player_youth_career}</b>
        </h3>
        <ul>
          {player.youth_career.map((item) => (
            <li key={item.club + "-" + item.season}>
              <b>{item.club}</b>
              {item.season}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PlayerCareer;
