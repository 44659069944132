/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import React, { useContext, useEffect, useRef } from "react";
import { ReactComponent as Field } from "../../../../assets/football/Soccer_field.svg";
import { Context } from "../../../Context";
import {
  createPlayerPositions,
  removePlayerPositions,
} from "../../../Helpers/playerFieldTools";
import { playerFieldStyle } from "./style";

function PlayerFieldPosition({ player }) {
  const {
    language: { language },
  } = useContext(Context);

  const svg = useRef();

  useEffect(() => {
    createPlayerPositions(player, svg);
  }, []);

  useEffect(() => {
    removePlayerPositions();
    createPlayerPositions(player, svg);
  }, [player]);
  return <Field css={playerFieldStyle} ref={svg}></Field>;
}

export default PlayerFieldPosition;
