import { useContext } from "react";
import { Context } from "../Context";

function useNavLink() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  const names = [
    ["Home", dictionary.menu_home],
    ["Players", dictionary.menu_players],
    ["Coaches", dictionary.menu_coaches],
    ["Services", dictionary.menu_services],
    ["News", dictionary.menu_news],
    ["About us", dictionary.menu_about_us],
    ["Contact us", dictionary.menu_contact_us],
  ];
  return names;
}

export default useNavLink;
