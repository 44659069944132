/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import social from "./SocialComponents";
import { socialBar, socialLogo } from "./topbarStyle";

function Socialbar({ height, fill }) {
  return (
    <div css={socialBar}>
      {social.map((item) => (
        <a
          rel="noreferrer"
          href={item.url}
          key={item.alt + item.url}
          target="_blank"
        >
          <item.logo
            aria-label={item.alt}
            css={[
              socialLogo,
              css`
                height: ${height};
                width: auto;
                fill: ${fill};
              `,
            ]}
            alt={item.alt}
          ></item.logo>
        </a>
      ))}
    </div>
  );
}

export default Socialbar;
