import React from "react";
import { useTheme } from "@emotion/react";

function ServiceCard({ title, text, Icon, size, changeStyles }) {
  const theme = useTheme();
  return (
    <div className="column">
      {text && (
        <div className="inner">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Icon size={"3rem"} className="icon" />
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "20px",
              justifyContent: "center",
            }}
          >
            <h4
              style={{
                fontWeight: "bold",

                color: `${
                  changeStyles === false
                    ? "#fff"
                    : theme.dark
                    ? "#fff"
                    : "#000000"
                }`,
              }}
            >
              {title}
            </h4>
          </div>

          <div
            style={{
              marginTop: "20px",
              fontSize: "18px",
              textAlign: "center",
              padding: "5px",
              color: `${
                changeStyles === false
                  ? "#fff"
                  : theme.dark
                  ? "#fff"
                  : "#000000"
              }`,
            }}
          >
            {text}
          </div>
        </div>
      )}
    </div>
  );
}

export default ServiceCard;
