/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";

function LazyComponent({ children, animationName }) {
  const ref = useRef();
  const [animation, setAnimation] = useState(false);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  });
  useEffect(() => {
    if (intersection && intersection.isIntersecting) {
      setAnimation(true);
    }
  }, [intersection]);
  return (
    <div
      ref={ref}
      className={"initial"}
      css={[
        css`
          transform: translate3d(
            ${animationName === "fadeInLeft" ? "-50%" : "50%"},
            0,
            0
          );
        `,
        animation &&
          function () {
            return (theme) => css`
              transform: none !important;
              opacity: 1 !important;
            `;
          },
      ]}
    >
      {children}
    </div>
  );
}

export default LazyComponent;
