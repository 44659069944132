import { useEffect, useRef, useState } from "react";

export default function useHover(initialValue, defaultIndex, hoverIndex = 2) {
  const [hover, setHover] = useState(initialValue);
  const [zIndex, setZindex] = useState(defaultIndex);
  function onHoverEnter(e) {
    e.preventDefault();
    setHover(true);
    setZindex(hoverIndex);
  }
  function onHoverExit(e) {
    e.preventDefault();
    setHover(false);
    setTimeout(() => setZindex(defaultIndex), 150);
  }

  return [
    hover,
    setHover,
    { onMouseEnter: onHoverEnter, onMouseLeave: onHoverExit },
    zIndex,
  ];
}
