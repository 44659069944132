/** @jsxRuntime classic */
/** @jsx jsx */
// MODULES
import { jsx, css } from "@emotion/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "font-awesome/css/font-awesome.min.css";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";

// LOCAL
import useServicesData from "../Services/useServicesData";
import { Link } from "react-router-dom";
import ServiceCard from "../Services/ServiceCard";
import { partnerStyles } from "./partnerStyles";

/* function NextArrow(props) {
  const { onClick } = props;
  return (
    <Button
      onClick={onClick}
      className="next-arrow slick-next fa-solid fa-square-caret-left"
    >
      <FontAwesomeIcon icon="fa-solid fa-square-caret-left" />
    </Button>
  );
}
function PrevArrow(props) {
  const { onClick } = props;
  return <Button onClick={onClick} className="prev-arrow slick-prev"></Button>;
} */

function SampleNextArrow(props) {
  const { onClick } = props;

  return (
    <div>
      <Button onClick={onClick}>
        <FaArrowRight
          className="right-arrow" /* style={{ color: "white" }}  */
        />
      </Button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;

  return (
    <div>
      <Button onClick={onClick}>
        <FaArrowLeft className="left-arrow" />
      </Button>
    </div>
  );
}

export default function Cards() {
  let settings = {
    infinite: true,
    lazyLoad: true,
    autoplay: false,
    dots: true,

    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: false,
    adaptativeHight: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 3024,
        settings: {
          centerMode: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const services = useServicesData();
  // console.log(services)

  // const {
  //   dictionary: { dictionary },
  // } = this.context
  const changeStyles = false;
  return (
    <div className="partners" css={partnerStyles}>
      {/* <AiOutlineArrowRight style={{ color: "white" }} /> */}

      <Slider {...settings} className="partners">
        {services.map((item) => (
          <Link key={item.title} to="/services">
            <ServiceCard
              title={item.title}
              Icon={item.icon}
              text={item.text_light}
              size={item.size}
              changeStyles={changeStyles}
            />
          </Link>
        ))}
      </Slider>
    </div>
  );
}
