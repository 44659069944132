/** @jsxRuntime classic */
/** @jsx jsx */

import React from "react";
import Home from "./Pages/Home/Home";
import News from "./Pages/News/News";
import { Switch, Route } from "react-router-dom";
import { jsx } from "@emotion/react";
import { main } from "./mainStyle";
import Legal from "./Pages/Legal Disclaimer/Legal";
import ContactUs from "./Pages/Contact us/ContactUs";
import Services from "./Pages/Services/Services";
import Players from "./Pages/Players/Players";
import CookiesPolicy from "./Pages/Cookies Policy/CookiesPolicy";
import PlayerPage from "./Pages/Players/Player page/PlayerPage";
import Coaches from "./Pages/Coaches/Coaches";
import CoachPage from "./Pages/Coaches/Coach Page/CoachPage";
import Footer from "./Footer";
//about us 2, the new one
import AboutUs2 from "./Pages/About us/AboutUs2";
import PrivacyPolicy from "./Pages/Legal Disclaimer/PrivacyPolicy";

function Main() {
  return (
    <div css={main}>
      <Switch>
        <Route exact path="/services">
          <Services />
          <Footer />
        </Route>
        <Route exact path="/news">
          <News />
        </Route>
        <Route exact path="/about-us">
          <AboutUs2 />
          <Footer />
        </Route>
        <Route exact path="/legal-disclaimers">
          <Legal />
          <Footer />
        </Route>
        <Route exact path="/contact-us">
          <ContactUs />
          <Footer />
        </Route>
        <Route path="/players/:id">
          <PlayerPage />
        </Route>
        <Route exact path="/players">
          <Players />
        </Route>
        <Route path="/coaches/:id">
          <CoachPage />
        </Route>
        <Route exact path="/coaches">
          <Coaches />
        </Route>
        <Route exact path="/cookies-policy">
          <CookiesPolicy />
          <Footer />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
          <Footer />
        </Route>
        <Route exact path="/cookies-policy">
          <CookiesPolicy />
          <Footer />
        </Route>
        <Route exact path="/">
          <Home />
          <Footer />
        </Route>
      </Switch>
    </div>
  );
}

export default Main;
