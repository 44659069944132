/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import { Context } from "../../Context";
import { style } from "./style";
import ScrollToTopOnMount from "../../Helpers/ScrollToTopOnMount";

function CookiesPolicy() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  return (
    <div style={{ paddingTop: 100 }} className="cookies-policy" css={style}>
      <ScrollToTopOnMount />
      <h2>{dictionary.cookies_title}</h2>
      <br />
      <section>
        <h3>{dictionary.cookies_what_they_are_title}</h3>
        <br />
        <p>{dictionary.cookies_what_they_are_title_new}</p>
      </section>
      <br />
      <section>
        <h3>{dictionary.cookies_what_information_they_store_title}</h3>
        <br />
        <p>{dictionary.cookies_what_information_they_store_text_1}</p>
        <p>{dictionary.cookies_what_information_they_store_text_2}</p>
        <p>{dictionary.cookies_what_information_they_store_text_3}</p>
      </section>
      <br />
      <section>
        {/* <h3>¿QUÉ TIPO DE COOKIES EXISTEN?</h3> */}
        <h3>{dictionary.cookies_what_types_exist}</h3>
        <br />
        <p>{dictionary.cookies_what_types_exist_introduction}</p>
        <ul>
          <li>{dictionary.cookies_what_types_exist_list1_text1}</li>
          <li>{dictionary.cookies_what_types_exist_list1_text2}</li>
        </ul>
        <p>{dictionary.cookies_what_types_exist_introduction2}</p>
        <ul>
          <li>{dictionary.cookies_what_types_exist_list2_text1}</li>
          <li>{dictionary.cookies_what_types_exist_list2_text2}</li>
        </ul>
        <p>{dictionary.cookies_what_types_exist_introduction3_text1}</p>
        <p>{dictionary.cookies_what_types_exist_introduction3_text2}</p>
        <ul style={{ listStyle: "inside" }}>
          <li>{dictionary.cookies_what_types_exist_list3_text1}</li>
          <br />
          <li>{dictionary.cookies_what_types_exist_list3_text2}</li>
          <br />
          <li>{dictionary.cookies_what_types_exist_list3_text3}</li>
          <br />
          <li>{dictionary.cookies_what_types_exist_list3_text4}</li>
          <br />
          <li>{dictionary.cookies_what_types_exist_list3_text5}</li>
          <br />
        </ul>
      </section>
      <h2>{dictionary.cookies_set_title}</h2>
      <section>
        <h3>{dictionary.cookies_preferences_title}</h3>
        <p>
          {dictionary.cookies_preferences_txt}
          <ul>
            <li>
              <b>{dictionary.cookies_acepted_title}</b>:{" "}
              {dictionary.cookies_acepted_txt}{" "}
            </li>
            <li>
              <b>{dictionary.cookies_theme_title}</b>:{" "}
              {dictionary.cookies_theme_txt}
            </li>
            <b>{dictionary.cookies_lang_title}</b>:{" "}
            {dictionary.cookies_lang_txt}
          </ul>
        </p>
      </section>
      <section>
        <h3>{dictionary.cookies_third_party_title}</h3>
        <p>
          {dictionary.cookies_third_party_txt}
          <ul>
            <li>
              <b>{dictionary.cookies_maps_title}</b>:{" "}
              {dictionary.cookies_maps_txt}{" "}
              <b>
                <nobr>{dictionary.cookies_maps_txt2}</nobr>
              </b>{" "}
            </li>
            <li>
              <b>{dictionary.cookies_twitter_title}</b>:{" "}
              {dictionary.cookies_twitter_txt}{" "}
              <b>{dictionary.cookies_twitter_txt2}</b>{" "}
            </li>
          </ul>
        </p>
      </section>
      <section>
        <h3>{dictionary.cookies_plazo_conversation_title}</h3>
        <p>{dictionary.cookies_plazo_conversation_text1}</p>
        <p>{dictionary.cookies_plazo_conversation_text2}</p>
        <p>{dictionary.cookies_plazo_conversation_text3}</p>
      </section>
      <section>
        <h3>{dictionary.cookies_desactivate_eliminate_title}</h3>
        <p>{dictionary.cookies_desactivate_eliminate_text1}</p>
        <a
          href="https://support.google.com/accounts/answer/61416?hl=es"
          target="_blank"
        >
          {dictionary.cookies_navigator} Chrome
        </a>
        <br />
        <br />
        <a
          href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
          target="_blank"
        >
          {dictionary.cookies_navigator} Internet Explorer
        </a>
        <br />
        <br />
        <p> Safari para OSX:</p>
        <p>Seleccione Safari &gt; Preferencias - Seguridad</p>
        <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
          {dictionary.cookies_navigator} Opera
        </a>
        <br />
        <br />
      </section>
      {/* <h2>{dictionary.cookies_title}</h2>
      <section>
        <h3>{dictionary.cookies_what_they_are_title}</h3>
        <p>{dictionary.cookies_what_they_are_txt}</p>
      </section>
      <section>
        <h3>{dictionary.cookies_how_we_use_title}</h3>
        <p>{dictionary.cookies_how_we_use_txt}</p>
      </section>
      <section>
        <h3>{dictionary.cookies_disabling_title}</h3>
        <p>{dictionary.cookies_disabling_txt}</p>
      </section>
      <h2>{dictionary.cookies_set_title}</h2>
      <section>
        <h3>{dictionary.cookies_preferences_title}</h3>
        <p>
          {dictionary.cookies_preferences_txt}
          <ul>
            <li>
              <b>{dictionary.cookies_acepted_title}</b>:{" "}
              {dictionary.cookies_acepted_txt}{" "}
            </li>
            <li>
              <b>{dictionary.cookies_theme_title}</b>:{" "}
              {dictionary.cookies_theme_txt}
            </li>
            <b>{dictionary.cookies_lang_title}</b>:{" "}
            {dictionary.cookies_lang_txt}
          </ul>
        </p>
      </section>
      <section>
        <h3>{dictionary.cookies_third_party_title}</h3>
        <p>
          {dictionary.cookies_third_party_txt}
          <ul>
            <li>
              <b>{dictionary.cookies_maps_title}</b>:{" "}
              {dictionary.cookies_maps_txt}{" "}
              <b>
                <nobr>{dictionary.cookies_maps_txt2}</nobr>
              </b>{" "}
            </li>
            <li>
              <b>{dictionary.cookies_twitter_title}</b>:{" "}
              {dictionary.cookies_twitter_txt}{" "}
              <b>{dictionary.cookies_twitter_txt2}</b>{" "}
            </li>
          </ul>
        </p>
      </section> */}
      <section>
        <h3>{dictionary.cookies_more_information_title}</h3>
        <p>{dictionary.cookies_more_information_txt}</p>
        <p>{dictionary.cookies_more_information_txt2}</p>
        Email:{" "}
        <a href="mailto:info@elitesoccer18.com">info@elitesoccer18.com</a>
      </section>
    </div>
  );
}

export default CookiesPolicy;
