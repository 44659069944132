/** @jsxRuntime classic */
/** @jsx jsx */
import ReCaptcha from "react-google-recaptcha";
import { jsx, css, useTheme } from "@emotion/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { emailStyle } from "./style";
import CustomPhoneInput from "./CustomPhoneInput";
import { Context } from "../../Context";
import { Link } from "react-router-dom";
import CustomCheckBox from "../../CustomCheckBox";
import Notification from "./Notification";
import { useLocation } from "react-use";
import { getLangLocale } from "../../Helpers/getLangCode";
import axios from "axios";
import { darkTheme } from "../../Styles/Themes";
import { BASE_URL } from "../../constants.js";

function Email() {
  const {
    dictionary: { dictionary },
    language: { language },
  } = useContext(Context);
  const theme = useTheme();
  const [enabled, setEnabled] = useState(false);
  const [recaptchaKey, setRecaptchaKey] = useState(0);
  const [state, setState] = useState({ state: "idle", msg: "" });
  const ref = useRef();
  const location = useLocation();
  const [passedCaptcha, setPassedCaptcha] = useState(true);
  const captcha = useRef();
  const [btnEnable, setBtnEnable] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    msg: "",
    phoneNumber: "",
    department: "",
    legal: false,
  });

  const assignValues = (name) => {
    return ({ target: { value } }) => {
      setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };

  async function handleCaptcha(value) {
    setPassedCaptcha(true);
    const response = await axios({
      url: BASE_URL + "captchaverify.php",
      method: "POST",
      data: {
        "g-recaptcha-response": value,
      },
    });
    // if (response.data.success) {
    //   setPassedCaptcha(true);
    // }
  }

  // Como manejar el estado despues de enviar el correo
  useEffect(() => {
    // Si se ha enviado el correo bien
    if (location.search === "?email=success") {
      setState({ state: "success", msg: "Email sent successfully" });
      setTimeout(() => {
        setState({ state: "idle", msg: "" });
      }, 2500);

      setEnabled(false);
    } else if (location.search === "?email=error") {
      // Si se ha enviado el correo mal
      setState({
        state: "error",
        msg: "Oh no, there was a problem sending your email...",
      });
      setTimeout(() => {
        setState({ state: "idle", msg: "" });
      }, 2500);

      setEnabled(true);
    }
  }, []);

  // Si se cambia el idioma o tema
  useEffect(() => {
    // Re-renderizar el recaptcha
    setRecaptchaKey(recaptchaKey + 1);
  }, [darkTheme, language]);

  useEffect(() => {
    if (passedCaptcha && formValues.legal) setBtnEnable(true);
    else setBtnEnable(false);
  }, [passedCaptcha, formValues.legal]);

  return (
    <>
      <div css={emailStyle}>
        <form
          ref={ref}
          id="email-form"
          method="post"
          action={BASE_URL + "mail/correo.php"}
        >
          <div className="inner-form">
            <div className="inputs-container">
              <input name="origin" value="EliteSoccer18" type="hidden" />
              <label htmlFor="name">{dictionary.contact_fullname}</label>
              <input
                type="text"
                id="name"
                name="name"
                required
                value={formValues.name}
                onChange={assignValues("name")}
              />
              <label htmlFor="email">{dictionary.contact_email}</label>
              <input
                type="email"
                name="email"
                id="email"
                // name="email"
                required
                value={formValues.email}
                onChange={assignValues("email")}
              />
              <CustomPhoneInput
                name="phoneNumber"
                label={dictionary.contact_phone}
                formValues={formValues}
                setFormValues={setFormValues}
              ></CustomPhoneInput>
            </div>
            <div className="message-container">
              <label htmlFor="message">{dictionary.contact_message}</label>
              <textarea
                required
                id="message"
                cols="30"
                rows="10"
                name="msg"
                value={formValues.msg}
                onChange={assignValues("msg")}
              ></textarea>
              {/* <textarea
                 style={{
                   display: "none",
                 }}
                 required
                 name="message"
                 id="origin"
                 cols="30"
                 rows="10"
                 name="msg"
                 value="EliteSoccer18"
               ></textarea>  */}
            </div>
          </div>
          <div className="service-category">
            <h3>{dictionary.contact_im}</h3>
            <div className="select-container">
              <input
                type="radio"
                name="department"
                id="coach"
                value="coach"
                required
                onChange={assignValues("department")}
              />
              <label htmlFor="coach">{dictionary.contact_coach}</label>
              <input
                type="radio"
                name="department"
                id="player"
                value="player"
                onChange={assignValues("department")}
              />
              <label htmlFor="player">{dictionary.contact_player}</label>
              <input
                type="radio"
                name="department"
                id="club"
                value="club"
                onChange={assignValues("department")}
              />
              <label htmlFor="club">{dictionary.contact_club}</label>
              <input
                type="radio"
                name="department"
                id="other"
                value="other"
                onChange={assignValues("department")}
              />
              <label htmlFor="other">{dictionary.contact_other}</label>
            </div>
          </div>
          <div className="lower-box">
            <CustomCheckBox
              formValues={formValues}
              setFormValues={setFormValues}
            >
              I agree to{" "}
              <Link to="/legal-disclaimers" style={{ color: "#0574bb" }}>
                Legal Terms
              </Link>
            </CustomCheckBox>
            <ReCaptcha
              className="captcha"
              ref={captcha}
              key={recaptchaKey}
              sitekey="6Le6wAgbAAAAAPtFT68VOwLlxhJrTWOdR7CIWxra"
              onChange={handleCaptcha}
              hl={getLangLocale(language)}
              theme={theme.dark ? "dark" : "light"}
            ></ReCaptcha>
            <button
              disabled={!btnEnable}
              type="submit"
              style={{ border: " 2px solid #ccc" }}
            >
              {dictionary.contact_send}
            </button>
          </div>
        </form>
        <Notification state={state}></Notification>
      </div>
    </>
  );
}

export default Email;
