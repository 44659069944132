import Toggle from "./Toggle/Toggle.jsx";

import React, { useContext } from "react";
import { Context } from "../Context";
import { darkTheme, lightTheme } from "../Styles/Themes";

function ToggleTheme() {
  const {
    localTheme: { setLocalTheme },
    theme: { setTheme },
  } = useContext(Context);

  // El componente Toggle acepta un callback para cuando se cambia el tema,
  // y le pasa como primer argumento el estado del toogle
  const handleToggleChange = React.useCallback((state) => {
    if (state) {
      setLocalTheme("dark");
      setTheme(darkTheme);
    } else {
      setLocalTheme("light");
      setTheme(lightTheme);
    }
  });
  return <Toggle variant="theme" onToggleChange={handleToggleChange} />;
}

export default ToggleTheme;
