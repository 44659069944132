import { useState } from "react";
import { useSpring, config } from "react-spring";
import useHover from "./useHover";

export default function useToggleSprings({
  onColor = "#90db03",
  offColor = "#ff3995",
  height = 20,
  width = 40,
  borderRadius = height,
  borderWidth = 2,
  dialColorOn = "white",
  dialColorOff = "white",
}) {
  const [toggleState, setToggleState] = useState(false);
  const toggle = () => {
    setToggleState(!toggleState);
  };
  const [hover, , onHover] = useHover(false);

  const style = useSpring({
    backgroundColor: toggleState ? onColor : offColor,
    cursor: "pointer",
    position: "relative",
    margin: "0.5rem",
    borderWidth: borderWidth,
    height: height,
    width: width,
    borderRadius: borderRadius,

    config: config.gentle,
  });

  const dialStyle = useSpring({
    left: !toggleState ? -1 : width - height,
    backgroundColor: !toggleState ? dialColorOff : dialColorOn,
    boxShadow: !toggleState
      ? "0px 0px 10px 0px rgba(0,0,0,0.48)"
      : "0px 0px 10px 0px rgba(255,255,255,0.8)",
    transform: hover ? "scale(1.1)" : "scale(1)",
    width: height,
    height: height,
    borderRadius: borderRadius,

    from: {
      position: "absolute",
      transform: "scale(1)",
    },
    config: config.gentle,
  });

  return [toggle, toggleState, onHover, style, dialStyle];
}
