import { css } from "@emotion/react";
import { flexGap } from "../../../Helpers/flexGap";

function textShadow(thickness, color) {
  let string = "";
  for (let i = 0; i < thickness; i++) {
    string +=
      i + 1 === thickness
        ? `0px ${i + 1}px 0px ${color};`
        : `0px ${i + 1}px 0px ${color}, `;
  }
  return string;
}

export const style = (theme) => css`
  label: player-page;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const playerHeaderStyle = (theme) => css`
  width: 100%;
  max-width: ${theme.contentMaxWidth};
  padding: 0 ${theme.padding};
  margin-top: 2rem;
  min-height: 800px;
  position: relative;
  padding-bottom: 2rem;

  @media (max-width: 700px) {
    padding: ${theme.paddingM};
    min-height: fit-content;
  }
  .back-button {
    display: flex;
    align-items: center;
    /* margin-left: 0.5rem; */
    cursor: pointer;
    width: fit-content;
    margin-bottom: 5rem;
    color: ${theme.accentColor2};
    text-decoration: none;
    .icon-container {
      svg {
        display: block;
      }
    }
    p {
      margin: 4.2rem 0.5rem;
      //margin-top: 4rem;
    }
  }

  .title-name {
    margin-bottom: 2rem;
    @media (max-width: 700px) {
      font-size: 15vw;
    }
  }
  .column-container {
    display: flex;
    justify-content: space-between;
  }
  .player-title {
    width: fit-content;
    .current-team {
      display: flex;
      align-items: center;
      h3 {
        font-size: ${theme.h2};
        font-weight: bold;
        @media (max-width: 430px) {
          font-size: 8vw;
        }
      }
      img {
        padding-right: 1rem;
        height: 3rem;
      }
    }
    .measurements {
      margin-top: 2rem;
      display: flex;
      .col {
        h3 {
          font-weight: bold;
        }
      }
      .height {
        margin-right: 2rem;
      }
    }
  }

  .player-bio {
    max-width: 200px;
    & > div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid ${theme.textColor};
      padding-bottom: 3px;
      :not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
      a {
        display: block;
        height: 100%;
        max-width: 3rem;

        img {
          width: 100%;
          height: 100%;
          /* max-height: 100%; */
        }
      }
    }
  }
  .player-number {
    margin: 0;
    font-size: 10rem;
    position: absolute;
    z-index: 2;
    left: 40%;
    bottom: 20px;
    color: white;
    text-shadow: ${textShadow(20, theme.accentColor2)};
    line-height: 100%;
    vertical-align: middle;
    @media (max-width: 1240px) {
      left: ${theme.padding};
    }
    @media (max-width: 700px) {
      position: unset;
      font-size: 40vw;
    }
  }
  .background {
    position: absolute;
    overflow: hidden;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 ${theme.padding};
    max-width: ${theme.contentMaxWidth};
    .text-container {
      position: absolute;
      top: 5%;
      z-index: -1;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);
      h1 {
        margin: 0;
        color: ${theme.dark
          ? theme.backgroundColorLighter
          : theme.secondaryTextColorLighter};
      }
    }
    .img-container {
      margin: auto;
      height: 100%;
      width: 100%;
      max-width: ${theme.contentMaxWidth};
      position: relative;
      object-fit: cover;
      position: absolute;
      img {
        object-fit: cover;
        position: absolute;
        left: 50%;
        top: 2rem;
        transform: translateX(-50%);
        margin: auto;
        height: 100%;
        opacity: 0.5;
      }
    }
    @media (max-width: 700px) {
      padding: 0 ${theme.paddingM};
    }
  }
`;

export const radarStyle = (theme) => css`
  svg {
    max-width: 400px;
    min-width: 0;
    width: 100%;
    overflow: visible;
  }
  .caption {
    font-family: "URWDIN", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: bold;
    font-size: ${theme.p};
    text-shadow: 1px 1px 0 ${theme.backgroundColorLighter};
    fill: ${theme.textColor};
  }
  .scale {
    stroke: ${theme.dark ? theme.backgroundColorHigh : theme.accentColor2};
    stroke-width: 2px;
    fill: ${theme.backgroundColorLighter};
  }
  .axis {
    stroke: ${theme.dark ? theme.backgroundColorHigh : theme.accentColor2};

    stroke-width: 2px;
  }
  .shape {
    stroke: ${theme.accentColor2};
    stroke-width: 4px;
    fill: ${theme.accentColor2};
  }
`;

export const playerBodyStyle = (theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
  position: relative;
  .tab-menu {
    background-color: ${theme.backgroundColorHigh};
    margin-top: 0;
    padding: 0 ${theme.padding} 1rem ${theme.padding};
    width: fit-content;
    position: relative;
    @media (max-width: 700px) {
      padding: 0 ${theme.paddingM} 1rem ${theme.paddingM};
    }
    ::before {
      content: "";
      position: absolute;
      width: calc(100vw + 1rem);
      z-index: -1;
      height: 100%;
      background-color: red;
      background-color: ${theme.backgroundColorHigh};
      box-shadow: 0 0 40px 0 ${theme.accentColor2 + "22"};
      @media();
    }
    .tab {
      text-align: center;
      max-width: 100%;
      box-shadow: 0px 0px 2px 1px
        rgba(
          0,
          0,
          ${theme.dark ? "10" : "150"},
          ${theme.dark ? "0.25" : "0.15"}
        );
      :hover {
        box-shadow: 0px 5px 20px 0px
          rgba(
            0,
            0,
            ${theme.dark ? "10" : "150"},
            ${theme.dark ? "0.25" : "0.10"}
          );
      }

      &.active-tab {
        box-shadow: 0px 0px 2px 1px
          rgba(
            0,
            0,
            ${theme.dark ? "0" : "150"},
            ${theme.dark ? "0.25" : "0.25"}
          );
      }
    }
  }
`;

export const playerBioStyle = (theme) => css`
  margin: auto;
  max-width: ${theme.contentMaxWidth};
  overflow: hidden;
  padding: ${theme.padding};

  @media (max-width: 700px) {
    padding: ${theme.paddingM};
  }
  .content {
    ${flexGap("1rem")}
    @media (max-width: 700px) {
      flex-direction: column;
    }
    .column {
      flex: 1 1 40%;
      display: flex;
      & > div {
        margin-bottom: 2rem;
      }
      &.field {
        width: 100%;
      }
      &.radar {
        & > div {
          width: fit-content;
        }
      }

      svg {
        width: 100%;
      }

      :first-of-type {
        flex-direction: column;
      }
      :nth-of-type(2) {
        justify-content: center;
        align-items: center;
      }
    }
    .quote {
      background-color: ${theme.backgroundColorHigh};
      padding: 1rem 2rem;
      border-radius: ${theme.borderRadius};
      position: relative;
      z-index: 1;
      box-shadow: 0 0 2px 1px
        ${theme.dark ? "rgba(8, 12, 22, 0.4)" : "rgba(0, 73, 255, 0.2)"};
      p {
        text-align: right;
      }
      blockquote {
        width: 100%;
        max-width: 50ch;
        font-size: ${theme.h3};
        line-height: 180%;
        p {
          margin: auto;
          text-align: center;
        }
      }
      .img-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        svg {
          fill: ${theme.inputBackgroundColor};
          opacity: 0.5;
          position: absolute;
          object-fit: cover;
          height: 60%;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }
  }
`;

export const playerGalleryStyle = (theme) => css`
  width: 100%;
  max-width: ${theme.contentMaxWidth};
  padding: ${theme.padding};
  /* background-color: red; */
  margin: auto;
  @media (max-width: 700px) {
    padding: ${theme.paddingM};
  }

  .img-container {
    cursor: pointer;
    border-radius: ${theme.borderRadius};
    overflow: hidden;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
`;

export const playerFieldStyle = (theme) => css`
  @keyframes breath {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
  max-width: 500px;
  overflow: visible;
  padding-right: 1rem;
  .st0 {
    fill: ${theme.dark ? theme.backgroundColorLighter : theme.accentColor};
  }
  .st1 {
    fill: ${theme.backgroundColor};
  }
  .st2 {
    fill: ${theme.dark ? theme.backgroundColorHigh : theme.accentColor2};
  }

  .position-container {
    .player-position {
      fill: white;
      cursor: pointer;
      :hover {
        & + .foreign-object {
          .text-container {
            opacity: 1;
            visibility: visible;

            transition: ${theme.transition};
            transition-property: opacity;
          }
        }
      }
    }
    .player-glow {
      fill: url("#grad1");
      animation-name: breath;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      animation-direction: alternate;
    }
    .secondary {
      fill: ${theme.accentColor2};
      stroke: white;
    }

    :hover {
      .text {
        display: block;
        background-color: ${theme.accentColor2};
        max-width: 0px;
        max-height: 0px;
      }
    }
  }

  .foreign-object {
    transform-box: fill-box;
    transform-origin: center;
    transform: translate3d(-50%, 0, 0);
    overflow: visible;
    .text-container {
      opacity: 0;
      visibility: hidden;
      transition: ${theme.transition};

      transform-box: fill-box;
      transform-origin: center bottom;
      transform: translate3d(-50%, calc(-100% - 15px), 0);
      min-width: fit-content;
      height: fit-content;
      max-width: max-content;
      overflow: hidden;
      background-color: ${theme.accentColor2};
      user-select: none;
      color: white;
      text-align: center;
      padding: 0.5rem;
      border-radius: ${theme.borderRadius};
    }
  }

  .position-container {
  }
`;

export const playerCareerStyle = (theme) => css`
  .career {
    padding: 1.5rem 2rem;
    background-color: ${theme.backgroundColorHigh};
    border-radius: ${theme.borderRadius};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, ${theme.dark ? "0.25" : "0.15"});
    h3 {
      margin-bottom: 1rem;
      margin-top: -5px;
    }
    ul {
      li {
        display: flex;
        justify-content: space-between;
        @media (max-width: 350px) {
          flex-direction: column;
        }
        // Fix for minimum right margin, only affects the first child of <li>
        & > * {
          margin-right: 2rem;
        }
        :not(:last-of-type) {
          margin-bottom: 0.3rem;
        }
      }
    }
    :not(:last-of-type) {
      margin-bottom: 2rem;
    }

    :nth-of-type(1) {
      margin-top: auto;
      margin-top: 2rem;
    }
  }
  @media (max-width: 700px) {
    flex-direction: column;
    .career {
      padding: 1rem 1.5rem;
    }
  }
`;
