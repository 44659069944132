import { css } from "@emotion/react";

export const style = (theme) => css`
  width: 100%;
  max-width: ${theme.contentMaxWidth};
  padding: ${theme.padding};
  margin: 4rem auto;
  @media (max-width: 700px) {
    padding: ${theme.paddingM};
  }
  h3 {
    font-weight: bold;
  }
  ul {
    margin: 1rem 0 1rem 2rem;
  }
`;
