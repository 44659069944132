/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useContext, useEffect, useRef, useState } from "react";
import { css, jsx } from "@emotion/react";
import HoverLinks from "./HoverLinks";
import { animated, useTransition } from "react-spring";
import {
  mobileNavBar,
  mobileNavbarDynamic,
  mobileNavbarHover,
} from "./navbarStyle";
import Logo from "./Logo";
import { useClickAway } from "react-use";
import useNavLink from "../Helpers/useNavLinks";
import { fadeTransition } from "../Helpers/transitions";
import { Spiral as Hamburger } from "hamburger-react";
import { HeaderContext } from "./Header";
const MobileNavBar = React.forwardRef(({}, ref2) => {
  //este estado sera solo para el burguer menu, de esta forma evitamos el bug de antes para que al clickar aqui
  //solo se abra el burguer
  const [open, setOpen] = useState(false);

  const names = useNavLink();
  const { compact, height, home, hover } = useContext(HeaderContext);
  const [style, setStyle] = useState("mobile-nav");
  const ref = useRef();
  const fade = useTransition(open, fadeTransition);

  // Cambiar el estilo del navbar cuando se cambia el estado del menu
  //chekc this logi later on , not sure if its realyl working
  useClickAway(ref, () => {
    setOpen(false);
    //setOpen((prev) => !prev && prev !== true);
  });
 

  useEffect(() => {
    setStyle("mobile-nav" + (open ? " open" : ""));
    //Cuando se desmonta el menu, se cambia el estado del navbar
    return () => setStyle("mobile-nav");
  }, [open]);

  return (
    <div
      css={[
        mobileNavBar,
        mobileNavbarDynamic(compact, height, home),
        mobileNavbarHover(hover, height),
      ]}
      className={style}
    >
      <div>
        <div className="top-bar" aria-label="menubar">
          <Logo />

          <Hamburger
            hideOutline={true}
            toggled={open === true ? open : false}
            toggle={setOpen}
          />
        </div>
      </div>

      {fade(
        (style, state) =>
          state && (
            <animated.nav ref={ref} style={style} className="navBar">
              <HoverLinks names={names} setOpen={setOpen} />
            </animated.nav>
          )
      )}
    </div>
  );
});

export default MobileNavBar;
