import { css } from "@emotion/react";

export const youtubeEmbedStyle = (theme) => css`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-bottom: 2rem;
  height: 100%;
  width: 100%;
  background-color: ${theme.backgroundColorHigh};
  & iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;
