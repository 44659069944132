import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import { range } from "../Helpers/dataConversion";

const config = {
  contrast: [850, 1130, 0.07, 1],
  contrastDark: [850, 1130, 0.3, 1],
  brightness: [850, 1130, 1.5, 1],
  brightnessDark: [850, 1130, 0.4, 0.8],
};

function useFadeRange(profileRef, width, config) {
  const theme = useTheme();

  useEffect(() => {
    if (profileRef.current) {
      let contrast = theme.dark
        ? range(...config.contrastDark, width)
        : range(...config.contrast, width);
      let brightness = theme.dark
        ? range(...config.brightnessDark, width)
        : range(...config.brightness, width);
      profileRef.current.style.filter = `contrast(${contrast}) brightness(${brightness}) sepia(1) hue-rotate(${
        theme.dark ? "180deg" : "177deg"
      })`;
    }
  }, [width, theme.dark]);
  return;
}

export default useFadeRange;
