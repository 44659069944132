import { css } from "@emotion/react";

export const animatedNavLinkStyle = (theme) => css`
  label: animated-nav-link;
  display: flex;
  align-items: flex-start;
  box-sizing: content-box;
  padding: 0 1vw;
  height: 50%;
  position: relative;

  :last-of-type {
    margin-right: -1vw;
  }

  h4 {
    min-width: max-content;
    ::before {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
  @media (max-width: 750px) {
    text-decoration: none;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  &::after {
    content: "";
    background: ${theme.accentColor};
    height: 2px;
    position: absolute;
    bottom: -2px;
    transition: 0.16s all 0.025s;
  }

  &::after {
    left: 100%;
    right: 0;
  }

  &:hover ~ a::after {
    left: 0;
    right: 100%;
  }

  &:hover::after {
    left: 0;
    right: 0;
  }
`;
export const activeLink = (theme) => css`
  label: active-link;
  position: relative;
  h4 {
    font-weight: bold;
  }
  &::before {
    content: "";
    background: ${theme.accentColor2};
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -2px;
    left: 0;
    z-index: 2;
  }
`;
export const carretStyle = (theme) => css`
  label: carret;
  content: "";
  border-right: 2px solid ${theme.textColor};
  border-bottom: 2px solid ${theme.textColor};
  margin-left: 5px;
  margin-top: -2px;
  width: 10px;
  height: 10px;
`;
