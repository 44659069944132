/** @jsxRuntime classic */
/** @jsx jsx */

import React, { createContext, useEffect, useState } from "react";
import NavBar from "./NavBar";
import Topbar from "./Topbar";
import { useMeasure, useMedia, useWindowScroll } from "react-use";
import MobileNavBar from "./MobileNavBar";
import { jsx, css } from "@emotion/react";
import { useHistory } from "react-router";
export const HeaderContext = createContext();

function Header() {
  const history = useHistory();
  const [home, setHome] = useState(false);
  const [compact, setCompact] = useState(false);
  const [hover, setHover] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [closeTriggerState, setCloseTriggerState] = useState(1);

  function closeTrigger() {
    setCloseTriggerState(closeTriggerState + 1);
  }

  const { y } = useWindowScroll();

  const [previousY, setPreviousY] = useState(0);

  const isWide = useMedia("(max-width: 750px)");
  const [ref, { height }] = useMeasure();
  const [ref2, { height: height2 }] = useMeasure();
  const [heightSet, setHeightSet] = useState(false);
  const [localHeight, setHeight] = useState(0);

  // Manejar el estado de la barra de navegación
  useEffect(() => {
    // Si subes
    if (y < previousY) {
      if (y < height) {
        // Y si y es menor que la altura de TopBar, haces el header grande y seteas el hover a falso
        setCompact(false);
        setHover(false);
      } else {
        // Si no, seteas el hover a true
        setHover(true);
      }
    } else {
      // Si bajas y y es mayor que la altura de TopBar, haces el header pequeño
      if (y > height) {
        setCompact(true);
      }
      // Seteas el hover a false
      setHover(false);
    }
    setPreviousY(y);
  }, [y]);

  // Si estamos en home, entonces seteas el home a true y usamos el estilo de home, solo al montar el componente
  useEffect(() => {
    history.location.pathname === "/" ? setHome(true) : setHome(false);
  }, []);

  // Manejo de header al hacer hover
  useEffect(() => {
    // Si el raton sale del header y no estamos compactos, cierra el menu
    !hover && !compact && closeTrigger();
    // Si no es compacto, cierra el menu
    !compact && closeTrigger();
  }, [hover]);

  // Cuando cambia de mobile a desktop, cierra el menu de mobile
  useEffect(() => {
    isWide && setOpen(false);
  }, [isWide]);

  // Manejo de la altura de navbar
  useEffect(() => {
    // Si la altura de topbar no es 0 y no esta seteada la altura de Header
    if (height !== 0 && !heightSet) {
      // Ponemos que la altura esta seteada y cambiamos la altura de header a la suma de Topbar y Navbar
      setHeightSet(true);
      setHeight(height2 + height);
    }
  }, [height2]);

  // Si estamos en home, entonces seteas el home a true y usamos el estilo de home
  useEffect(() => {
    history.listen((location) => {
      location.pathname === "/" ? setHome(true) : setHome(false);
    });
  }, [history]);

  return (
    <HeaderContext.Provider
      value={{
        isWide,
        compact,
        isOpen,
        setOpen,
        hover,
        setHover,
        height,
        menu: {
          closeTriggerState,
          closeTrigger,
        },
        home,
      }}
    >
      <>
        <header
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => {
            closeTrigger();
            setHover(false);
          }}
          css={css`
            z-index: 3;
            height: 80px;
          `}
          className={(home ? "home" : "") + (compact ? " compact" : "")}
        >
          <Topbar ref={ref} />
          {!isWide ? <NavBar ref={ref2} /> : <MobileNavBar ref={ref2} />}
        </header>
      </>
    </HeaderContext.Provider>
  );
}

export default Header;
