/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import React, { useContext } from "react";
import { Context } from "../../Context";
import ScrollToTopOnMount from "../../Helpers/ScrollToTopOnMount";
import Chapter from "./Chapter";
import { legalContainer } from "./style";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

const customCSS = css`
  label: custom;
  column-width: unset !important;
  max-width: 65ch;
`;
function Legal() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  return (
    <div css={legalContainer}>
      <ScrollToTopOnMount />
      <div className="legal-header">
        <p>
          {dictionary.legal_first_box_1}{" "}
          <a href="https://www.elitesoccer18.com">
            https://www.elitesoccer18.com
          </a>{" "}
          {dictionary.legal_first_box_2}
        </p>
        <p>{dictionary.legal_first_box_3}</p>
        <hr />
      </div>
      <Chapter title={dictionary.legal_tittle_of_website_title}>
        <p>{dictionary.legal_tittle_of_first_text}</p>
      </Chapter>
      <ul style={{ listStyle: "inside" }}>
        <li>
          {dictionary.legal_tittle_of_website_company_name}:{" "}
          <b>ELITE SPORTS 17, S.L.</b> (en adelante, "<b>el titular</b>")
        </li>
        <br />
        <li>
          {dictionary.legal_tittle_of_website_population}: C/Álvaro de Bazan
          17-3, 46010, Valencia
        </li>
        <br />
        <li>
          CIF: B87817359
          {/* <b>{dictionary.legal_tittle_of_website_population}:</b> Valencia |{" "}
            {dictionary.legal_tittle_of_website_province}: Valencia */}
        </li>
        <br />
        <li>
          {dictionary.legal_tittle_of_website_mail}:{" "}
          <a href="mailto:staff@elitesports17.com">staff@elitesports17.com</a>
        </li>
        <br />
      </ul>
      <Chapter title={dictionary.legal_object_title} config={customCSS}>
        <p>{parse(dictionary.legal_object_txt)}</p>
        <p>{parse(dictionary.legal_object_txt2)}</p>
        <ul style={{ listStyle: "inside" }}>
          <li>{parse(dictionary.legal_object_list_txt1)}</li>
          <br />
          <li>{parse(dictionary.legal_object_list_txt2)}</li>
          <br />
          <li>{parse(dictionary.legal_object_list_txt3)}</li>
          <br />
          <li>{parse(dictionary.legal_object_list_txt4)}</li>
        </ul>
        <ul></ul>
      </Chapter>
      <Chapter title={dictionary.legal_intellectual_title}>
        <p>{parse(dictionary.legal_intellectual_txt)}</p>
        <p>{parse(dictionary.legal_intellectual_txt1)}</p>
        <ul style={{ listStyle: "inside" }}>
          <li>{dictionary.legal_intellectual_list_txt1}</li>
          <br />
          <li>{dictionary.legal_intellectual_list_txt2}</li>
          <br />
          <li>{dictionary.legal_intellectual_list_txt3}</li>
          <br />
        </ul>
        <p>{dictionary.legal_intellectual_txt2}</p>
      </Chapter>
      <Chapter title={dictionary.legal_modifications_title}>
        <p>{parse(dictionary.legal_modifications_txt1)}</p>
      </Chapter>
      <Chapter title={dictionary.legal_responsability_title}>
        <p>{dictionary.legal_responsability_txt1}</p>
        <ul style={{ listStyle: "inside" }}>
          <li>{dictionary.legal_responsability_list_txt1}</li>
          <br />
          <li>{dictionary.legal_responsability_list_txt2}</li>
          <br />
          <li>{dictionary.legal_responsability_list_txt3}</li>
          <br />
        </ul>
      </Chapter>
      <Chapter title={dictionary.legal_protection_title}>
        <p>
          {dictionary.legal_protection_txt1}{" "}
          <Link to={"/privacy-policy"}>{dictionary.footer_privacy}.</Link>
        </p>
      </Chapter>
      <Chapter title={dictionary.legal_jurisdiction_title}>
        <p>{dictionary.legal_jurisdiction_txt}</p>
      </Chapter>
      {/* <Chapter title={dictionary.legal_tittle_of_website_title}>
        <p>{dictionary.legal_tittle_of_first_text}</p>

        <ul style={{ listStyle: "inside" }}>
          <li>
            {dictionary.legal_tittle_of_website_company_name}:{" "}
            <b>ELITE SPORTS SOCCER 18, S.L.</b>{" "}
            {parse(dictionary.legal_ttitle_titular)}
          </li>
          <br />
          <li>
            {dictionary.legal_tittle_of_website_population}: C/Álvaro de Bazan
            17-3, 46010, Valencia
          </li>
          <br />
          <li>CIF: B40530677</li>
          <br />
          <li>
            {dictionary.legal_tittle_of_website_mail !== undefined
              ? dictionary.legal_tittle_of_website_mail
              : "Email Address"}
            :{" "}
            <a href="mailto:privacidad@elitesports17.com">
              info@elitesoccer18.com
            </a>
          </li>
          <br />
        </ul>
      </Chapter>
      <Chapter title={dictionary.legal_object_title} config={customCSS}>
        <p>{parse(dictionary.legal_object_txt)}</p>
        <p>{parse(dictionary.legal_object_txt2)}</p>
        <ul style={{ listStyle: "inside" }}>
          <li>{parse(dictionary.legal_object_list_txt1)}</li>
          <br />
          <li>{parse(dictionary.legal_object_list_txt2)}</li>
          <br />
          <li>{parse(dictionary.legal_object_list_txt3)}</li>
          <br />
          <li>{parse(dictionary.legal_object_list_txt4)}</li>
        </ul>
        <ul></ul>
      </Chapter>
      <Chapter title={dictionary.legal_intellectual_title}>
        <p>{parse(dictionary.legal_intellectual_txt)}</p>
        <p>{parse(dictionary.legal_intellectual_txt1)}</p>
        <ul style={{ listStyle: "inside" }}>
          <li>{dictionary.legal_intellectual_list_txt1}</li>
          <br />
          <li>{dictionary.legal_intellectual_list_txt2}</li>
          <br />
          <li>{dictionary.legal_intellectual_list_txt3}</li>
          <br />
        </ul>
        <p>{dictionary.legal_intellectual_txt2}</p>
      </Chapter>

      <Chapter title={dictionary.legal_modifications_title}>
        <p>{parse(dictionary.legal_modifications_txt1)}</p>
      </Chapter>
      <Chapter title={dictionary.legal_responsability_title}>
        <p>{dictionary.legal_responsability_txt1}</p>
        <ul style={{ listStyle: "inside" }}>
          <li>{dictionary.legal_responsability_list_txt1}</li>
          <br />
          <li>{dictionary.legal_responsability_list_txt2}</li>
          <br />
          <li>{dictionary.legal_responsability_list_txt3}</li>
          <br />
        </ul>
      </Chapter>
      <Chapter title={dictionary.legal_protection_title}>
        <p>
          {dictionary.legal_protection_txt1}{" "}
          <Link to={"/privacy-policy"}> {dictionary.footer_privacy}.</Link>
        </p>
      </Chapter>
      <Chapter title={dictionary.legal_jurisdiction_title}>
        <p>{dictionary.legal_jurisdiction_txt}</p>
      </Chapter> */}
      <p>{parse(dictionary.legal_update)}</p>
    </div>
  );
}

export default Legal;
