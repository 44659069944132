/** @jsxRuntime classic */
/** @jsx jsx */

import LanguageSelect from "./LanguageSelect";
import Socialbar from "./Socialbar";
import { topbarDynamic, topbarStyle } from "./topbarStyle";
import { css, jsx } from "@emotion/react";
import React, { useContext } from "react";
import { HeaderContext } from "./Header";
import ToggleTheme from "./ToggleTheme";

const Topbar = React.forwardRef(({}, ref) => {
  const { hover, setHover, height, compact, home } = useContext(HeaderContext);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      ref={ref}
      css={[
        topbarStyle,
        topbarDynamic(compact, height, home),
        hover &&
          css`
            top: 0px;
          `,
      ]}
    >
      <div className={"inner-top-bar " + (home ? "home" : "")}>
        <ToggleTheme />
        <LanguageSelect />
        <Socialbar height="20px" fill="black" />
      </div>
    </div>
  );
});

export default Topbar;
