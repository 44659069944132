import { animated } from "@react-spring/web";
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL_IMG } from "../../constants";
import { Skeleton } from "@material-ui/lab";
import LazyLoad from "react-lazy-load";
import defaultImage from "../../../assets/players_shadow/player.png";

function PlayerCard({ data, style, hoverIn, hoverOut, transformed }) {
  const [baseUrl, setBaseUrl] = useState();
  let name = data.name.trim();
  name = name.replace(/\s/g, "");
  let surname = data.surname.trim();
  surname = surname.replace(/\s/g, "");
  useEffect(() => {
    if (data) {
      setBaseUrl(BASE_URL_IMG + data.img_profile_url);
    }
  }, [data]);
  return (
    <div className="player-card">
      <Link 
      to={`/players/${data.id}-${name}-${surname}#playerProfile`}>
        <animated.div
          onMouseEnter={hoverIn}
          onMouseLeave={hoverOut}
          style={style}
          className="player-card"
        >
          <div className="text-container" style={{ marginBottom: "20px" }}>
            <div>
              {" "}
              <span><h>{data.name}</h> </span>{" "}
            </div>
            <div>
              {" "}
              <span><h3>{data.surname}</h3> </span>
            </div>
          </div>

          <div
            className={`${
              transformed === true ? "player-home" : "player-page"
            } image-players`}
          >
            <div className="img-container">
              <LazyLoad height={"100%"} offset={300}>
                <img
                style={{ paddingBottom:'50px'}}
                  src={baseUrl}
                  alt={`player-${data.name}`}
                  onError={(e) => {
                    e.target.src = defaultImage;
/*                     e.target.style.opacity = "0.3"; */
                    e.target.style.height = "95%";
                  }}
                  /*  style={{width:"270px"}} */
                />
              </LazyLoad>
            </div>
          </div>
        </animated.div>
      </Link>
    </div>
  );
}

export default PlayerCard;
