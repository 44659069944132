/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import { animated } from "@react-spring/web";
import React, { memo, useContext } from "react";
import { Context } from "../../../Context";
import { playerNewsStyle } from "../../Coaches/Coach Page/style";

const PlayerNews = ({ style, player }) => {
  const {
    dictionary: { dictionary },
  } = useContext(Context);

  return (
    <animated.div style={style} css={playerNewsStyle}>
      <h2>{dictionary.menu_news}</h2>
      <div className="news">
        {player.news.map((data) => {
          return (
            <a
              key={data.id}
              href={data.url}
              className="news-card"
              target="_blank"
            >
              <h3>{data.title}</h3>
              <p>{data.text}</p>
            </a>
          );
        })}
      </div>
    </animated.div>
  );
};

export default memo(PlayerNews);
