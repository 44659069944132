import { css } from "@emotion/react";

export const logoContainer = (theme) => css`
  label: logo-container;
  width: 100%;
  max-width: 150px;
  margin-bottom: -6px;
  padding: 1rem;
  padding-left: 0;

  @media (max-width: 700px) {
    max-width: 150px;
    padding: 0.5rem;
    padding-left: 0;
    margin: 0;
  }
`;

export const logoStyle = (theme) => css`
  .LOGO {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: ${theme.dark ? "#ce3d5a" : "#f5224c"};
  }

  .text {
    .SPORTS {
      stroke-linejoin: round;
      fill: none;
      stroke: ${theme.textColor};
      stroke-width: 2.5512;
      stroke-miterlimit: 1.2756;
      transform: scale(1);
    }

    .ELITE {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: ${theme.textColor};
    }
  }
`;
