/** @jsxRuntime classic */
/** @jsx jsx */

import getAge from "../../../Helpers/getAge";
import { cmToFt, kgToLb } from "../../../Helpers/converters";
import { jsx } from "@emotion/react";
import { getLangLocale } from "../../../Helpers/getLangCode";
import { useContext, useRef, useState } from "react";
import useFitText from "../../../Hooks/useFitText";
import useFadeRange from "../../../Hooks/useFadeRange";
import { playerHeaderStyle } from "./style";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useSpring, animated, config } from "@react-spring/web";
import PlayerCareer from "./PlayerCareer";
import { Context } from "../../../Context";
import transferMarktLogo from "../../../../assets/images/Transfermarkt_logo.png";
import { BASE_URL_IMG } from "../../../constants";
import defaultImage from "../../../../assets/players_shadow/shadow_xavi.png";
import defaultLogo from "../../../../assets/images/logo_shadow.png";
// svg imagen de placeholder
// import loading_players from "../../../../../src/assets/images/loading_players.svg";
// import shadow_artur from "../../../../../src/assets/players_shadow/shadow_artur.png";
// import shadow_bernat from "../../../../../src/assets/players_shadow/shadow_bernat.png";
// import shadow_collins from "../../../../../src/assets/players_shadow/shadow_collins.png";
// import shadow_isaac from "../../../../../src/assets/players_shadow/shadow_isaac.png";
// import shadow_kabir from "../../../../../src/assets/players_shadow/shadow_kabir.png";
// import shadow_luca from "../../../../../src/assets/players_shadow/shadow_luca.png";
// import shadow_tristan from "../../../../../src/assets/players_shadow/shadow_tristan.png";
// import shadow_xavi from "../../../../../src/assets/players_shadow/shadow_xavi.png";
import GoBack from "../../../UI/GoBack";

function PlayerHeader({ player, language, mobile, id }) {
  const langLocale = getLangLocale(language);
  const [hover, setHover] = useState(false);
  const profileRef = useRef();
  const [textRef, ref, width] = useFitText(player);
  const [loading, setLoading] = useState(false);
  //numero del jugador
  const player_num = parseInt(id.slice(0, 2));
  console.log(player);

  const {
    dictionary: { dictionary },
  } = useContext(Context);
  useFadeRange(profileRef, width, {
    contrast: [850, 1130, 0.07, 1],
    contrastDark: [850, 1130, 0.3, 1],
    brightness: [850, 1130, 1.5, 1],
    brightnessDark: [850, 1130, 0.4, 0.8],
  });

  // const shadowPlayer = (id) => {
  //   switch (id) {
  //     case 8:
  //       return <img src={shadow_artur} alt="artur" loading="lazy" />;

  //     case 9:
  //       return <img src={shadow_bernat} alt="bernat" loading="lazy" />;

  //     case 11:
  //       return <img src={shadow_collins} alt="collins" loading="lazy" />;

  //     case 10:
  //       return <img src={shadow_isaac} alt="isaac" loading="lazy" />;

  //     case 12:
  //       return <img src={shadow_kabir} alt="kabir" loading="lazy" />;

  //     case 13:
  //       return <img src={shadow_tristan} alt="tristan" loading="lazy" />;
  //     case 14:
  //       return <img src={shadow_luca} alt="luca" loading="lazy" />;

  //     case 15:
  //       return <img src={shadow_xavi} alt="Xavi" loading="lazy" />;

  //     default:
  //       return <img src={loading_players} alt="cr7" loading="lazy" />;
  //   }
  // };

  return (
    <div ref={ref} css={playerHeaderStyle} className="player-header">
      <GoBack path="/players" />

      <h1 className="title-name" id="playerProfile" style={{ width: "50%" }}>
        {player.data.name} <br /> {player.data.surname}
      </h1>
      <div className="column-container">
        <div className="col">
          <div className="player-title">
            <div className="current-team">
              <img
                src={BASE_URL_IMG + player.data.current_team_img}
                alt={player.data.current_team + " logo"}
                onError={(e) => {
                  e.target.src = defaultLogo;
                }}
                loading="lazy"
              />
              <h3>{player.data.current_team_name ? player.data.current_team_name.toUpperCase() : " "}</h3>
            </div>
            <div className="measurements">
              <div className="col height">
                <h4>{dictionary.player_height}</h4>
                <h3>{player.data.height / 100}m</h3>
                <p>{`/${cmToFt(player.data.height)[0]}ft ${
                  cmToFt(player.data.height)[1] < 1
                    ? ""
                    : cmToFt(player.data.height)[1] + "in"
                }`}</p>
              </div>
              <div className="col weight">
                <h4>{dictionary.player_weight}</h4>
                <h3>{player.data.weight}kg</h3>
                <p>/{kgToLb(player.data.weight)} lbs</p>
              </div>
            </div>
          </div>
          <div className="player-bio">
            <div className="born">
              <b>{dictionary.player_born}</b>
              {new Date(player.data.birthdate).toLocaleDateString(langLocale)}
            </div>
            <div className="age">
              <b>{dictionary.player_age}</b>
              {getAge(player.data.birthdate)}
            </div>

            <div className="from">
              {player.data.segunda_nacionalidad !== null ? (
                <b>Nationalities</b>
              ) : (
                <b>{dictionary.player_form}</b>
              )}

              {player.data.nacionalidad ?? "España"}
              <br />
              {player.data.segunda_nacionalidad}
            </div>

            <div className="foot">
              <b>{dictionary.player_foot}</b>
              <div className="foot-img">{player.data.foot_name}</div>
            </div>

            {player.data.transfermarkt_url && (
              <div className="transfer-markt">
                <b>Transfermarkt</b>
                <a
                  href={player.data.transfermarkt_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={transferMarktLogo}
                    alt="Transfermarkt Logo"
                    loading="lazy"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
        {!mobile && <PlayerCareer player={player} />}
      </div>
      <h2 className="player-number">#{player.data.jersey_nro}</h2>
      <div className="background">
        <div className="img-container">
          <img
            ref={profileRef}
            src={BASE_URL_IMG + player.data.img_header_url}
            onError={(e) => {
              e.target.src = defaultImage;
            }}
            alt="`player"
          />

          <img
            src={BASE_URL_IMG + player.data.img_header_url}
            onError={(e) => {
              e.target.src = defaultImage;
            }}
            alt="player"
          />
        </div>
        <div className="text-container">
          <h1 ref={textRef}>{player.data.nacionalidad ?? "España"}</h1>
        </div>
      </div>
    </div>
  );
}

export default PlayerHeader;
