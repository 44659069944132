import { useLocalStorage } from "react-use";
import { useTransition, config, animated } from "react-spring";
import { useState } from "react";

export default function useCookiesLS() {
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage(
    "cookiesAccepted",
    null
  );
  const [localState, setLocalState] = useState(cookiesAccepted);
  const transition = useTransition(localState, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    config: config.gentle,
  });

  const handleClick = (props) => {
    if (props === true) {
      setCookiesAccepted(true);
      setLocalState(true);
      if (window.location.pathname !== "/") {
        window.location.reload();
      }
    } else if (props === false) {
      setCookiesAccepted(false);
      setLocalState(true);
      window.location.reload();
    }

    // else {
    //   setCookiesAccepted(false);
    //   setLocalState(true);
    //   if (window.location.pathname !== "/") {
    //     window.location.reload();
    //   }
    // }
  };
  const handleRejection = () => {};

  return [transition, handleClick];
}
