/** @jsxRuntime classic */
/** @jsx jsx */

import { animated, config } from "@react-spring/web";
import useBoop from "../Hooks/useBoop";
import de from "../../assets/flags/de.svg";
import es from "../../assets/flags/es.svg";
import fr from "../../assets/flags/fr.svg";
import gb from "../../assets/flags/gb.svg";
import pt from "../../assets/flags/pt.svg";
import cn from "../../assets/flags/cn.svg";
import it from "../../assets/flags/it.svg";
import ru from "../../assets/flags/ru.svg";
import jp from "../../assets/flags/jp.svg";
import { jsx } from "@emotion/react";

function CountryFlag({
  lang,
  height,
  x = 0,
  y = -5,
  rotation = 0,
  scale = 1,
  timing = 0,
  springConfig = config.gentle,
  withAnimation = true,
}) {
  let icon;
  const [style, trigger, triggerExit] = useBoop({
    x,
    y,
    rotation,
    scale,
    timing,
    springConfig,
  });
  switch (lang) {
    case "English":
      icon = gb;
      break;
    case "Italian":
      icon = it;
      break;
    case "Spanish":
      icon = es;
      break;
    case "French":
      icon = fr;
      break;
    case "Portuguese":
      icon = pt;
      break;
    case "Chinese":
      icon = cn;
      break;
    case "German":
      icon = de;
      break;
    case "Japanese":
      icon = jp;
      break;
    case "Russian":
      icon = ru;
      break;
    default:
      break;
  }
  return (
    <animated.img
      {...(withAnimation && { style: style })}
      onMouseOver={trigger}
      onMouseOut={triggerExit}
      src={icon}
      alt={lang}
      title={lang}
      height={height + "px"}
      width={(height * 5) / 3 + "px"}
      className="country-flag"
    ></animated.img>
  );
}

export default CountryFlag;
