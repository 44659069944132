/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import useAxios from "axios-hooks";
import React, { useContext, useEffect, useState } from "react";
import { headerStyle, style } from "./style";
import CoachCard from "./CoachCard";
import { getLangCode } from "../../Helpers/getLangCode";
import { Context } from "../../Context";
import useMasonrySpring from "../../Hooks/useMasonrySpring";
import { BASE_URL } from "../../constants";
import Footer from "../../Footer";
import GoBack from "../../UI/GoBack";
import spinner from'../../../assets/images/loading.gif'


function Coaches() {
  const {
    language: { language },
  } = useContext(Context);
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  const langCode = getLangCode(language);
  const [players, setPlayers] = useState([]);
  const [hoverStates, setHoverStates] = useState({});

  let [{ data, loading, error }, refetch] = useAxios({
    url: BASE_URL + `/coaches?countryid=${langCode}`,
    method: "GET",
    responseType: "json",
  });

  useEffect(() => {
    //para que cunado nos montemos en la app subamos ahsta el inico de la misma
    window.scrollTo(0, 0);
    if (data) {
      setPlayers(data);
      setHoverStates(() => {
        let hoverStore = {};
        data.map((player) => {
          hoverStore[player.id] = false;
        });
        return hoverStore;
      });
    }
  }, [data]);

  const [ref, heights, transition, containerHeight] = useMasonrySpring(
    players,
    400,
    25,
    hoverStates
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simula una carga asincrónica del componente
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);


  return data ? (
    <>
      <div className="coachesHeader" css={headerStyle}>
        <div className="coachesHeaderImg">
          <div className="coachesHeaderTitle">
            <span className="span">
              {dictionary.home_coaches_title} {dictionary.home_coaches}
            </span>
          </div>
        </div>

        <div className="players" style={{minHeight:"300px"}} css={style}>
          <h2 className="coachesTitle">
            {dictionary.home_coaches_title} {dictionary.home_coaches}
          </h2>

          {isLoading ? (
        <img src={spinner} alt="Loading spinner" height={300} />
      ) : (
          <div
            className="players-container"
            ref={ref}
            style={{
              height: Math.max(...heights) + containerHeight,
            }}
          >
            <GoBack path="/" />
            {data &&
              transition(({ xy, ...rest }, player) => {
                return (
                  <CoachCard
                    hoverIn={() => {
                      setHoverStates((prev) => ({
                        ...prev,
                        [player.id]: true,
                      }));
                    }}
                    hoverOut={() => {
                      setHoverStates((prev) => ({
                        ...prev,
                        [player.id]: false,
                      }));
                    }}
                    style={{
                      transform: xy.interpolate(
                        (x, y) => `translate3d(${x}px,${y}px,0)`
                      ),
                      ...rest,
                    }}
                    key={player.id}
                    data={player}
                  ></CoachCard>
                );
              })}
          </div>
      )}
        </div>
      </div>

      <Footer />
    </>
  ) : null;
}

export default Coaches;
