/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { style } from "./tabMenuStyle";

function TabMenu({ current, setMenu, tabs, activeClass = "active-tab" }) {
  return (
    <div className="tab-menu" css={style}>
      {tabs.map((item) => (
        <div
          key={item.value}
          onClick={() => setMenu(item.value)}
          className={
            "tab " +
            (current.toLowerCase() === item.value.toLowerCase()
              ? activeClass
              : "")
          }
        >
          {item.icon}
          <p title={item.name}>{item.name}</p>
        </div>
      ))}
    </div>
  );
}

export default TabMenu;
