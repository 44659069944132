/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import { animated, useSpringRef } from "@react-spring/web";
import React, { memo, useContext, useState } from "react";
import { BASE_URL_IMG } from "../../../constants";
import { Context } from "../../../Context";
import useMasonrySpring from "../../../Hooks/useMasonrySpring";
import { playerGalleryStyle } from "../../Coaches/Coach Page/style";

const PlayerGallery = ({ style, player, animating }) => {
  const galleryRef = useSpringRef();
  const [full, setFull] = useState(false);
  const [ref, heights, transition, containerHeight] = useMasonrySpring(
    player.galery,
    400,
    25,
    false,
    galleryRef
  );
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  function handleClick(e) {
    e.target.parentElement.classList.toggle("full");
    setFull(!full);
  }
  galleryRef.start();

  return (
    <animated.div
      style={{ ...style, ...(full ? { transform: "none" } : {}) }}
      css={playerGalleryStyle}
    >
      <h2>{dictionary.coach_photos_title}</h2>
      <animated.div
        ref={ref}
        style={{
          width: "100%",
          height: Math.max(...heights) + containerHeight,
        }}
      >
        {transition(({ xy, id, ...rest }, item) => {
          return (
            <animated.div
              className="img-container"
              onClick={handleClick}
              style={{
                transform: xy.interpolate((x, y) => {
                  return `translate3d(${x}px,${y}px,0)`;
                }),
                ...rest,
                position: "absolute",
              }}
              key={id}
            >


            <img src={BASE_URL_IMG + item.child} alt="" />
            </animated.div>
          );
        })}
      </animated.div>
    </animated.div>
  );
};

export default memo(PlayerGallery);
