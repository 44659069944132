/** @jsxRuntime classic */
/** @jsx jsx */

import { animated, useTransition } from "react-spring";
import { css, jsx } from "@emotion/react";
import PropTypes from "prop-types";
import useToggleSprings from "./useToggleSprings";
import useSelectVariant from "./useSelectVariant";
import { useEffect } from "react";

function Toggle({ variant, onToggleChange, ...props }) {
  const toggleStyle = useSelectVariant(variant, props);
  const [
    toggleTheme,
    toggleState,
    onHover,
    style,
    dialStyle,
  ] = useToggleSprings({
    ...toggleStyle,
  });
  const AnimatedOff = animated(toggleStyle.offIcon);
  const AnimatedOn = animated(toggleStyle.onIcon);

  useEffect(() => {
    onToggleChange(toggleState);
  }, [toggleState]);

  const transition = useTransition(toggleState, {
    from: {
      position: "absolute",
      opacity: 0,
    },
    enter: {
      position: "relative",
      opacity: 1,
    },
    leave: { position: "absolute", opacity: 0 },
  });
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        overflow: visible;
        width: fit-content;
      `}
      className="theme-toggle-container"
    >
      <animated.div
        className="theme-toggle"
        style={style}
        onClick={toggleTheme}
      >
        <animated.div
          className="toggle-dial"
          style={dialStyle}
          {...onHover}
          css={(theme) => css`
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${theme.moonColor};
          `}
        >
          {transition((trans, state) =>
            state ? (
              <AnimatedOff
                fill={"#ffab00"}
                stroke="none"
                style={trans}
              ></AnimatedOff>
            ) : (
              <AnimatedOn
                fill={"#ffab00"}
                stroke={"#ffab00"}
                style={trans}
              ></AnimatedOn>
            )
          )}
        </animated.div>
      </animated.div>
    </div>
  );
}

Toggle.propTypes = {
  variant: PropTypes.oneOf(["theme", "default", "custom"]),
  onToggleChange: PropTypes.func,
};

Toggle.defaultProps = {
  variant: "default",
  onToggleChange: () => {},
};

export default Toggle;
