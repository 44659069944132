/** @jsxRuntime classic */
/** @jsx jsx */

import { animated } from "@react-spring/web";
import { NavLink } from "react-router-dom";
import useBoop from "../Hooks/useBoop";
import { jsx, ClassNames, useTheme } from "@emotion/react";
import {
  activeLink,
  animatedNavLinkStyle,
  carretStyle,
} from "./animatedNavLinkStyle";

function AnimatedNavLink({ setOpen = () => null, name, config = {} }) {
  const [style, trigger] = useBoop(config);
  const theme = useTheme();
  return (
    <ClassNames>
      {({ css }) => (
        <NavLink
          onClick={() => setOpen(false)}
          css={animatedNavLinkStyle}
          activeClassName={css(activeLink(theme))}
          to={
            name[0] === "Home"
              ? "/"
              : `/${name[0].toLowerCase().replace(" ", "-")}`
          }
          exact
          key={name[0]}
        >
          <h4 title={name[1]}>{name[1]}</h4>
        </NavLink>
      )}
    </ClassNames>
  );
}

export default AnimatedNavLink;
