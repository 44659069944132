import React from "react";
import { config, useSpring } from "react-spring";
// UPDATE this path to your copy of the hook!

function useBoop({
  x = 0,
  y = 0,
  rotation = 0,
  scale = 1,
  timing = 150,
  originalRotation = 0,
  springConfig = config.molasses,
  loop = true,
}) {
  const [isBooped, setIsBooped] = React.useState(false);
  const style = useSpring({
    transform: isBooped
      ? `translate(${x}px, ${y}px)
         rotate(${rotation}deg)
         scale(${scale})`
      : `translate(0px, 0px)
      rotate(${originalRotation}deg)
         scale(1)`,
    config: { ...springConfig, loop: { reverse: loop } },
  });
  React.useEffect(() => {
    if (timing) {
      if (!isBooped) {
        return;
      }
      const timeoutId = window.setTimeout(() => {
        setIsBooped(false);
      }, timing);
      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [timing, isBooped]);

  const trigger = React.useCallback(() => {
    setIsBooped(true);
  }, []);

  const triggerExit = React.useCallback(() => {
    setIsBooped(false);
  });
  return [style, trigger, triggerExit];
}

export default useBoop;
