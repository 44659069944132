import { css } from "@emotion/react";
import { flexGap } from "../../Helpers/flexGap";
import coachesHeaderBackground from "../../../assets/images/background-coaches.jpg";
import backgroundBody from "../../../assets/images/background-body.jpg";
import coachesBackgroundImg from "../../../assets/images/img-home_coaches-container_coach.png";
import coachTextContainerImg from "../../../assets/images/img-home_coaches-container_name.png";

export const headerStyle = (theme) => `css
  width: 100%;
  height: 100%;
  position: relative;
  top: 1rem;
  background-image: url(${theme.dark ? null : backgroundBody});
  background-size: cover;

  .coachesHeaderImg {
    margin-top: 3rem;
   
    background: rgb(121,201,66);
    background: linear-gradient(324deg, rgba(121,201,66,0.768411759234944) 49%, rgba(5,116,187,1) 98%);
    //border: red solid 10px;
    
    .coachesHeaderTitle {
      margin: auto;
      display: flex;
      flex-direction: column; 
      max-width: ${theme.contentMaxWidth};

      span {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        font-size: 50px;
        text-transform: uppercase;  
        color: #fff;
      }

      @media(max-width: 1020px) {
        span {
          display: none;
        }
      }

      } 

  .coachesBackgroundImg {
     border: solid #fff 10px; 
      background-image: url(${coachesHeaderBackground});
      background-size: cover;
  }
`;

export const style = (theme) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${theme.padding};

  @media (min-width: 1024px) {
    .coachesTitle {
      display: none;
    }
  }

  @media (max-width: 700px) {
    padding: ${theme.paddingM};
  }

  .players-container {
    margin-top: 3rem;
    margin-bottom: 7rem;
    width: 100%;
    max-width: ${theme.contentMaxWidth};

    .tab-menu {
      @media (max-width: 900px) {
      }
    }
  }

  .back-button {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    /* margin-left: 0.5rem; */
    cursor: pointer;
    width: fit-content;
    color: ${theme.accentColor2};
    text-decoration: none;
    .icon-container {
      svg {
        margin-top: -1rem;
        display: block;
      }
    }
    p {
      //margin: 0.4rem 0.5rem;
      //margin-bottom: 4rem;
    }
  }

  .player-card {
    border-radius: ${theme.borderRadius};
    cursor: pointer;
    display: flex;
    position: absolute;
    // will-change: transform, width, height, opacity;
    padding: 1rem;
    z-index: auto;
    @media (max-width: 700px) {
      padding: 0.7rem;

      h3 {
        font-size: ${theme.p};
      }
    }

    @media (max-width: 550px) {
      h3 {
        display: none;
      }
    }

    h3 {
      font-weight: bold;
    }
    ::after {
      content: "+";
      position: absolute;
      color: white;
      top: 10px;
      right: 10px;
      background-color: ${theme.accentColor2};
      padding: 0.5rem 1rem;
      display: block;
      opacity: 0;
      border-radius: 100px;
      transform-origin: right;
      transform: translate3d(0, -10px, 0);
      transition: all ease-in-out 0.3s;
      @media (max-width: 410px) {
        content: "+";
        padding: 0;
        width: 2rem;
        height: 2rem;
        padding: 0.36rem;
        font-size: ${theme.p};
        text-align: center;
        min-width: fit-content;
      }
    }
    :hover {
      z-index: 20;
      ::after {
        display: initial;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: all ease-in-out 0.3s;
      }
    }
    .text-container {
      margin-bottom: 20px;

      height: 52px;
      display: flex;
      flex-direction: column;
      background: url(${coachTextContainerImg});
      color: #fff;
      width: 100%;
      z-index: 2;
      position: absolute;
      bottom: -30px;
      left: 0;

      @media (max-width: 430px) {
        font-size: 0.8rem;
        min-height: 50px;
        padding: 0.1rem;
        margin: 10px auto;
      }

      span {
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    .img-container {
      background-image: url(${coachesBackgroundImg});

      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      overflow: hidden;
      ::after {
        content: "";
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        /* opacity: 0.3; */
      }
    }
  }
`;
