/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx, useTheme } from "@emotion/react";
import NewsCard from "./NewsCard";
import { containerStyle, headerStyle } from "./style";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import React, { useContext, useEffect, useRef } from "react";
import useTranslateNews from "./newsData";
import useAxios from "axios-hooks";
import { BASE_URL, BASE_URL_IMG } from "../../constants";
import { Context } from "../../Context";
import { getLangCode } from "../../Helpers/getLangCode";
import Footer from "../../Footer";
import { useLocalStorage } from "react-use";
import fut from "../../../assets/images/fut.jpg";

function News() {
  const {
    language: { language },
  } = useContext(Context);
  const langCode = getLangCode(language);
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage(
    "cookiesAccepted",
    false
  );

  const {
    dictionary: { dictionary },
  } = useContext(Context);

  const theme = useTheme();
  const ref = useRef();
  const news = useTranslateNews();

  let [{ data, loading, error }, refetch] = useAxios({
    url: BASE_URL + `/news.general?countryid=${langCode}`,
    method: "GET",
    responseType: "json",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    return data && data;
  }, [data]);

  console.log(data);
  return data ? (
    <>
      <div className="newsWrapper" css={headerStyle}>
        <div className="newsHeaderImg">
          <div className="newsHeaderTitle">
            <span>{dictionary.menu_news}</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          <img src={fut} height={400} alt="futbol" />
        </div>
        <div
          className="yellowWRapper"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="news-container" css={containerStyle}>
            <div className="news">
              {data &&
                data.map((item) => (
                  <NewsCard
                    key={item.id}
                    {...item}
                    img={BASE_URL_IMG + item.img_url}
                  ></NewsCard>
                ))}
            </div>
          </div>
          {cookiesAccepted === true && (
            <div className="socialTimeline">
              <TwitterTimelineEmbed
                ref={ref}
                sourceType="profile"
                screenName="Soccer18Elite"
                theme={theme.dark ? "dark" : "light"}
                noHeader={true}
                noFooter={true}
                options={{ tweetLimit: "3" }}
              />
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  ) : null;
}

export default News;
