import { ReactComponent as InstagramLogo } from "../../assets/social/instagram.svg";
import { ReactComponent as FacebookLogo } from "../../assets/social/facebook.svg";
import { ReactComponent as TwitterLogo } from "../../assets/social/twitter.svg";
import { ReactComponent as LinkedinLogo } from "../../assets/social/linkedin.svg";
import { ReactComponent as YoutubeLogo } from "../../assets/social/youtube.svg";
//test
const social = [
  {
    logo: InstagramLogo,
    alt: "Instagram",
    url: "https://www.instagram.com/elitesports17sl/",
  },
  {
    logo: FacebookLogo,
    alt: "Facebook",
    url: "https://www.facebook.com/EliteSports17",
  },
  {
    logo: TwitterLogo,
    alt: "Twitter",
    url: "https://twitter.com/elitesports17",
  },
  {
    logo: LinkedinLogo,
    alt: "Linkedin",
    url: "https://es.linkedin.com/company/elite-sports-17-sl",
  },
  {
    logo: YoutubeLogo,
    alt: "Youtube",
    url: "https://www.youtube.com/channel/UCKHUrEYYNvUq5Aqy9kOGA1g",
  },
];

export default social;
