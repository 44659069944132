/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import { useChain, useSpringRef, useTransition } from "@react-spring/core";
import { animated } from "@react-spring/web";
import { useState, useEffect, useCallback } from "react";
import TabMenu from "../../../TabMenu";

import { playerBodyStyle } from "./style";
import { useLangTabData } from "./tabData";

function CoachBody({ player, mobile }) {
  const [current, setCurrent] = useState("Player Bio");
  const tabs = useLangTabData(player);
  let CurrentTab = tabs.filter((item) => item.value === current)[0].component;
  const transRef = useSpringRef();
  const transitions = useTransition(current, {
    ref: transRef,
    keys: null,
    from: {
      position: "absolute",
      opacity: 0,
      transform: "translate3d(100%,0,0)",
    },
    enter: {
      position: "unset",
      opacity: 1,
      transform: "translate3d(0%,0,0)",
    },
    leave: {
      position: "absolute",
      opacity: 0,
      transform: "translate3d(-100%,0,0)",
    },
  });

  useEffect(() => {
    transRef.start();
  }, [current]);

  return (
    <animated.div
      key="player-body"
      className="player-body"
      css={playerBodyStyle}
    >
      <TabMenu current={current} setMenu={setCurrent} tabs={tabs}></TabMenu>

      <div style={{ position: "relative", width: "100%", overflow: "hidden" }}>
        {transitions((style, i) => {
          return <CurrentTab mobile={mobile} style={style} player={player} />;
        })}
      </div>
    </animated.div>
  );
}

export default CoachBody;
