import { ReactComponent as AllIcon } from "../../../assets/football/all.svg";
import { ReactComponent as GoalkeeperIcon } from "../../../assets/football/goalkeeper.svg";
import { ReactComponent as StrikerIcon } from "../../../assets/football/striker.svg";
import { ReactComponent as MidfieldIcon } from "../../../assets/football/midfield.svg";
import { ReactComponent as DefenderIcon } from "../../../assets/football/defense.svg";
import { ReactComponent as WomenIcon } from "./../../../assets/football/female-football.svg";
import { ReactComponent as MenIcon } from "./../../../assets/football/futbol-masculline.svg";
import { useContext } from "react";
import { Context } from "../../Context";

export function useLangTabs() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  let tabs;
  return (tabs = [
    { name: dictionary.masculine, value: "all", icon: <MenIcon></MenIcon> },
    // { name: "Masculino", value: "Masculino", icon: <AllIcon></AllIcon> },

    {
      name: dictionary.femenine,
      value: "feminine",
      icon: <WomenIcon></WomenIcon>,
    },
    // {
    //   name: dictionary.players_gks,
    //   value: "goalkeepers",
    //   icon: <GoalkeeperIcon></GoalkeeperIcon>,
    // },
    // {
    //   name: dictionary.players_dfs,
    //   value: "defenders",
    //   icon: <DefenderIcon></DefenderIcon>,
    // },
    // {
    //   name: dictionary.players_mcs,
    //   value: "midfileders",
    //   icon: <MidfieldIcon></MidfieldIcon>,
    // },
    // {
    //   name: dictionary.players_sts,
    //   value: "strikers",
    //   icon: <StrikerIcon></StrikerIcon>,
    // },
  ]);
}
