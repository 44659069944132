import { css } from "@emotion/react";

export const cookies = (theme) => css`
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(${theme.contentMaxWidth} - 200px);
  background-color: ${theme.backgroundColor};
  padding: 1.5rem 2rem;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 2rem;

  box-shadow: inset 0 0 0 2px ${theme.textColor};
  border-radius: ${theme.borderRadius};

  &::before {
    z-index: 9;
    border-radius: ${theme.borderRadius};
    mix-blend-mode: multiply;
    content: "";
    box-shadow: 15px 15px 0px 0px ${theme.accentColor};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  h3 {
    z-index: 10;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
  }

  .legal-text {
    z-index: 10;
    column-width: 300px;
    column-gap: 1rem;
    column-rule: 2px ${theme.accentColor} solid;
    line-height: 140%;
    color: ${theme.textColor};
    p {
      margin-top: 1rem;
      &:nth-of-type(1) {
        margin-top: 0;
      }
    }
  }
`;

export const buttonContainer = css`
  label: button-container;
  z-index: 10;
  width: 100%;
  display: flex;
  margin-top: 2rem;
  align-items: flex-end;
  justify-content: space-between;
  .button {
    width: max-content;
    max-width: 200px;
  }
`;
