/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, useTheme } from "@emotion/react";
import { animated } from "@react-spring/web";
import { playerBioStyle, radarStyle } from "./style";
import { ReactComponent as QuotesIcon } from "../../../../assets/reviews/quotes.svg";
import PlayerFieldPosition from "./PlayerFieldPosition";
import RadarChart from "react-svg-radar-chart";
import "react-svg-radar-chart/build/css/index.css";
import React, { useContext } from "react";
import PlayerCareer from "./PlayerCareer";
import { Context } from "../../../Context";

const PlayerBio = ({ style, player, mobile }) => {
  const theme = useTheme();
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  const captions = {
    defending: dictionary.player_stat_defending,
    dribbling: dictionary.player_stat_dribbling,
    pace: dictionary.player_stat_pace,
    passing: dictionary.player_stat_passing,
    physicality: dictionary.player_stat_physicality,
    shooting: dictionary.player_stat_shooting,
  };
  const performance = { ...player.performance[0] };
  Object.keys(performance).map((key) => {
    performance[key] /= 10;
  });

  const data = [
    {
      data: {
        ...performance,
      },
      meta: {
        color: theme.textColor,
        class: "custom-radar",
      },
    },
  ];

  return (
    <animated.div style={style} css={playerBioStyle}>
      <h2>{dictionary.player_bio_introduction}</h2>
      <div className="content">
        <div className="column">
          <div className="bio">
            <p>{player.text.bio}</p>
          </div>
          <div className="bio quote">
            <blockquote cite={player.data.name + " " + player.data.surname}>
              <p>{player.text.quote} </p>
            </blockquote>

            <div className="img-container">
              <QuotesIcon></QuotesIcon>
             
            </div>
          </div>
        </div>
        <div className="column radar">
          <div css={radarStyle}>
            <RadarChart data={data} captions={captions}></RadarChart>
          </div>
        </div>
      </div>
      <h2>{dictionary.player_bio_position_and_stats}</h2>
      <div className="content">
        <div className="column">
          <h3>
            <b>{dictionary.player_main_position}</b>
          </h3>
          <p>{player.map_positions_translate.primary_description}</p>
          {player.map_positions_translate.secondary.length != 0 && (
            <>
              <h3>
                <b>{dictionary.player_secondary_position}</b>
              </h3>
              <ul>
                {player.map_positions_translate.secondary.map((item) => (
                  <li>{item.description}</li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="column field">
          <PlayerFieldPosition player={player}></PlayerFieldPosition>
        </div>
      </div>
      {mobile && <PlayerCareer player={player}></PlayerCareer>}
    </animated.div>
  );
};

export default PlayerBio;
