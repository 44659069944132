export const global = {
  s: "0.8rem",
  p: "1rem",
  h1: "6rem",
  h2: "2.375rem",
  h3: "1.5rem",
  h4: "1.25rem",

  contentMaxWidth: "1280px",
  padding: "50px",
  paddingM: "25px",
  buttonPadding: "0.5rem 1rem",
  borderRadius: "6px",
  lineHeight: "180%",
  paragBottomMargin: "2rem",
  accentColor: "#6fb7d7",
  accentColor2: "#007eff",
  transition: "all ease-in-out 0.3s",
  defaultShadow: `#00000040 0px 2px 5px -1px,#0000004D 0px 1px 3px -1px`,
  defaultHoverShadow: `#00000040 0px 50px 100px -20px, #0000004D 0px 30px 60px -30px`,
  shadow: (theme, name) =>
    `${theme[name.toLowerCase() + "Color"] + "40"} 0px 2px 5px -1px, ${
      theme[name.toLowerCase() + "Color"] + "4D"
    } 0px 1px 3px -1px`,
  shadowHover: (theme, name) =>
    `${theme[name.toLowerCase() + "Color"] + "40"} 0px 50px 100px -20px, ${
      theme[name.toLowerCase() + "Color"] + "4D"
    } 0px 30px 60px -30px`,
};

export const lightTheme = {
  blendMode: "hard-light",
  dark: false,

  accentColor: "#82cced",

  inputBorderColor: "#e6e6e6",
  inputBackgroundColor: "#d7eaf8",
  inputHoverColor: "#a8d8f1",
  inputBackgroundColorActive: "#f4fbff",

  backgroundColorHigh: "#ffffff",

  backgroundColor: "#f7fdff",
  backgroundColorLighter: "#f7fdff",
  textColor: "#000000",
  secondaryTextColor: "#57929e",
  secondaryTextColorLighter: "#d6e9f0",
  socialLogoColor: "#000000",
  socialLogoColorInverse: "#ffffff",

  hoverMenuShadow:
    "inset 0px 30px 10px -30px rgba(0,0,0,0.23), inset 0px -30px 10px -30px rgba(255,255,255,0.8)",
};

export const darkTheme = {
  blendMode: "multiply",
  dark: true,

  inputBorderColor: "#3d3d3d",
  inputBackgroundColor: "#3f3f3f",
  inputHoverColor: "#666666",
  inputBackgroundColorActive: "#666666",

  backgroundColorHigh: "#242424",

  backgroundColor: "#323237",
  backgroundColorLighter: "#4b4b4b",
  textColor: "#ffffff",
  secondaryTextColor: "#666666",
  secondaryTextColorLighter: "#4a7191",
  socialLogoColor: "#ffffff",
  socialLogoColorInverse: "#000000",

  hoverMenuShadow:
    "inset 0px 30px 10px -30px rgba(0,0,0,0.8), inset 0px -30px 10px -30px rgba(255,255,255,0.15)",
};
