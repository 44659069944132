/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useContext } from "react";
import { jsx, css } from "@emotion/react";
import { aboutUsContainer2 } from "./style";
import { Context } from "../../Context";
import getColumnData from "./getColumnData";
import aboutUsOriginBallonImg from "../../../assets/images/img-about-soccerball320.png";
import parse from "html-react-parser";
import playersImgs from "../../../assets/images/img-about-football_players-combined.png";
import presentSectionPlayer from "../../../assets/images/img-about-playerkick.png";
import futureSectionTrhphy from "../../../assets/images/img-about-trophy320.png";
//players images
import playersImg2 from "../../../assets/images/img-about-football_players-02.jpg";
import playerImg3 from "../../../assets/images/img-about-football_players-01.jpg";
function AboutUs2() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  const columnData = getColumnData(dictionary);
  //para que suba hasta arriba
  window.scrollTo(0, 0);

  return (
    <>
      <div css={aboutUsContainer2}>
        <div className="aboutUsHeaderImg">
          <div className="aboutUsHeaderText">
            <span>{dictionary.menu_about_us}</span>
          </div>
        </div>
        <div className="aboutUsOriginSection">
          <div className="originText">
            <div className="originsTitle">
              <div className="aboutUsTitle">
                <span> {columnData[0].title}</span>
                <p>{dictionary.about_us_origin_sentence}</p>
              </div>
            </div>
            <div className="originsText">{parse(columnData[0].text)}</div>
          </div>
          <div className="originBallImg">
            <img src={aboutUsOriginBallonImg} alt="ballon" />
          </div>
        </div>

        <div className="aboutUsPlayersSection">
          <div className="playersImgs">
            <picture className="hazard">
              <source
                media="(max-width: 1316px)"
                srcSet={playersImg2}
                className="source"
              />

              {/* this is the defaul img, dont delete it */}
              <img src={playersImgs} alt="playersImg" className="hazard3" />
            </picture>

            <picture className="secondPicture">
              <source
                className="hazard2"
                media="(max-width: 480px)"
                srcSet={playerImg3}
              />
              <source media="(max-width: 1316px)" srcSet={playerImg3} />
              <source media="(max-width: 1316px)" srcSet={playerImg3} />

              {/* this is the defaul img, dont delete it */}
              <img src="" alt="" />
            </picture>
          </div>
        </div>

        <div className="aboutUsPresent">
          <div className="aboutUsPresentPlayer">
            <img src={presentSectionPlayer} alt="r" />
          </div>

          <div className="aboutUsPresentText">
            <h2>{columnData[1].title}</h2>
            <p className="agencyPresent">
              {dictionary.about_us_present_sentence}
            </p>
            <p>{parse(columnData[1].text)}</p>
          </div>
        </div>

        <div className="aboutUsFuture">
          <div className="aboutUsFutureText">
            <h2>{columnData[2].title}</h2>
            <div className="innerText">
              <p>{parse(columnData[2].text)}</p>
            </div>
          </div>

          <div className="aboutUsFutureImg">
            <img src={futureSectionTrhphy} alt="trophy" loading="lazy" />
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs2;
