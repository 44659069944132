import { css } from "@emotion/react";
import hexagonImg from "../../../assets/images/img-hexagon-default.png";
import backgroundBody from "../../../assets/images/background-body.jpg";

export const style = (theme) => css`
  width: 100%;
  max-width: 100%;
  position: relative;
  top: 1rem;
  background-image: url(${!theme.dark ? backgroundBody : "#000000"});
  background-size: cover;

  @media(min-width: 1024px) {
    .servicesTitle {
      display: none;
    }
  }
   
  .servicesBanner {
  
    background: rgb(121,201,66);
    background: linear-gradient(324deg, rgba(121,201,66,0.6115490141369048) 44%, rgba(5,116,187,1) 98%);
  
    @media(max-width: 1020px) {
      .span {   
      display: none;
      }
    }


    .servicesHeaderTitle {
      margin: auto;
      margin-top: 3rem;

      display: flex;   
      text-align: center;

      display: flex; 
      justify-content: center;  

      max-width: ${theme.contentMaxWidth};
      

      .span {
         margin-top: 20px;
         margin-bottom: 20px;
         font-size: 50px;
         text-transform: uppercase;  
         color:#fff;
      }     
      
    }
   
   }
  }

  .wrapperPlayer {
    display: flex;
    justify-content: flex-end;
     width: 100%;
     height: 40px;
   

     @media (max-width: 1355px) {
       .servicesPlayerImg {
         display: none;
       }
     }
  }

  @media (max-width:1000px) {
    .kickPlayerImg > img {
      opacity: 0.5;
  
    }
  }

 
  h2 {
    margin: auto;
    margin-top: 3rem;
    font-weight: bold;
  }

 
    .contact-us {
       position: relative;
       bottom: 400px;
       display: flex;
       flex-direction: column;
       margin: 0 auto;
       align-items: center;
       max-width: 60ch;

       span {
        color: #fff;
         font-size: 3em;
         font-weight: bold;
         text-transform: uppercase;
       }
  
       p {
         width: fit-content;
         text-align: center;
       }
       a {
         text-decoration: none;
       }
      
       &-button { 
         padding: 1rem;
         color: #fff;
         border-radius: ${theme.borderRadius};
         background-color: #79c942;
        
       }
    }


  
  @media (max-width: 700px) {
    padding: ${theme.paddingM};
  }
  .categories {
    display: flex;
    flex-direction: column;

  
    @media (max-width: 1304px) {
      .innerContainer {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .championIcon {
      column-count: 1;
      display: flex;
      margin: 2rem auto;
    }

    .servicesColumnFirstRow {
      column-count: 2;
      display: flex;
      justify-content: center;

      .innerContainer {
        display: flex;
        flex-direction: row;
 
      }
    }

    .servicesColumnSecondRow {
      column-count: 3;
      display: flex;
      justify-content: center;

      .innerContainer {
        display: flex;
        flex-direction: row;
 
      }
    }
    .servicesColumnThirdRow {
      column-count: 4;
      display: flex;
      align-items: center;
      justify-content: center;

      .innerContainer {
        display: flex;
        flex-direction: row;
 
      }
    }

    .column {
      display: flex;
      padding: 0.5rem;
      margin: 0 auto;
      gap: 1px;

      .icon {   
        color: #79c942;
      }

      @media (max-width: 1070px) {
        max-width: calc(100% / 2 - 2rem);
      }
      @media (max-width: 700px) {
        max-width: 100%;
      }

      .inner {
        border: solid blue 0px;
        min-width: 0;
        width: 297px;
        height: 341px;
        background-image: url(${hexagonImg});
      }
    }
  }
  
`;
