import { useTheme } from "@emotion/react";
import { FiSun, FiMoon } from "react-icons/fi";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";

export default function useSelectVariant(variant, props) {
  // const theme = useTheme();
  switch (variant) {
    case "theme":
      return {
        onIcon: FiSun,
        offIcon: FiMoon,
        onColor: "#90db03",
        offColor: "#ff3995",
        height: 20,
        width: 40,
        borderRadius: 20,
        borderWidth: 2,
        dialColorOn: "white",
        dialColorOff: "white",
      };

    case "default":
      return {
        onIcon: RiCloseFill,
        offIcon: RiCheckFill,
        onColor: "#90db03",
        offColor: "#c9c9c9",
        height: 20,
        width: 40,
        borderRadius: 20,
        borderWidth: 2,
        dialColorOn: "white",
        dialColorOff: "white",
      };
    case "custom":
      return {
        onIcon: RiCloseFill,
        offIcon: RiCheckFill,
        ...props,
      };
  }
}
