/** @jsxRuntime classic */
/** @jsx jsx */

import transferMarktLogo from "../../../../assets/images/Transfermarkt_logo.png";
import getAge from "../../../Helpers/getAge";
import { jsx } from "@emotion/react";
import { getLangLocale } from "../../../Helpers/getLangCode";
import { useContext, useRef, useState } from "react";
import useFitText from "../../../Hooks/useFitText";
import useFadeRange from "../../../Hooks/useFadeRange";
import { playerHeaderStyle } from "./style";
import { Context } from "../../../Context";
import { BASE_URL_IMG } from "../../../constants";
import loading_coaches from "../../../../../src/assets/images/loading_coaches.svg";
import shadow_langa from "../../../../../src/assets/coaches_shadow/shadow_langa.png";
import shadow_jose_carlos from "../../../../../src/assets/coaches_shadow/shadow_jose_carlos.png";
import shadow_juan_carlos from "../../../../../src/assets/coaches_shadow/shadow_juan_carlos.png";
import defaultLogo from "../../../../assets/images/logo_shadow.png";
import GoBack from "../../../UI/GoBack";

function CoachHeader({ player, language, mobile, id }) {
  const langLocale = getLangLocale(language);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const profileRef = useRef();
  const [textRef, ref, width] = useFitText(player);
  const coach_num = parseInt(id.slice(0, 2));

  const {
    dictionary: { dictionary },
  } = useContext(Context);
  useFadeRange(profileRef, width, {
    contrast: [850, 1130, 0.07, 1],
    contrastDark: [850, 1130, 0.3, 1],
    brightness: [850, 1130, 1.5, 1],
    brightnessDark: [850, 1130, 0.4, 0.8],
  });
console.log(player);
  const shadowCoach = (id) => {
    switch (id) {
      case 1:
        return (
          <img src={shadow_jose_carlos} alt="jose_carlos" loading="lazy" />
        );

      case 2:
        return (
          <img src={shadow_juan_carlos} alt="juan_carlos" loading="lazy" />
        );

      case 3:
        return (
          <img src={shadow_langa} alt="langashadow_langa" loading="lazy" />
        );

      default:
        return <img src={loading_coaches} alt="cr7" loading="lazy" />;
    }
  };

  return (
    <div ref={ref} css={playerHeaderStyle} className="player-header">
      <GoBack path="/coaches" />

      <h1 className="title-name" id="coachProfile" style={{ width: "50%" }}>
        {player.data.name} <br /> {player.data.surname}
      </h1>
      <div className="column-container">
        <div className="col">
          <div className="player-title">
            <div className="current-team">
              <img
                src={BASE_URL_IMG + player.data.current_team_img}
                alt={player.data.current_team + " logo"}
                onError={(e) => {
                  e.target.src = defaultLogo;
                }}
              />
              <h3>{player.data.current_team_name ? player.data.current_team_name.toUpperCase() : " "}</h3>
            </div>
          </div>
          <div className="player-bio">
            <div className="born">
              <b>{dictionary.player_born}</b>
              {new Date(player.data.birthdate).toLocaleDateString(langLocale)}
            </div>
            <div className="age">
              <b>{dictionary.player_age}</b>
              {getAge(player.data.birthdate)}
            </div>
            <div className="from">
              <b>{dictionary.player_form}</b>
              {player.data.nacionalidad ?? "España"}
            </div>
            {player.data.transfermarkt_url && (
              <div className="transfer-markt">
                <b>Transfermarkt</b>
                <a
                  href={player.data.transfermarkt_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={transferMarktLogo}
                    alt="Transfermarkt Logo"
                    loading="lazy"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="background">
        <div className="img-container">
          <img
            ref={profileRef}
            src={BASE_URL_IMG + player.data.img_header_url}
            alt={""}
            loading="lazy"
            onLoad={() => setLoading(true)}
          />

          {loading ? (
            <img src={BASE_URL_IMG + player.data.img_header_url}  
            onError={(e) => {
              e.target.src = loading_coaches;}}
            alt="player" />
          ) : (
            shadowCoach(coach_num)
          )}
        </div>
        <div className="text-container">
          <h1 ref={textRef}>{player.data.nacionalidad ?? "España"}</h1>
        </div>
      </div>
    </div>
  );
}

export default CoachHeader;
