import { useContext, useEffect, useState } from "react";
import { Context } from "../../../Context";
import CoachBio from "./CoachBio";
import CoachGallery from "./CoachGallery";
import CoachNews from "./CoachNews";
import CoachVideoGallery from "./CoachVideoGallery";

export function useLangTabData(player) {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  let localTabs = [
    {
      name: dictionary.player_bio_tab,
      value: "Player Bio",
      component: ({ style, player, mobile }) => (
        <CoachBio style={style} player={player} mobile={mobile} />
      ),
    },
    {
      name: dictionary.menu_news,
      value: "News",
      component: ({ style, player }) => (
        <CoachNews style={style} player={player} />
      ),
    },
    {
      name: dictionary.coach_photos_title,
      value: "Photos",
      component: ({ style, player }) => (
        <CoachGallery style={style} player={player} />
      ),
    },
  ];
  if (player.videos.length != 0) {
    localTabs.push({
      name: dictionary.coach_videos_title,
      value: "Videos",
      component: ({ style, player }) => (
        <CoachVideoGallery style={style} player={player} />
      ),
    });
  }
  const [tabs, setTabs] = useState(localTabs);

  useEffect(() => {
    setTabs(localTabs);
  }, []);
  useEffect(() => {
    setTabs(localTabs);
  }, [dictionary]);

  return tabs;
}
