export function kgToLb(kg) {
  return Math.round(kg * 2.2046 * 10) / 10;
}

export function ftToIn(ft) {
  return ft * 12;
}

export function cmToFt(cm) {
  let feet = cm * 0.032808;
  let restInch = ftToIn(((feet * 1000) % 1000) * 0.001);
  return [Math.floor(feet), Math.floor(restInch)];
}
