import parse from "html-react-parser";
import aboutImg1 from "../../../assets/images/about-us_1.jpg";
import aboutImg2 from "../../../assets/images/about-us_2.jpg";
import aboutImg3 from "../../../assets/images/about-us_3.jpg";
import React from "react";

function getColumnData(dictionary) {
  const data = [
    {
      title: dictionary.about_us_origin_title,
      text: dictionary.about_us_origin_txt,
      img: aboutImg1,
    },
    {
      title: dictionary.about_us_present_title,
      text: dictionary.about_us_present_txt,
      img: aboutImg2,
    },
    {
      title: dictionary.about_us_future_title,
      text: dictionary.about_us_future_txt,
      img: aboutImg3,
    },
  ];
  return data;
}

export default getColumnData;
