/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import  { useContext } from "react";
import { RiWhatsappLine } from "react-icons/ri";
import { Context } from "./Context";

function WhatsappIcon() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  return (
    <div
      css={(t) => css`
        transition: ${t.transition};
        z-index: 4;
        position: fixed;
        height: 42px;
        width: 42px;
        bottom: 2rem;
        right: 2rem;
        background-color: #25d366;
        padding: 0.5rem;
        border-radius: 100px;
        a {
          display: block;
          position: relative;

          ::before {
            transition: ${t.transition};
            content: ${dictionary.txt_whats_app};
            padding: 1rem;
            color: white;
            background-color: ${t.accentColor2};
            border-radius: ${t.borderRadius};
            position: absolute;
            top: -2rem;
            right: 1rem;
            width: max-content;
            visibility: hidden;
            opacity: 0;
          }
          :hover {
            ::before {
              visibility: visible;
              opacity: 1;
            }
          }
        }

        :hover {
          height: 68px;
          width: 68px;
          bottom: 2rem;
          right: 2rem;
        }
        svg {
          fill: white;
          width: 100%;
          height: 100%;
        }
      `}
    >
      <a href="https://api.whatsapp.com/send?phone=34641880697" target="_blank" rel="noreferrer">
        <RiWhatsappLine />
      </a>
    </div>
  );
}

export default WhatsappIcon;
