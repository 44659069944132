import { css } from "@emotion/react";

export const main = (theme) => css`
  z-index: 1;
  width: 100%;
  margin: 0 auto;

  & > div {
    margin: auto;
    bacckground-color: red;
  }
`;
