import { css } from "@emotion/react";

export const legalContainer = (theme) => css`
  label: custom;
  text-align: justify; /* Justificar el texto */
  max-width: 65ch;
  max-width: ${theme.contentMaxWidth};
  min-width: 0;
  padding: ${theme.padding};
  /* background: red; */
  p {
    line-height: ${theme.lineHeight};
    &:not(:last-child) {
      margin-bottom: ${theme.paragBottomMargin};
    }
  }
  @media (max-width: 700px) {
    padding: ${theme.paddingM};
  }
  .legal-header {
    border: 2px solid ${theme.textColor};
    padding: ${theme.padding};
    border-radius: ${theme.borderRadius};
    margin-bottom: 5rem;

    @media (max-width: 700px) {
      padding: 0;
      border: none;
    }
  }

  .chapter-container {
    display: flex;
    flex-direction: column;
    .chapter-title {
      /* align-self: flex-end; */
      margin-bottom: 1rem;
      font-size: ${theme.h2};
      &::after {
        height: 0.5rem;
      }
    }
    .chapter-text {
      column-width: 45ch;
      margin-bottom: 3rem;
      p {
        break-inside: avoid;
      }
    }
  }
`;
