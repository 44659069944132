import { css } from "@emotion/react";
import { flexGap } from "../../Helpers/flexGap";
import backgroundPlayersImg from "../../../assets/images/background-body.jpg";
import backgroundCoaches from "../../../assets/images/background-coaches.jpg";
import playerBackground from "../../../assets/images/img-player-container.png";
import coachNameBackground from "../../../assets/images/img-home_coaches-container_name.png";
import coachBackground from "../../../assets/images/img-home_coaches-container_coach.png";
import adrianBackgroundImg from "../../../assets/images/background-home-adrian.jpg";
import rfecBackgroundImage from "../../../assets/images/background-body.jpg";
import homeContactImg from "../../../assets/images/img-home_contact-background.png";
import academy from "../../../assets/images/academy_home.jpg";

export const homeStyle = (theme) => css`
  width: 100%;
  position: relative;
  -webkit-tap-highlight-color: transparent !important;

  .initial {
    transition: ${theme.transition};
    transition-duration: 1s;
    opacity: 0;
  }

  & > div > div {
    width: 100%;
    position: relative;
    padding-top: 2rem;
    padding-left:inherit;
    height: 100%;
    padding-bottom: 6rem;

    .inner {
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      // @media (min-width: 1300px) {
      //   min-width:170%;
      // }
      @media (max-width: 700px) {
        padding: 0 ${theme.paddingM};
      }
      margin: auto;
      max-width: ${theme.contentMaxWidth};
      height: 100%;
    }
  }

  h2 {
    line-height: 100%;
    text-align: center;
    margin: 2rem auto;
  }
  h3 {
    width: fit-content;
    max-width: 65ch;
  }
  .button {
    margin-top: auto;
  }
  

  .img-container {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    width: 100%;
    height: 100%;
    
    
    
    padding: 0;
    img {
      object-fit: cover;
      width: 100%;
      height: 90%;
      filter: brightness(1.2);
      opacity: 0.3;
      transform: translateY(10px);
      @media (max-width: 720px) {
        height:75%;
        transform:translateY(70px);
      }
/*       @media (max-width: 650px) {
        height:65%;
        transform:translateY(30px);
      } */
    }
  }
  .coach-container {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    width: 100%;
    height: 100%;
    padding: 0;
    img {
      object-fit: cover;
      width: 100%;
      height: 90%;
      filter: brightness(1.2);
      opacity: 0.3;
      transform: translateY(10px);
      @media (max-width: 700px) {
        height:100%;
        transform:translateY(60px);
      }
      @media (max-width: 650px) {
        height:90%;
        
        transform:translateY(50px);
        width:100%;
        object-fit:cover;
      }
    }
  }

  .button {
    cursor: pointer;
    display: block;
    max-width: fit-content;
    text-decoration: none;
    border-radius: ${theme.borderRadius};
    padding: 1rem;
    background-color: ${theme.accentColor2};
    color: white;
    transition: ${theme.transition};
    :hover {
      background-color: #009aff;
    }
  }

  .your-agency {
    height: 600px;
    padding: 0 ${theme.padding};
    padding-top: 4rem;

    @media (max-width: 700px) {
      padding: 0 ${theme.paddingM};
      padding-top: 4rem;
    }
    // @media(max-width:700px){
    //   height:400px;
    // }
    @media(max-width:700px){
      height:380px;
    }
    h1 {
      color: white;
      width: fit-content;
      margin: auto;

      @media (max-width: 470px) {
        font-size: 16vw;
      }
    }
    h3 {
      color: #fff;
      width: fit-content;
      margin: auto;
      margin-top: 2rem;
    }
    .img-container {
      top: -300px;
      height: 1000px;
      z-index: -1;
      //mask-image: linear-gradient(0deg, transparent 0%, white 40%, white 100%);
      img {
        opacity: 1;
        filter: brightness(0.7);
      }
    }
  }

  .no-limits {
    background-color: url(${theme.dark ? null : "#F7FDFF"});

    img {
      position: absolute;
      right: 5rem;
      top: 0.2rem;
    }

    @keyframes loop {
      from {
        transform: translateX(0%);
      }
      to {
        transform: translateX(-50%);
      }
    }

    .services-container {
      opacity: 1;
      margin-top: 2rem;
      margin-bottom: -160px;
      overflow-x: hidden;
     //  width: 120rem;
      padding: 10px 0 120px 0;
      border-radius: ${theme.borderRadius};
      mask-size: 100% 120%;
      display: flex;
      justify-content: center;
      text-align: center;

      a {
        text-decoration: none;
        margin-right: 2rem;
      }
      .services {
          
        //overflow-y: visible;
        //animation-name: loop;
      //animation-fill-mode: forwards;
      //animation-duration: 20s;
      //animation-timing-function: linear;
      //animation-iteration-count: infinite;
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      /* animation-play-state: running;
      @media (max-width: 530px) {
        animation-duration: 55s;
      }
      :hover {
        //animation-play-state: paused;
      } */
      .column {
        color: white;
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 420px;
        padding: 0.7rem;
        
        border-radius: ${theme.borderRadius};
        //transition: ${theme.transition};
        :hover {
          transform: translateY(-10px);
        }

          .inner {
            background: ${theme.dark ? "rgba(5, 116, 187, 0.4)" : "#323237"};
            display: flex;
            flex-direction: column;

            p {
              font-size: 18px;
            }
          }
          .title {
            display: flex;
            position: relative;
            top: 25px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            svg {
              max-width: max-content;
            }
            h3 {
              text-align: center;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .acad-header {
    font-size: 1.35rem;
  }
  @media (max-width: 700px) {
    .no-limits {
      height:100%;
      transform:translateY(120px);
    }
    .services-container {
      margin: auto !important;
      width: 100%;
      min-width: 100vw;
    }
    .services{
    }
  }
 


  .our-services {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 21%;
    padding-top: 2rem;
    color: ${theme.dark ? "#F7FDFF" : "#313841"};
    margin-top: -2rem;
  }
  @media (min-width: 550px) {
    .academy-phone{
      display: none;
    }
    .services-container {
      width:100%;

    }
    .our-services{
      font-size: 2.1rem;
      margin-top: -2rem;
      padding-left: 31.5%;
      margin-left: auto;
    }
    .services{
      width: 100%;
      min-width: 100vw;
    }
  }

  //ACADEMY BANNER FOR LAPTOT

  .academy {
    position: relative;
    height: 650px;
    margin-top: -5px;
    padding-bottom: 30rem;
    object-fit: contain;
    overflow: hidden; 
  }
  .academy::before {
    content: "";
    background-image: url(${academy});
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(0);
    transition: filter 0.3s ease;
  }
  .academy:hover::before {
    filter: blur(8px);
    
  }
  .upper-acad-container{
    display: flex; 
    width: 100vw;
    position:absolute;
    top: 0;
    padding-top: 0;
    background: rgba(49, 56, 65, 0.8); 
  }
  

  .acad-title{
    display: flex;
    flex-direction:column;
    width: 100vw;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .valencia{
    display: flex;
    justify-content: center;

    width: 100vw;
  }
  .acad-logo{
    display: flex;
    justify-content: center;
    max-width: 100vw;
    margin: auto;
    position: relative;
    top: 6rem;
    

  }
  .lower-acad-container{
    display: flex; 
    width: 100vw;
    position:absolute;
    flex-direction: column;
    top: 506px;
    background: rgba(49, 56, 65, 0.9); 
    opacity: 0.9;
    justify-content: center;
    text-align: center;

  /*     border: 2px solid orange;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    text-align: end; */
  }
  
  .greeny{
    color: #79C942;
  }
  .but-acad{
    display: flex;
    justify-content: center;
    bottom: 1rem;
  }

@media (max-width: 700px) {
  .css-o0jic-Home .player-card .image-players img {
    transform: translateY(0px) !important;
  }
  .css-1uqhciu .player-card .image-players img {
    transform: translateY(0px) !important;
  }
  .css-j6bblv .player-card .image-players img{
    transform: translateY(0px) !important;
  }
  .css-i2bele-Home .player-card .image-players img {
    transform: translateY(0px) !important;
  }
}

@media (min-width: 550px ) and ( max-width : 700px ) {
  .acad-style2 {
    display: flex;
    flex-flow: column;
  }
}
@media( max-width: 375px) {
  .acad-style2{
    font-size: 1.6rem !important;
    padding-left: 5px;
    padding-right: 5px;
  }
  .acad-header {
    font-size: 1.3rem;
  }
}
@media(max-width: 350px ) {
  .acad-style2{
    display: flex;
    flex-direction: column;
    margin-bottom: -1.9rem;
  }
}

  .academy-phone {

    background-image: url(${academy});
    background-size: cover;
    height: 500px;
    margin-top: -5px;
    padding-bottom: 30rem;
    object-fit: contain;
    position:relative;
  }
  @media (max-width: 550px) {
    .academy{
      display: none;
    }

  }

  .scc18-container{
    width: 100vw;
    padding-left: 2%;
    position: absolute;
    top: 4.1rem;
  }
  .scc18-container img {
    height: 150px;
  }
  .acad-style1, .acad-style2 {
    color: white;
  }
  .acad-style1{
    font-size: 1.6rem;
  }
  .acad-style2{
    font-size: 3rem;
    font-weight: bold;
  }
  .join{
    width: 50%;
  }

  .acad-wrapper{
    position:relative;
    margin-top: -10rem;

    height: 500px;
    text-align: center;
    align-items: center;
  }
  .up, .middle, .down, .deepdown, .join{
    width: 100vw;
  }
  .up {
    grid-row:
  }
  .down{
    display: flex;
    flex-flow: column;
  }

  .down a{
    margin-top: 7rem;

  }
  .pro-clubs{
    color: #0976B8;
  }
  .join {

    cursor: pointer;
    display: block;
    max-width: fit-content;
    text-decoration: none;
    border-radius: ${theme.borderRadius};
    padding: 1rem;
    background-color: ${theme.accentColor2};
    color: white;
    transition: ${theme.transition};
    :hover {
      background-color: #009aff;
    }


    margin-top: 0.5rem;
    background-color: #79C942;
    padding: 0.4rem;
    padding-bottom: 0.6rem;
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
    padding-right: 1rem;
    padding-left: 1rem;
    justify-content: center;
    font-weight: bold;
  }
  @media(max-width: 550px) {
    .acad-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;

      align-items: start;
      margin-top: -2rem;
    }
    .acad-style1 {
      font-size: 1.5rem;
    }
    .acad-style2{
      font-size: 1.7rem;
      font-weight: bold;
    }
    .scc18-container img {
      height: 100px;
      margin-top: 2rem;
    }
    .acad-up{
      width: 100vw;
      background-color: #303841;
      opacity: 0.9;
    }
    .down{
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 2rem;
    }
    .deepdown{
      position: absolute;
      bottom: 0;
      padding-bottom: 0.5rem;
      white-space: pre-line;
      display: block;
      background-color:#303841;
      opacity: 0.9;
      }
  }

  @media( max-width : 550px ) {
    .player-header{
      font-size: 2.8rem;
    }
    .players {
      margin-top: -2rem;
      margin-bottom: -4rem;
    }
    .our-coaches {
      margin-bottom: -4rem;
    }
    .quote {
      margin-top: -6rem !important;
    }
  }
  .players {
    margin-top: -0.5rem;
  }
  .our-players {
    display: flex;
    justify-content: center;
    background-image: url(${theme.dark ? null : backgroundPlayersImg});
    background-size: cover;
    h1 {
      color: #01173e;
      text-transform: uppercase;
      margin-bottom: 
    }

    @media (max-width: 426px) {
      h1 {
        font-size: 50px;
      }
      
    }



    .inner {

      .players {
         
          // display: grid;
          // grid-template-columns: repeat(3, 1fr);
          // grid-gap: 100px;
          // grid-auto-rows: minmax(100px, auto);
          // float: left;
          // -webkit-transform-origin: 50% 50%;
          // -ms-transform-origin: 50% 50%;
          // transform-origin: 50% 50%;
          // -webkit-transition: all .65s ease, opacity .2s;
          // transition: all .65s ease, opacity .2s;
          // overflow: hidden;
        
    
    @media (max-width: 850px){
      overflow:hidden;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 50px;
    }
    @media (min-width: 1393px){
      overflow:hidden;
      grid-template-columns: repeat(4, 1fr);
    }
          
          margin-left: 1rem;
          align-items: center;
         //las lineas de arriba son las relevantes para el problema 
          margin: 2rem auto; 
          overflow-x: auto;
          overflow-y: auto;
          padding: 10px 5px 100px 5px;
          scroll-snap-type: x mandatory;
          ::before {
            content: "+";
            position: absolute;
            color: white;
            top: 10px;
            right: 10px;
            background-color: ${theme.accentColor2};
            padding: 0.5rem 1rem;
            display: block;
            opacity: 0;
            border-radius: 100px;
            transform-origin: right;
            transform: translate3d(0, -10px, 0);
            transition: all ease-in-out 0.3s;
            @media (max-width: 410px) {
              content: "+";
              padding: 0;
              width: 2rem;
              height: 2rem;
              padding: 0.36rem;
              font-size: ${theme.p};
              text-align: center;
              min-width: fit-content;
            }
          }
        
         ::-webkit-scrollbar {
           background-color: transparent;
           width: 10px;
          
         }
        ::-webkit-scrollbar-thumb {
          background-color: ${
            theme.dark
              ? theme.inputBackgroundColorActive
              : theme.inputBackgroundColor
          };
          border-radius: 6px;
          height: 4px;
          width: 5px;
        }
        @media (max-width: 710px){
          overflow:hidden;
        }
       

        .player-card {
        
          display: flex;
          position:absolute;
          z-index:auto;
          align-items: flex-end;
          padding: 1rem;
          flex: 1 0 auto;
          scroll-snap-align: center;
          width: 100%;
          max-width: 250px;
          height: 350px;
          position: relative;
          border-radius: ${theme.borderRadius};
          
        



          text-decoration: none;
          transition: ${theme.transition};

          :not(:last-of-type) {
            margin-right: 1rem;
          }
         
            
          

          :hover {
            transform: translateY(-10px);
            box-shadow: ${theme.defaultHoverShadow};
          }

          .text-container {
            z-index: 2;

            

            p {
              text-align: left;
              margin-bottom: 0;
              
              
            }

            .player-card-name {
              font-size: 16px;
              font-weight: bold;
              text-transform: uppercase;
            }

            .player-card-position {
              font-weight: bold;
              color: #0574bb;
              text-transform: uppercase;
            }
          }

          .img-container {
            background-image: url(${playerBackground});
            background-size: cover;
            z-index: 1;
            img {
              opacity: 1;
            }
            ::after {
              content: "";
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-image: linear-gradient(
                0deg,
                ${theme.dark ? "rgba(0,0,0,1)" : "rgba(255,255,255,1)"} 5%,
                ${theme.dark ? "rgba(0,0,0,1)" : "rgba(255,255,255,1)"} 4%,
                ${theme.dark ? "rgba(0,0,0,0)" : "rgba(255,255,255,0)"} 34%
              );
              opacity: 0.8;
              
            }
          }
        }
      }
    }
  }
  .jugadores-container{
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
  }
  .players-container {
    margin-left: 0.5rem;
    margin-top: 2rem;
    width: 90%;
    overflow: hidden;
    height: 100%;

  }

//playyerts

  .our-coaches2 {
    background-image: url(${theme.dark ? null : backgroundPlayersImg});
    background-size: cover;
    display: flex;
    justify-content: center;
    background-color: #303841;
    

    h1 {
      font-size:90px;
      color: #01173e;
      
    }

    
   
    .inner {
      align-items: center;
      text-align: center;
      justify-content: center;

      

      @media (max-width: 600px) {
        h1 {
          font-size: 50px;
        }
      }

      .players {
        max-width: 1280px;
        margin: auto;
        display: grid;
        width: 100%;
        margin-top:30px;
        place-items: center;
        // margin: 4rem auto;
        // overflow-x: auto;
        // overflow-y: auto;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        grid-auto-rows: minmax(100px, auto);
        justify-content: center;
        @media (max-width: 850px){
        overflow:hidden;
        width:100%;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 80px;
        grid-column-gap: 10px;
        place-items:flex-start;
        // margin-left:10px;
      }
        

        padding: 10px 5px 100px 5px;
        scroll-snap-type: x mandatory;
        ::-webkit-scrollbar {
          background-color: transparent;
          width: 12px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${
            theme.dark
              ? theme.inputBackgroundColorActive
              : theme.inputBackgroundColor
          };
          border-radius: 60px;
          height: 40px;
          width: 5px;
        }

        .player-card {
          display: flex;
          align-items: flex-end;
          padding: 1rem;
          flex: 1 0 auto;
          scroll-snap-align: center;
          width: 100%;
          max-width: 250px;
          height: 279px;
          position: relative;
          text-decoration: none;
          transition: ${theme.transition};
          @media (max-width:750px){
            height:300px;
          }

          :not(:last-of-type) {
            // margin-right: 4rem;
          }

          :hover {
            transform: translateY(-10px);
            box-shadow: ${theme.defaultHoverShadow};
          }

          .text-container {
            height: 52px;
            display: flex;
            flex-direction: column;
            background: url(${coachNameBackground});
            color: #fff;
            width: 100%;
            z-index: 2;
            position: absolute;
            bottom: -20px;
            @media(max-width:400px){
              bottom:0px;
            }
            left: 0;
            @media(max-width:400px){
              width:100%;
              // bottom:39px;
            }
            

            span {
              text-transform: uppercase;
              font-weight: bold;
              
              @media(max-width:560px){
                font-size:15px;
              }
            }
          }

          .img-container {
            background-image: url(${playerBackground});
            background-size: cover;
            z-index: 1;
            border-radius: ${theme.borderRadius};

            img {
              opacity: 1;
            }
            ::after {
              content: "";
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
            }
          }
        }
      }
    }
  }
  
  //COACHES


  .our-coaches {
    background-image: url(${theme.dark ? null : backgroundCoaches});
    background-size: cover;
    display: flex;
    justify-content: center;
    background-color: #303841;
    

    h1 {
      font-size:90px;
      color: #01173e;
      
    }

    
   
    .inner {
      align-items: center;
      text-align: center;
      justify-content: center;

      

      @media (max-width: 600px) {
        h1 {
          font-size: 50px;
        }
      }

      .players {
        max-width: 1280px;
        margin: auto;
        display: grid;
        width: 100%;
        margin-top:30px;
        place-items: center;
        // margin: 4rem auto;
        // overflow-x: auto;
        // overflow-y: auto;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        grid-auto-rows: minmax(100px, auto);
        justify-content: center;
        @media (max-width: 850px){
        overflow:hidden;
        width:100%;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 80px;
        grid-column-gap: 10px;
        place-items:flex-start;
        // margin-left:10px;
      }
        

        padding: 10px 5px 100px 5px;
        scroll-snap-type: x mandatory;
        ::-webkit-scrollbar {
          background-color: transparent;
          width: 12px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${
            theme.dark
              ? theme.inputBackgroundColorActive
              : theme.inputBackgroundColor
          };
          border-radius: 60px;
          height: 40px;
          width: 5px;
        }

        .player-card {
          display: flex;
          align-items: flex-end;
          padding: 1rem;
          flex: 1 0 auto;
          scroll-snap-align: center;
          width: 100%;
          max-width: 250px;
          height: 279px;
          position: relative;
          text-decoration: none;
          transition: ${theme.transition};
          @media (max-width:750px){
            height:300px;
          }

          :not(:last-of-type) {
            // margin-right: 4rem;
          }

          :hover {
            transform: translateY(-10px);
            box-shadow: ${theme.defaultHoverShadow};
          }

          .text-container {
            height: 52px;
            display: flex;
            flex-direction: column;
            background: url(${coachNameBackground});
            color: #fff;
            width: 100%;
            z-index: 2;
            position: absolute;
            bottom: -20px;
            @media(max-width:400px){
              bottom:0px;
            }
            left: 0;
            @media(max-width:400px){
              width:100%;
              // bottom:39px;
            }
            

            span {
              text-transform: uppercase;
              font-weight: bold;
              
              @media(max-width:560px){
                font-size:15px;
              }
            }
          }

          .img-container {
            background-image: url(${coachBackground});
            background-size: cover;
            z-index: 1;
            border-radius: ${theme.borderRadius};

            img {
              opacity: 1;
            }
            ::after {
              content: "";
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .css-833u0l-Home .our-coaches .inner .players .player-card .img-container::after {
    transform: translateX(50px);
  }

  .quote {
    padding-bottom: 0;
    height: initial;
    background-image: url(${theme.dark ? null : adrianBackgroundImg});

    background-size: cover;
    h2 {
      margin: 0 auto;
      margin-bottom: 3rem;
    }

    .inner {
      padding: 0 ${theme.padding};
      padding-top: ${theme.padding};
      border-radius: ${theme.borderRadius};
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      z-index: 1;
      position: relative;
      hr {
        display: none;
        margin: 0 0 2rem 0;
        border-color: ${theme.inputBackgroundColor};
      }
      @media (max-width: 865px) {
        flex-direction: column;
        hr {
          display: block;
        }
      }
      @media (max-width: 700px) {
        padding: 0 ${theme.paddingM};
        padding-top: 0;
        margin-top: -5rem;
        margin-bottom: -3rem;
      }

      .button {
        font-size: ${theme.p};
        //margin: 2rem 0 2rem auto;
        font-weight: bold;
        background-color: #79c942;

      }


      figure {
        display: flex;
        flex-direction: column;
        margin: 0;
        max-width: 55ch;
        min-height: 100%;
        flex: 1 1 auto;
        font-size: ${theme.h3};
        z-index: 2;

        @media (max-width: 420px) {
          font-size: ${theme.h4};
         
        }

  

        p {
          margin-bottom: 0;
          line-height: initial;
        }
        blockquote {
          position: relative;
          border: 3px solid #79c942;
          color: #fff;
          padding: 20px;
          z-index: -1;
          margin: auto;
          svg {
            z-index: -1;
            position: absolute;
            width: fit-content;
            fill: #79c942;
            height: 70%;
            padding: 5px;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        figcaption {
          
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          z-index: 4;
          margin-top: 1rem;
          text-align: right;
          font-size: ${theme.p};
          font-style: italic;
          color: #fff;

          .adrianName {
            font-weight: bold;
            font-size: 2em;
            bor
          }

          .adrianCargo {
            color: #fff;
            font-size: 1.6em;
          }
          
        }
      }
  
      .img-container {
        z-index: 3;
        flex: 2 1 auto;
        position: relative;
        left: initial;
        transform: initial;
        margin: auto;
        max-width: fit-content;
        min-width: fit-content;

        img {
          object-fit: contain;
          opacity: 1;
          margin-bottom: -10px;
          ${
            theme.dark
              ? "filter: brightness(0.7) contrast(1.4);"
              : "filter:brightness(0.7) contrast(2) "
          }
        }
      }
    }
  }

  .rfefWrapper {
    background-image: url(${theme.dark ? null : rfecBackgroundImage});
    background-color: #303841;
    background-size: cover;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .textWrapper {
      position: relative;
      bottom: 18rem;
      transform: translate(0%, 40%);

      span {
        color: #0574bb; // cambiar el color del texto aqui
        font-weight: bold;
        font-size: 144px;
        z-index: -100;
        opacity: 0.4;
        position: absolute;
        font-weight: bold;
      }

      .top {
        position: relative;
        bottom: -2rem;
        display: flex;
        justify-content: center;
        align-items: center;
       
      }
      .bottom {
        position: relative;
        bottom: 2.1rem;
        display: flex;
        justify-content: center;
        top: 3rem;
      }
    }

    @media (max-width: 1901px) {
      display: flex;
      justify-content: center;

      .textWrapper {
        span {
          font-size: 6em;
        }

      }


      .leftPlayer {
        display: none;
      }
    }

    @media (max-width: 895px) {
      .textWrapper {
        span { 
         font-size: 70px;
        }
      }

      .leftPlayer {
        display: none;
      }
    }

    @media (max-width: 450px) {
      .textWrapper {

        .inner {
          position: relative;
          top: 5rem;
        }
        .top {
          position: relative;
          top: 4rem;
          font-size: 50px;
          width: 100%;
          margin: 0 auto;
        }

        .bottom {
          position: relative;
          font-size: 50px;
          width: 100%;
          margin: 0 auto;

        }
      }
    }
  }

@media ( max-width: 500px ){
  .textWrapper {
    margin-bottom: -4rem;
  }

}

  .rfef {
    pointer-events: none;
    max-width: 920px;
    margin-bottom: 20rem;
    margin: 0rem auto;
    padding: 0 ${theme.padding};
    height: 1000%;
    overflow-y: visible;
    perspective: 849px;
    perspective-origin: 50% 50%;
    z-index: 3;
    @media (max-width: 700px) {
      padding: 0 ${theme.paddingM};
    }

    & > h2 {
      margin: auto;
      margin-bottom: 3rem;
    }
    a {
      text-decoration: none;
    }

    .inner {
      pointer-events: auto;
      height: 450px;
      padding: ${theme.padding};
      border-radius: ${theme.borderRadius};
      box-shadow: ${theme.defaultShadow};
      background-color: ${theme.backgroundColorHigh};
      margin-bottom: -200px;
      position: relative;
      overflow: hidden;
      transition: ${theme.transition};
      z-index: 2;
      transform: scale(1.4) rotateX(51deg) rotateY(6deg) rotateZ(-34deg)
        translateX(480px) translateY(-650px) translateZ(-450px) skewX(0deg)
        skewY(0deg);

      > div {
        z-index: 2;
      }
      ::after {
        transition: ${theme.transition};
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: blue;
        background-image: ${
          theme.dark
            ? "linear-gradient(45deg,rgba(0, 0, 0, 1) 0%,rgba(57, 61, 64, 1) 42%,rgba(0, 0, 0, 1) 44%,rgba(0, 0, 0, 1) 55%,rgba(57, 61, 64, 1) 69%)"
            : "linear-gradient(45deg,rgba(255, 255, 255, 1) 0%,rgba(222, 230, 236, 1) 42%,rgba(255, 255, 255, 1) 44%,rgba(255, 255, 255, 1) 55%,rgba(222, 230, 236, 1) 69%)"
        };
        background-repeat: repeat-x;
        background-size: 200%;
        background-position: 25%;
        z-index: 1;
      }

      :hover {
        transform: translateY(-10px);
        box-shadow: ${theme.defaultHoverShadow};
        ::after {
          background-size: 120%;
          filter: blur(45px);
          background-position: 100%;
        }

        :active {
          transform: translateY(-10px) scale(0.9);
        }
      }

      @media (max-width: 700px) {
        /* margin-top: -160px; */
        padding: ${theme.paddingM};
        height: 450px;
        h3 {
          font-size: ${theme.h4} !important;
        }

        h2 {
          font-size: ${theme.h3} !important;
        }

        svg {
          max-width: 60px !important;
        }
      }
      p {
        margin: 0;
      }
      .title {
        display: flex;
        align-items: center;

        svg {
          width: 100%;
          fill: ${theme.textColor};
          max-width: 100px;
          margin-right: 2rem;
        }
        h2 {
          flex: 1 1 auto;
          margin: auto;
        }
      }

      .info {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        flex-wrap: wrap;
        .text {
          margin-bottom: 2rem;
          margin-right: 2rem;
        }
        .dates {
          mix-blend-mode: ${theme.dark ? "hard-light" : "multiply"};
          display: flex;
          flex-direction: column;
          background-color: ${theme.inputBackgroundColor};
          max-width: 300px;
          padding: 1rem;
          border-radius: ${theme.borderRadius};
          height: fit-content;

          > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            p {
              :first-of-type {
                font-weight: bold;
                margin-right: 2rem;
                min-width: max-content;
              }
            }
          }
        }
      }
      .footer {
        width: calc(100% + ${theme.padding} * 2);
        margin-left: -${theme.padding};
        margin-bottom: -${theme.padding};
        mix-blend-mode: ${theme.dark ? "hard-light" : "multiply"};
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        padding: ${theme.paddingM} ${theme.padding};
        background-color: ${theme.inputBackgroundColor};
        p {
          font-size: ${theme.h3};
          margin-bottom: 0;
        }
        img {
          height: 100%;
          max-height: 3rem;
        }

        @media (max-width: 700px) {
          width: calc(100% + ${theme.paddingM} * 2);
          margin-left: -${theme.paddingM};
          margin-bottom: -${theme.paddingM};

          p {
            font-size: ${theme.h4} !important;
          }
          img {
            /* width: 100%; */
            height: 100%;
            max-height: 2rem;
          }
        }

        @media (max-width: 500px) {
          p {
            :nth-of-type(2) {
              display: none;
            }
          }
        }
      }
    }
  }

  .shout-out {
    height: 100%;
    min-height: 300px;
    overflow: hidden;
    background-image: url(${theme.dark ? null : homeContactImg});
    background-size: cover;
    //display: flex;
    //justify-content: center;

    a {
      text-decoration: none;
      display: flex;
      color: #fff;
    }

    :hover {
      .img-container {
        transition: ${theme.transition};
        transition-duration: 1s;
        filter: blur(0px);
        max-width: ${theme.contentMaxWidth};

        svg {
          transition: ${theme.transition};
          transition-duration: 0.5s;
          height: 100%;
        }
      }
    }
    .inner {
      z-index: 2;
      position: relative;
      //border: dotted green 10px;
      display: flex;
      justify-content: center;

      p {
        font-size: 18px;
      }

      h2 {
        line-height: 3.3rem;
      }
    }
    .img-container {
      transition: ${theme.transition};
      filter: blur(50px);
      opacity: 0.5;
      width: 50%;
      max-width: 100%;
      z-index: 1;
      svg {
        transition: ${theme.transition};
        fill: #fff;
        position: absolute;
        object-fit: cover;
        right: 0;
        height: 250%;
        top: 53%;
        max-width: 100%;
        transform: translateY(-50%);
      }
    }
  }
`;
