import { css } from "@emotion/react";

export const newsCardStyle = (theme) => css`
  text-decoration: none;

  .news-card {
    padding: 1rem 2rem;
    //background-color: #01173e;
    
    border-radius: ${theme.borderRadius};
    h4 {
      color: ${theme.secondaryTextColor};
    }
    :hover {
      cursor: pointer;
      h3 {
        text-decoration: none;
        //text-underline-offset: 0.2rem;
        //text-decoration-color: ${theme.accentColor2};
      }
    }
    h3 {
      font-weight: bold;
    }
  }
`;
