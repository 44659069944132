export function getLangCode(lang) {
  let langCode;

  switch (lang) {
    case "English":
      langCode = "gb";
      break;
    case "Italian":
      langCode = "it";
      break;
    case "Spanish":
      langCode = "es";
      break;
    case "French":
      langCode = "fr";
      break;
    case "Portuguese":
      langCode = "pt";
      break;
    case "Chinese":
      langCode = "cn";
      break;
    case "German":
      langCode = "de";
      break;
    case "Japanese":
      langCode = "jp";
      break;
    case "Russian":
      langCode = "ru";
      break;
    default:
      langCode = "gb";
      break;
  }
  return langCode;
}

export function getLangLocale(lang) {
  let langCode;

  switch (lang) {
    case "English":
      langCode = "en-GB";
      break;
    case "Italian":
      langCode = "it-IT";
      break;
    case "Spanish":
      langCode = "es-ES";
      break;
    case "French":
      langCode = "fr-FR";
      break;
    case "Portuguese":
      langCode = "pt-PT";
      break;
    case "Chinese":
      langCode = "zh-CN";
      break;
    case "German":
      langCode = "de-DE";
      break;
    default:
      langCode = "en-GB";
      break;
  }
  return langCode;
}
