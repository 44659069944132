import { animated, useSpring } from "@react-spring/web";
import React from "react";
import { Link } from "react-router-dom";
import { BASE_URL_IMG } from "../../constants";
import LazyLoad from "react-lazy-load";
import defaultImage from "../../../assets/coaches_shadow/shadow_jose_carlos.png"


function CoachCard({ data, style, hoverIn, hoverOut }) {
  let name = data.name.trim();
  name = name.replace(/\s/g, "");
  let surname = data.surname.trim();
  surname = surname.replace(/\s/g, "");
  console.log(data.img_profile_url);
  return (
    <Link to={`/coaches/${data.id}-${name}-${surname}#coachProfile`}>
      <animated.div
        onMouseEnter={hoverIn}
        onMouseLeave={hoverOut}
        style={style}
        className="player-card"
      >
        <div className="text-container">
          <div className="coachName">
            <span>{data.name}</span>
          </div>
          <div className="coachSurname">
            <span>{data.surname}</span>
          </div>
        </div>
        <div className="img-container">
          <LazyLoad>
            <img
            style={{   height: "300px"}}
              baseUrl={BASE_URL_IMG + data.img_profile_url}
              src={BASE_URL_IMG + data.img_profile_url}
              onError={(e) => {
                e.target.src = defaultImage;
              }}
              
            />
          </LazyLoad>
        </div>
      </animated.div>
    </Link>
  );
}

export default CoachCard;
