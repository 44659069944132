/** @jsxRuntime classic */
/** @jsx jsx */

import HoverLinks from "./HoverLinks";
import Logo from "./Logo";
import { jsx } from "@emotion/react";
import { navbarDynamic, navbarHover, navigationStyle } from "./navbarStyle";
import useNavLink from "../Helpers/useNavLinks";
import React, { useContext } from "react";
import { HeaderContext } from "./Header";

const NavBar = React.forwardRef(({}, ref) => {
  const names = useNavLink();
  const { height, compact, hover, home } = useContext(HeaderContext);

  return (
    <div
      className="nav-container"
      ref={ref}
      css={[
        navigationStyle,
        navbarDynamic(compact, height, home),
        navbarHover(hover, height),
      ]}
    >
      <nav>
        <Logo />
        <HoverLinks names={names} />
      </nav>
    </div>
  );
});

export default NavBar;
