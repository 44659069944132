export function getPosition(num, mid, description) {
  switch (num) {
    case "POR":
      return [mid, 250, description];
      break;
    case "LI":
      return [mid - 160, 170, description];
      break;
    case "DCI":
      return [mid - mid / 4, 170, description];
      break;
    case "DCD":
      return [mid + mid / 4, 170, description];
      break;
    case "LD":
      return [mid + 160, 170, description];
      break;
    case "MI":
      return [mid - 110, 110, description];
      break;
    case "MCD":
      return [mid, 140, description];
      break;
    case "MC":
      return [mid, 110, description];
      break;
    case "MCO":
      return [mid, 80, description];
      break;
    case "MD":
      return [mid + 110, 110, description];
      break;
    case "EI":
      return [mid - 90, 37.5, description];
      break;
    case "MP":
      return [mid, 50, description];
      break;
    case "DC":
      return [mid, 25, description];
      break;
    case "ED":
      return [mid + 90, 37.5, description];
      break;
    default:
      return [mid, 50, description];
      break;
  }
}

export function setAttributes(element, attributes) {
  Object.keys(attributes).map((key) => {
    element.setAttribute(key, attributes[key]);
  });
}

export function appendChildren(element, children) {
  children.map((child) => element.appendChild(child));
}

export function createCircle([x, y, description]) {
  let xurl = "http://www.w3.org/2000/svg";
  let group = document.createElementNS(xurl, "g");

  let circle = document.createElementNS(xurl, "circle");
  let glow = document.createElementNS(xurl, "circle");
  let innerGlow = document.createElementNS(xurl, "circle");
  let popup = createPopup([x, y, description]);

  setAttributes(innerGlow, {
    cx: x,
    cy: y,
    r: 32,
    class: "player-glow",
  });

  setAttributes(glow, {
    cx: x,
    cy: y,
    r: 46,
    class: "player-glow",
  });

  setAttributes(circle, {
    cx: x,
    cy: y,
    r: 8,
    title: description,
    class: "player-position",
  });

  setAttributes(group, {
    class: "position-container",
  });

  appendChildren(group, [glow, innerGlow, circle, popup]);

  return group;
}

export function createPopup([x, y, description]) {
  let xurl = "http://www.w3.org/2000/svg";
  let popup = document.createElementNS(xurl, "foreignObject");
  let textNode = document.createTextNode(description);
  let div = document.createElement("div");

  setAttributes(popup, {
    x: x,
    y: y,
    class: "foreign-object",
  });
  popup.appendChild(div);

  div.setAttribute("class", "text-container");
  div.appendChild(textNode);

  return popup;
}

export function createSecondaryCircle([x, y, description]) {
  let xurl = "http://www.w3.org/2000/svg";
  let group = document.createElementNS(xurl, "g");
  let circle = document.createElementNS(xurl, "circle");
  let popup = createPopup([x, y, description]);
  setAttributes(circle, {
    cx: x,
    cy: y,
    r: 6,
    title: description,
    class: "player-position secondary",
  });

  group.setAttribute("class", "position-container");

  appendChildren(group, [circle, popup]);
  return group;
}

export function createPlayerPositions(player, svg) {
  let mid =
    Number.parseFloat(svg.current.getAttribute("viewBox").split(" ")[2]) / 2;

  let primary = player.map_positions_translate.primary_code;
  let primaryDescription = player.map_positions_translate.primary_description;
  let circle = createCircle(getPosition(primary, mid, primaryDescription));
  svg.current.appendChild(circle);

  player.map_positions_translate.secondary.map((position) => {
    let circle = createSecondaryCircle(
      getPosition(position.code, mid, position.description)
    );
    svg.current.appendChild(circle);
  });
}

export function removePlayerPositions() {
  let positions = document.querySelectorAll(".position-container");
  [...positions].map((item) => {
    item.parentNode.removeChild(item);
  });
}
