import { css } from "@emotion/react";

export const topbarStyle = (theme) => css`
  label: topbar;
  background-color: transparent;
  z-index: 2;
  transition: ${theme.transition};

  .inner-top-bar {
    //min-width: 100%;
    max-width: ${theme.contentMaxWidth};
    margin: 0 auto;
    padding: 1rem ${theme.padding};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;

    .theme-toggle-container {
      margin-right: auto !important;
    }
    @media (max-width: 700px) {
      padding: 0 ${theme.paddingM};
      //flex-wrap: wrap; // esto lo que hace esque tengamos todo en una linea cuando estamos el movil
    }
  }
`;
export const topbarDynamic = (compact, height, home) => (theme) =>
  css`
    width: 100%;
    background-color: ${compact
      ? theme.backgroundColor
      : home
      ? "transparent"
      : theme.backgroundColor};
    top: ${compact ? -height : 0}px;
    position: fixed;
    .inner-top-bar {
      ${home &&
      css`
        .selected-option {
          color: ${compact ? theme.textColor : "white"};
        }
      `}
      a {
        svg {
          .svg-fill {
            ${home &&
            css`
              fill: ${compact
                ? theme.socialLogoColor
                : theme.socialLogoColorInverse};
              ${theme.dark ? "fill: white;" : ""}
            `}
            &--inverse {
              ${home &&
              css`
                fill: ${compact
                  ? theme.socialLogoColorInverse
                  : theme.socialLogoColor};
                ${theme.dark ? "fill: black;" : ""}
              `}
            }
          }
        }
      }
    }
  `;
export const languageSelect = (theme) => css`
  position: relative;
  user-select: none;
  outline: none;
  cursor: pointer;

  .selected-option {
    display: flex;
    flex-wrap: none;
    align-items: center;

    img {
      margin-right: 0.5rem;
    }
    svg {
      margin-top: 2px;
    }
  }

  .options-menu {
    backface-visibility: hidden;
    perspective: 1000;
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: -1rem;
    right: -1rem;
    margin-top: 0.5rem;
    min-width: max-content;
    background-color: ${theme.inputBackgroundColor};
    border-radius: ${theme.borderRadius};
    overflow: hidden;
    box-shadow: 0 0 10px -5px #000000;
    .option {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      transition: all ease-in-out 0.2s;
      :focus-visible {
        outline: none;
        border-radius: 0;
        background-color: ${theme.accentColor};
      }

      :hover {
        background-color: ${theme.inputHoverColor};
      }
      img {
        margin-right: 0.5rem;
      }
    }
  }
`;
export const socialBar = css`
  margin-left: 0.5rem;
  display: flex;
  z-index: 1;
  a {
    :not(:last-of-type) {
      margin-right: 0.5rem;
    }
    svg {
      vertical-align: bottom;
    }
  }
`;

export const socialLogo = (theme) => css`
  .svg-fill {
    fill: ${theme.socialLogoColor};
  }
  .svg-fill--inverse {
    fill: ${theme.socialLogoColorInverse};
  }
`;
