/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import achievementsStyle from "./achivementsStyle";
import {
  RiTrophyFill,
  RiFundsBoxFill,
  RiSwapFill,
  RiCloseCircleFill,
} from "react-icons/ri/";
import { useContext, useEffect, useState } from "react";
import { useMeasure } from "react-use";
import { animated, useSpring } from "react-spring";
import { Context } from "../../../Context";

function getAchievementType(id, dictionary) {
  switch (id) {
    case 1:
      return {
        className: "campeonatos",
        icon: <RiTrophyFill></RiTrophyFill>,
        name: dictionary.coach_championships,
      };

    case 2:
      return {
        className: "ascensos",
        icon: <RiFundsBoxFill />,
        name: dictionary.coach_promotion,
      };

    case 3:
      return {
        className: "playoffs",
        icon: <RiSwapFill />,
        name: dictionary.coach_playoffs,
      };

    default:
      break;
  }
}

function Achievements({ data }) {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  const [layover, setLayover] = useState(null);
  const [originalHeight, setOriginalHeight] = useState(0);
  const [achievementsHeight, setAchievementsHeight] = useState(0);
  const [ref, { height }] = useMeasure();
  const [refContainer, { height: containerHeight }] = useMeasure();
  const style = useSpring({
    height: `${(!layover ? originalHeight : height) + 64}px`,
  });
  const styleInner = useSpring({ opacity: layover ? 1 : 0 });
  const styleInner2 = useSpring({ opacity: !layover ? 1 : 0 });
  useEffect(() => {
    if (layover && height > 0) {
      setAchievementsHeight(height);
    }
  }, [height]);

  useEffect(() => {
    if (containerHeight > 0) {
      setOriginalHeight(containerHeight);
    }
  }, [containerHeight]);
  return (
    <animated.div
      style={style}
      className="achievements"
      css={achievementsStyle}
    >
      <animated.div
        style={styleInner2}
        className="inner-achievements"
        ref={refContainer}
      >
        {data.achievements.map((type) => {
          const { className, icon, name } = getAchievementType(
            type.merit_id,
            dictionary
          );
          return (
            <div
              className={"category " + className}
              key={className}
              onClick={() => {
                setLayover({ name: className, data: type.items });
              }}
              css={css`
                flex: 1 0 calc(100% / ${data.achievements.length});
                width: calc(100% / ${data.achievements.length});
                max-width: 100%;
                min-width: max-content;
              `}
            >
              <div className="title-row">
                {icon}
                <h2>{type.items.length}</h2>
              </div>
              <h3>{name}</h3>
            </div>
          );
        })}
      </animated.div>
      {layover && (
        <animated.div style={styleInner} className="layover" ref={ref}>
          <RiCloseCircleFill
            onClick={() => {
              setAchievementsHeight(originalHeight);
              setLayover(null);
            }}
          />
          <ul>
            {layover.data.map((item, i) => {
              return (
                <li key={item.detail + i}>
                  <ul>
                    <li>
                      <b>{item.detail}</b>
                    </li>
                    <li>{item.season}</li>
                  </ul>
                </li>
              );
            })}
          </ul>
        </animated.div>
      )}
    </animated.div>
  );
}

export default Achievements;
