/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import React from "react";

function Chapter({ title, children, config }) {
  return (
    <div className="chapter-container">
      <h2 className="chapter-title">{title}</h2>
      <div className="chapter-text" css={config}>
        {children}
      </div>
    </div>
  );
}

export default Chapter;
