/** @jsxRuntime classic */
/** @jsx jsx */

import { ReactComponent as ReactLogo } from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import { jsx, css } from "@emotion/react";
import { logoStyle, logoContainer } from "./logoStyle";

function Logo() {
  return (
    <Link
      to="/"
      css={logoContainer}
      className={"logo"}
      aria-label="Elite Soccer 18 Logo"
    >
      <ReactLogo css={logoStyle} />
    </Link>
  );
}

export default Logo;
