import React, { useEffect, useRef } from "react";
import { useMeasure } from "react-use";

function useFitText(player) {
  const textRef = useRef();
  const [containerRef, { width }] = useMeasure();

  useEffect(() => {
    if (textRef.current) {
      let fontSize = 1;
      let sum = 0;
      for (let i = 1; i <= 5; i++) {
        fontSize *= i;
        textRef.current.style.fontSize = fontSize + "px";
        sum += fontSize / textRef.current.clientWidth;
      }
      textRef.current.style.fontSize = width * (sum / 5) + "px";
    }
  }, [width, textRef.current, player.data.nacionalidad]);
  return [textRef, containerRef, width];
}

export default useFitText;
