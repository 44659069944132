import { useTheme } from "@emotion/react";
import { config, useTransition } from "@react-spring/core";
import { useMemo } from "react";
import { useMeasure } from "react-use";
import useMediaQuery from "../Hooks/useMediaQuery";
function useMasonrySpring(items, height, gap, hoverStates = false, transRef) {
  // Hook1: Tie media queries to the number of columns
  const theme = useTheme();
  const columns = useMediaQuery(
    [
      "(min-width: 1500px)",
      "(min-width: 1250px)",
      "(min-width: 950px)",
      "(min-width: 200px)",
    ],
    [4, 4, 3, 2],
    // [4, 3, 3, 4],
    // [5, 4, 3, 2],
    3
  );
  // Hook2: Measure the width of the container element
  const [ref, { width }] = useMeasure();
  // Hook3: Hold items
  // Hook5: Form a grid of stacked items using width & columns we got from hooks 1 & 2
  const [heights, gridItems, containerHeight] = useMemo(() => {
    gap = width * 0.04;
    let heights = new Array(columns).fill(0); // Each column gets a height starting with zero
    let row = 0;
    let localWidth = width - gap * (columns - 1);
    height = ((localWidth / columns) * 4) / 3;
    // height = ((localWidth / columns) * 4) / 3;
    // height = columns == 1 ? ((localWidth / columns) * 1) / 3 : height;
    let gridItems = items.map((child, i) => {
      const column = heights.indexOf(Math.min(...heights)); // Basic masonry-grid placing, puts tile into the smallest column using Math.min
      const xy = [
        (localWidth / columns + gap) * column,
        (heights[column] += height) - height, // "-height" is for first row Y compensation
        // (heights[column] += height / 2) - height / 2,
      ]; // X = container localWidth / number of columns * column index, Y = it's just the height of the current column
      xy[0] === 0 && row++;
      xy[1] += xy[1] > 0 ? gap * row - gap : 0;
      return typeof child === "object"
        ? { ...child, xy, width: localWidth / columns, height: height }
        : { child, id: child, xy, width: localWidth / columns, height: height };
    });
    let containerHeight = gap * row - gap;
    return [heights, gridItems, containerHeight];
  }, [columns, items, width]);
  // Hook6: Turn the static grid values into animated transitions, any addition, removal or change will be animated

  const transitions = useTransition(gridItems, {
    ref: transRef,
    key: (item) => item.id,
    initial: {
      xy: [0, 0],
      width: 0,
      height: 0,
    },
    from: ({ xy, width, height }) => ({ xy, width, height, opacity: 0 }),
    enter: ({ xy, width, height }) => ({ xy, width, height, opacity: 1 }),
    update: ({ xy, width, height, id }) => {
      return {
        xy: hoverStates[id] ? (xy = [xy[0], xy[1] - 10]) : xy,
        boxShadow: hoverStates[id]
          ? `0px 1.5rem 50px -1px rgba(0, 0, 0, ${theme.dark ? "0.3" : "0.1"})`
          : `0px 0px 4px 0px rgba(0, 0, 0, ${theme.dark ? "0.5" : "0.25"})`,
        width,
        height,
      };
    },
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 10,
  });
  return [ref, heights, transitions, containerHeight];
}

export default useMasonrySpring;
