/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { customCheckboxStyle } from "./customCheckBoxStyle";

function CustomCheckBox({ children, formValues, setFormValues }) {
  return (
    <label css={customCheckboxStyle}>
      {children}
      <input
        type="checkbox"
        required
        value={formValues.legal}
        onChange={() => {
          setFormValues((oldValues) => ({
            ...oldValues,
            legal: !formValues.legal,
          }));
        }}
      />
      <span className="checkmark"></span>
    </label>
  );
}

export default CustomCheckBox;
