/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, ClassNames, useTheme } from "@emotion/react";
import "react-phone-input-2/lib/style.css";
import React, {
  Children,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from "react";
import PhoneInput from "react-phone-input-2";
import { telInputStyle } from "./style";

function CustomPhoneInput({ name, label, withLabel = true, customClass }) {
  const [focus, setFocus] = useState("");
  const ref = useRef(null);

  const theme = useTheme();

  useEffect(() => {
    ref.current.numberInputRef.setAttribute("name", "phoneNumber");
  });

  return (
    <>
      {withLabel && (
        <label
          htmlFor={name}
          onClick={() => ref.current.numberInputRef.focus()}
        >
          {label}
        </label>
      )}
      <ClassNames>
        {({ css, cx }) => {
          return (
            <PhoneInput
              ref={ref}
              specialLabel={false}
              country={"es"}
              containerClass={
                css(telInputStyle(theme)) + focus + " " + customClass
              }
              onFocus={() => setFocus(" focus")}
              onBlur={() => setFocus("")}
              required
            ></PhoneInput>
          );
        }}
      </ClassNames>
    </>
  );
}

export default CustomPhoneInput;
