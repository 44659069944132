/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import { useContext } from "react";
import { Context } from "../../../Context";
import { playerCareerStyle } from "./style";

function CoachCareer({ player }) {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  return (
    <div css={playerCareerStyle}>
      <div className="career">
        <h3>
          <b>{dictionary.player_career}</b>
        </h3>
        <ul>
          {player.career.map((item) => {
            return (
              <li key={item.club + "-" + item.season}>
                <ul>
                  <li>{item.division}</li>
                  <li>
                    <b>{item.club}</b>
                  </li>
                </ul>

                <p>{item.season}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="career education">
        <h3>
          <b>{dictionary.coach_education}</b>
        </h3>
        <ul>
          {player.education.map((item) => (
            <li key={item.title + "-" + item.period}>
              <ul>
                <li>{item.detail}</li>
                <li>
                  <b>{item.title}</b>
                </li>
              </ul>
              <p>{item.period}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default CoachCareer;
