import { css } from "@emotion/react";
import backgroundBody from "../../../assets/images/background-body.jpg";

export const aboutUsContainer2 = (theme) => css`
  width: 100%;
  height: 100%;
  background-image: url(${!theme.dark ? backgroundBody : null});
  background-size: cover;
  position: relative;
  top: 1rem;
 

  .aboutUsHeaderImg {
    margin-top: 3rem;
    background: rgb(121,201,66);
    background: linear-gradient(315deg, rgba(121,201,66,0.768411759234944) 60%, rgba(5,116,187,1) 100%);

    .aboutUsHeaderText {
      margin: auto;
      display: flex;
      flex-direction: column;
      max-width: ${theme.contentMaxWidth};

      span {
        margin-top: 20px;
        margin-bottom: 20px;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        font-size: 50px;
        text-transform: uppercase;
        color: #fff;
        
      }

      @media (max-width: 1024px) {
        span {
          display: flex;
          display: none;
          justify-content: center;
          //border: solid green 5px;
          font-size: 100px;
        }
      }

      @media (max-width: 500px) {
        span {
          font-size: 50px;
         
        }
      }
    }
  }

  .aboutUsOriginSection {
    //border: dotted blue 9px;
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .originsTitle {
      color: #0574bb;
      font-size: 80px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      //border: solid red 7px;
      p {
        color: #828282;
        font-size: 50px;
        text-transform: uppercase;
      }
    }

    .originText {
      //border: solid blue 10px;
      max-width: 40%;
    }

    .originsText {
      //border: solid green 4px;
      margin: 2rem;
      font-size: 18px;
    }

    @media (max-width: 1024px) {
      .originBallImg {
        position: absolute;
        //border: solid green 4px;
        top: 32rem;
       
      }
    }

    @media (max-width: 426px) {

      .originBallImg {
        position: absolute;
        //border: solid green 4px;
        top: 20.5rem;
        opacity: ${theme.dark ? 0.4 : 0.1};
       
      }
      .originText {
        //border: solid brown 9px;
        min-width: 375px;
      }
      .originsTitle {
        display: flex:
        justify-content: center;
        align-items: center;
        margin: 2rem;
       // border: purple solid 9px;
        font-size: 40px;
        p {
          font-size: 20px;
        }
      }
    }

   

    @media(max-width: 330px) {
      .originBallImg > img {
        width: 100%;
      }

    }
  }

  .playersImgs {
    min-width: 80%;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    //padding: 7rem;
  }


    @media(max-width:1087px) {
      
      .playersImgs {
        flex-direction: column;  
        display: flex;
      }

      .hazard {  
        margin: 0 45%;
        position: relative;
        top: 20px;  
     
      }
      .secondPicture {
        position: relative;
        top: -20px;
        margin: 0 20%;
       
      }
  
    }

    @media(max-width: 850px) {
      
      .hazard {
        margin-bottom: 80px; 
        display: flex;
        justify-content: center;
        left: 0px ;
        justify-content: center;
        
      }
      .secondPicture {      
       display: flex;
       justify-content: center;

      }
    }

    @media (max-width: 528px) {
      .playersImgs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
  
      }    
      
    }
   

  .aboutUsPresent {
    display: flex;
    justify-content: center;
    // border: solid blue 5px;
    margin-top: 3rem;


    @media (max-width: 1024px) {
        .aboutUsPresentPlayer > img {
            position: absolute;
            opacity: 0.4;
          }
    }

    @media (max-width: 768px) {
      .aboutUsPresentPlayer {
        position: absolute;
        opacity: 0.5;
      }
  
      .aboutUsPresentText {
        font-size: 20px;
        font-weight: bold;
       
      }

       .aboutUsPresentPlayer > img {
           max-width: 100%;

           position: relative;
           top: 15rem;
         }
  
      .aboutUsPresentText {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    
  @media (max-width: 500px) {
    .aboutUsPresentText {
      h2 {
        font-size: 40px;

      }
      p {
        font-size: 20px;
      }
     
    }
  
  }
   
    .aboutUsPresentText {
      min-width: 375px;      
    }
  }

  .aboutUsPresentText {
    //border: dotted green 6px;
    max-width: 30%;
    margin: 100px;
    padding: 2rem;
    font-size: 18px;
    

    h2 {
      text-transform: uppercase;
      color:#0574bb;
      font-size: 80px;
    }
    .agencyPresent{
      color: #828282;
      text-transform: uppercase;
      font-size: 50px;
      font-weight: bold;
    }
  }

  .aboutUsFutureText {
    //border: solid blue 5px;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 50%;
    margin: 0 auto;
    h2 {
      text-transform: uppercase;
      color: #0574bb;
      font-size: 80px;
    }

    .innerText {
      display: flex;
      align-items: center;
      flex-direction: column;
     
      padding: 2rem;
      font-size: 18px;
    }

    .practicing {
      //border: solid green 2px;
      color: #828282;
      font-weight: bold;
      padding: 1rem;
      text-transform: uppercase;
      font-size: 23px;
    }

    @media (max-width: 500px) {
      min-width: 375px;
      h2 {
        font-size: 40px;
      }

      .practicing {
        width: 84%;
        font-size: 18px;
      }
         
    }
  }

  .aboutUsFutureImg {
    //border: solid green 5px;
    display: flex;
    justify-content: center;
    margin-top: -25px;
    
  }

  .aboutUsFutureImg img { 
    margin: ${theme.dark ? "-9px" : "-24px"}
  }
`;
