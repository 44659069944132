import { css } from "@emotion/react";

export const buttonStyle = (theme) => css`
  label: button;
  display: flex;
  justify-content: center;
  padding: ${theme.buttonPadding};
  border-radius: ${theme.borderRadius};
  transition: ${theme.transition};
  cursor: pointer;
  a {
    text-decoration: none;
  }
`;

export function createButton(
  theme,
  color,
  weight,
  hover,
  margin = false,
  border = true
) {
  return (theme) => css`
    color: ${color};
    font-weight: ${weight};
    border: 2px solid ${color};
    ${margin
      ? `&:not(:last-child){
      margin-right: 1rem;
    }`
      : null}
    ${!border ? "border: none;" : null}
          ${hover
      ? `&:hover {
              color: ${theme.textColor};
              background-color: ${color};
              transition: ${theme.transition};`
      : null}
  `;
}

export const buttonTypes = {
  login: (theme) =>
    createButton(
      theme,
      theme.accentColor,
      "bold",
      true,
      true,
      theme.transition
    ),
  register: (theme) =>
    createButton(
      theme,
      theme.accentColor2,
      "bold",
      true,
      true,
      theme.transition
    ),
  accept: (theme) =>
    createButton(theme, theme.accentColor, "bold", true, theme.transition),
  seeMore: (theme) =>
    createButton(theme, theme.textColor, "bold", false, "none", true, false),
  decline: (theme) =>
    createButton(
      theme,
      theme.accentColor2,
      "bold",
      true,
      theme.transition,
      true
    ),
};
