import React from "react";
import { useContext } from "react";
import { Context } from "../../../Context";
import useMasonrySpring from "../../../Hooks/useMasonrySpring";
import PlayerCard from "../PlayerCard";

const Midfielders = ({
  players,
  hoverStates,
  data,
  setHoverStates,
  loading,
}) => {
  const transformed = false;

  const {
    dictionary: { dictionary },
  } = useContext(Context);

  // Usamos la función que maneja el spring para el masonry
  const [ref, heights, transition, containerHeight] = useMasonrySpring(
    // players.filter((item) => item.position === "goalkeepers").length > 0 &&
    //   players.goalkeepers,
    players,
    // players.filter((item) => item.position === "forward"),
    400,
    25,
    hoverStates
  );
  return (
    <div
      className="players-container less-width"
      ref={ref}
      style={{
        height: Math.max(...heights) + containerHeight,
      }}
    >
      <h1 className="title-group">{dictionary.players_mcs}</h1>

      {data &&
        transition(({ xy, ...rest }, player) => {
          return (
            <PlayerCard
              hoverIn={() => {
                setHoverStates((prev) => ({
                  ...prev,
                  [player.id]: true,
                }));
              }}
              hoverOut={() => {
                setHoverStates((prev) => ({
                  ...prev,
                  [player.id]: false,
                }));
              }}
              style={{
                transform: xy.interpolate(
                  (x, y) => `translate3d(${x}px,${y}px,0)`
                ),
                ...rest,
              }}
              key={player.id}
              data={player}
              transformed={transformed}
              loading={loading}
            ></PlayerCard>
          );
        })}
    </div>
  );
};

export default Midfielders;
