/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, useTheme } from "@emotion/react";
import { animated } from "@react-spring/web";
import { playerBioStyle, radarStyle } from "./style";
import "react-svg-radar-chart/build/css/index.css";
import React, { useContext } from "react";
import CoachCareer from "./CoachCareer";
import { Context } from "../../../Context";
import Achievements from "../../Players/Player page/Achievements";

const CoachBio = ({ style, player, mobile }) => {
  const theme = useTheme();
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  return (
    <animated.div style={style} css={playerBioStyle}>
      <h2>{dictionary.player_bio_tab}</h2>
      <Achievements data={player} />
      <CoachCareer player={player} />
    </animated.div>
  );
};

export default CoachBio;
