import { css } from "@emotion/react";

export function flexGap(gap) {
  return css`
    display: inline-flex;
    flex-wrap: wrap;
    margin: -${gap} 0 0 -${gap};
    width: calc(100% + ${gap});
    & > * {
      margin: ${gap} 0 0 ${gap};
    }
  `;
}
