import { css } from "@emotion/react";

export const customCheckboxStyle = (theme) => css`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${theme.inputBackgroundColor};
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ .checkmark {
    background-color: ${theme.inputHoverColor};
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: ${theme.accentColor};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 9px;
    top: 4px;
    width: 7px;
    height: 14px;
    border: solid ${theme.textColor};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  @media (max-width: 700px) {
    margin-bottom: 2rem;
  }
`;
