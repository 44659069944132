/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import { Link } from "react-router-dom";
import { style } from "./style";

import React, { useContext } from "react";
import { Context } from "../../Context";
import useServicesData from "./useServicesData";
import ServiceCard from "./ServiceCard";
import championIcon from "../../../assets/images/icon-services-championship.png";
import servicesPlayer from "../../../assets/images/img-services-playerhead.png";
import servicesPlayerKick from "../../../assets/images/img-services-playerhighkick.png";
function Services() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  const data = useServicesData();
  const changeStyles = true;
  let array1 = data.slice(0, 2);
  let array2 = data.slice(2, 5);
  let array3 = data.slice(5, 9);
  //scrol to top when loading the page
  window.scrollTo(0, 0);

  return (
    <>
      <div className="sercivesBackgroundBody" css={style}>
        <div className="servicesBanner">
          <div className="servicesHeaderTitle">
            <span className="span">{dictionary.services_title}</span>
          </div>
        </div>
        <h2 className="servicesTitle">{dictionary.services_title}</h2>

        <div className="wrapperPlayer">
          <div className="servicesPlayerImg">
            <img src={servicesPlayer} loading="lazy" alt="playerHead" />
          </div>
        </div>

        <div className="categories">
          <div className="championIcon">
            <img src={championIcon} loading="lazy" alt="champion" />
          </div>

          <div className="servicesColumnFirstRow">
            <div className="innerContainer">
              {array1.map((item) => (
                <ServiceCard
                  key={item.title}
                  title={item.title}
                  Icon={item.icon}
                  text={item.text_light}
                  size={item.size}
                  changeStyles={changeStyles}
                />
              ))}
            </div>
          </div>

          <div className="servicesColumnSecondRow">
            <div className="innerContainer">
              {array2.map((item) => (
                <ServiceCard
                  key={item.title}
                  title={item.title}
                  Icon={item.icon}
                  text={item.text_light}
                  size={item.size}
                  changeStyles={changeStyles}
                />
              ))}
            </div>
          </div>

          <div className="servicesColumnThirdRow">
            <div className="innerContainer">
              {array3.map((item) => (
                <ServiceCard
                  key={item.title}
                  title={item.title}
                  Icon={item.icon}
                  text={item.text_light}
                  size={item.size}
                  changeStyles={changeStyles}
                />
              ))}
            </div>
          </div>

          <div className="wrapperImgKick">
            <div className="kickPlayerImg">
              <img src={servicesPlayerKick} loading="lazy" alt="player" />
            </div>
          </div>
        </div>

        <div className="contact-us">
          <span>{dictionary.services_contact_title}</span>
          <Link to="/contact-us">
            <div className="contact-us-button">
              {dictionary.services_contact_btn}
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Services;
