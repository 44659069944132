/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useContext } from "react";
import { jsx } from "@emotion/react";
import { footer } from "./footerStyle";
import { Link } from "react-router-dom";
import { Context } from "./Context";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
  AiFillLinkedin,
  AiOutlineCopyright,
  AiFillYoutube,
} from "react-icons/ai";

import iconCall from "../../src/assets/images/icon-call_us.png";
import Marker from "../assets/maps/marker.svg";
import iconAvailability from "../../src/assets/images/icon-availability.png";
import { VERSION } from "./version";

function Footer() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  return (
    <>
      <footer css={footer}>
        <div className="content">
          <div className="top">
            <div className="logo-details">
              <h2>{dictionary.home_title}</h2>
              <p>ELITE SPORTS SOCCER 18</p>
            </div>

            <div className="media-icons">
              <Link
                to={{ pathname: "https://www.facebook.com/EliteSoccer18" }}
                target="_blank"
                rel="noreferrer"
              >
                <AiFillFacebook
                  className="social-media-icons"
                  onMouseOver={({ target }) => (target.style.color = "#79c942")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>
              <Link
                to={{
                  pathname: "https://www.instagram.com/elitesoccer18/",
                }}
                target="_blank"
                rel="noreferrer"
              >
                <AiFillInstagram
                  className="social-media-icons"
                  onMouseOver={({ target }) => (target.style.color = "#79c942")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>
              <Link
                to={{ pathname: "https://twitter.com/Soccer18Elite" }}
                target="_blank"
                rel="noreferrer"
              >
                <AiFillTwitterSquare
                  className="social-media-icons"
                  onMouseOver={({ target }) => (target.style.color = "#79c942")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>

              <Link
                to={{
                  pathname:
                    "https://www.linkedin.com/company/elite-sports-17-sl/?originalSubdomain=es",
                }}
                target="_blank"
                rel="noreferrer"
              >
                <AiFillLinkedin
                  className="social-media-icons"
                  onMouseOver={({ target }) => (target.style.color = "#79c942")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>

              <Link
                to={{
                  pathname:
                    "https://www.youtube.com/channel/UCKHUrEYYNvUq5Aqy9kOGA1g",
                }}
                target="_blank"
                rel="noreferrer"
              >
                <AiFillYoutube
                  className="social-media-icons"
                  onMouseOver={({ target }) => (target.style.color = "#79c942")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              </Link>
            </div>
          </div>

          <div className="link-boxes">
            <ul className="box one">
              <h3 className="link_name">ELITE SPORTS 18 S.L.</h3>
              <li>
                <li>
                  <img
                    src={Marker}
                    style={{ marginRight: "3px" }}
                    alt="google maps marker"
                  />
                  C/ Daniel Balaciart, 2, Of.12,{" "}
                  <span> 46020, Valencia, España</span>
                  <li
                    className="link_office"
                    style={{
                      marginBottom: "-2px",
                      marginRight: "12px",
                      color: "white",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[{" "}
                    {dictionary.footer_office} ]
                  </li>
                  <li>
                    <br />
                  </li>
                  <img
                    src={Marker}
                    style={{ marginRight: "3px" }}
                    alt="google maps marker"
                  />
                  C/ Álvaro de Bazán, 17 - 3,
                  <span> 46010, Valencia, España</span>
                </li>
              </li>
              {/* <li>España</li> */}
            </ul>

            <ul className="box two">
              <h3 className="link_name">{dictionary.footer_organizaations}</h3>

              <li>Wizard Football</li>
              <li>
                <a href="https://elitesports17.com">Elite Sports 17</a>
              </li>
            </ul>

            <ul className="box three">
              <h3 className="link_name">{dictionary.footer_legal}</h3>
              <li>
                <Link to="/legal-disclaimers">
                  {dictionary.footer_legal_disclaimers}
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">{dictionary.footer_privacy}</Link>
              </li>
              <li>
                <Link to="/cookies-policy">{dictionary.footer_cookies}</Link>
              </li>
            </ul>
          </div>

          <div className="data">
            <div className="data-info telephone">
              <div>
                <img src={iconCall} loading="lazy" alt="call-us" />
              </div>
              <div>
                <p>
                  &nbsp;{dictionary.contact_call_us} &nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    <br />
                    &nbsp;&nbsp;+34 641 88 06 97{" "}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    <br />
                    &nbsp;&nbsp;+34 962 43 93 34 &nbsp;(
                    {dictionary.footer_office})
                  </span>
                </p>
              </div>
            </div>

            <div className="data-info opening">
              <div>
                <img src={iconAvailability} loading="lazy" alt="availability" />
              </div>
              <div>
                <p>&nbsp;{dictionary.date_open_min}</p>
              </div>
            </div>
          </div>

          <div className="footer_copy_right">
            <div className="footer-copy_right_data">
              <div className="footer-inner-data">
                <AiOutlineCopyright
                  style={{ position: "relative", top: "2px" }}
                />
                &nbsp; Elite Sports Soccer 18{" . "}
                <span style={{ color: "darkblue" }}>{VERSION}</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
