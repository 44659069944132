import { css } from "@emotion/react";
import { flexGap } from "../../Helpers/flexGap";
import imgBody from "../../../assets/images/background-body.jpg";
import contactUsImg from "../../../assets/images/contactus_back_balon.jpg";

export const saver = (theme) => css`
  padding: 1.8rem;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const contactContainer = (theme) => css`
  align-self: flex-start;
  //border: solid green 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-image: url(${theme.dark ? null : imgBody});
  background-size: cover;

  .contactUsHeaderImg {
    margin-top: 3rem;
    background: rgb(121, 201, 66);
    background: linear-gradient(
      315deg,
      rgba(121, 201, 66, 0.768411759234944) 60%,
      rgba(5, 116, 187, 1) 100%
    );
    width: 100%;

    //border: solid yellow 10px;
    //position: relative;

    .contactUsHeaderTitle {
      margin: auto;
      //border: solid red 3px;
      display: flex;
      justify-content: center;
      max-width: ${theme.contentMaxWidth};

      .span {
        margin-top: 20px;
        margin-bottom: 20px;
        opacity: 0.8;
        padding: 1rem;
        display: flex;
        justify-content: center;
        font-size: 50px;
        text-transform: uppercase;
        color: #fff;
      }

      @media (max-width: 1024px) {
        .span {
          display: none;
        }

        @media (max-width: 580px) {
          .span {
            font-size: 50px;
            border: red solid 5px;
          }
        }
      }
    }
  }

  .contactUsWrapper {
    max-width: 1300px;
    min-width: 180px;
    display: flex;
    //margin-top: 3rem;
    margin: 3rem auto;
    margin-bottom: 3rem;
    justify-content: center;
    align-items: center;
    //border: solid purple 5px;

    @media (max-width: 1173px) {
      position: relative;
      min-width: 280px;
      //width: 700px;

      //border: solid blue 10px;
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 864px) {
      //border: solid yellow 5px;
      //min-width: 650px;
      width: 640px;
    }

    @media (max-width: 656px) {
      width: 340px;
    }
  }

  .contactUsInner {
    height: 975px;
    min-height: 975px;
    width: 520px;
    //border: green solid 5px;

    @media (max-width: 1173px) {
      width: 100%;
    }
  }

  .contactUsText {
    color: #fff;
    margin-bottom: 2rem;
    height: 70%;
    line-height: 2.5rem;

    h3,
    p {
      font-weight: bold;
    }

    h3::after {
      background-color: #79c942;
      content: "";
      display: inline-block;
      height: 2px;
      position: relative;
      left: 10px;
      vertical-align: middle;
      width: 70%;
    }
  }

  .contactUsAddress {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .contactUsPhone {
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .contactUsBtn > button {
    color: #fff;
    padding: 0.4rem;
    font-weight: bold;
    background-color: #79c942;
    border-radius: 10%;
    text-transform: uppercase;
  }

  .social-media-icons {
    height: 40px;
    width: 30px;
    margin: 0 8px;
    color: #fff;
  }

  .social-links {
    //border: solid purple 4px;
    margin-bottom: 2rem;
    height: 30%;
    color: #fff;

    p {
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
    }

    p::after {
      background-color: #79c942;
      content: "";
      display: inline-block;
      height: 2px;
      position: relative;
      left: 10px;
      vertical-align: middle;
      width: 70%;
    }
  }

  .contactUsInfo {
    padding: 1rem;
    //border: pink solid 3px;
    height: 40%;
    background-image: url(${contactUsImg});
    background-size: cover;
  }

  .contactUsFrame {
    //border: red solid 5px;
    filter: ${theme.dark ? "invert(90%)" : null};
  }

  h2 {
    padding: 0 ${theme.padding};
    text-align: center;
  }
  .tab-menu {
    //border: solid purple 5px;
    padding: 0 ${theme.padding};
    .tab {
      max-width: fit-content;
    }
    @media (max-width: 700px) {
      padding: 0 ${theme.paddingM};
      justify-content: stretch;
      .tab {
        max-width: unset;
      }
    }
  }
`;

export const emailStyle = (theme) => css`
  max-width: ${theme.contentMaxWidth};
  padding: ${theme.padding};
  font-size: ${theme.p};
  background-color: ${theme.dark ? "#000000" : "#fff"};
  //border: solid red 3px;
  max-width: 960px;
  position: relative;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0px 1000px ${theme.inputBackgroundColor} inset;
    text-fill-color: ${theme.textColor};
  }
  form {
    display: flex;
    flex-direction: column;

    *:focus-visible {
      background-color: ${theme.inputHoverColor};
    }
    button[type="submit"] {
      //border: red solid 10px;
      font-size: ${theme.p};
      cursor: pointer;
      align-self: flex-end;
      width: calc(30% - 0.2rem);
      margin-top: 2rem;
      padding: 1rem 2rem;
      font-weight: bold;
      border: 2px solid ${theme.accentColor};
      border-radius: ${theme.borderRadius};
      background-color: transparent;
      transition: all ease-in-out 0.5s;
      color: ${theme.dark ? "#fff" : "#000000"};
      //border: solid brown 10px;

      &:hover {
        color: black;
        background-color: ${theme.accentColor};
        transition: all ease-in-out 0.5s;
      }

      @media (max-width: 425px) {
        //border: dotted green 5px;
        border: none;
      }
    }
    .service-category {
      margin-top: 2rem;

      h3 {
        font-weight: bold;
      }
      .select-container {
        ${flexGap("1rem")}
        margin-top: 0;
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
          height: 0;

          &:checked + label {
            background-color: transparent;
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-underline-offset: 0.5rem;
            text-decoration-color: ${theme.accentColor};
          }
          &:focus + label {
            z-index: 3;
            box-shadow: 0 0 0 2px ${theme.accentColor};
          }
        }
        label {
          text-align: center;
          flex-grow: 1;
          box-sizing: border-box;
          display: inline-block;
          padding: 1rem 2rem;
          border: solid #ccc 2px;
          transition: all ease-in-out 0.5s;
          border-radius: ${theme.borderRadius};
          text-decoration-color: transparent;
          color: ${theme.dark ? "#fff" : "#000000"};

          &:hover {
            cursor: pointer;
            background-color: ${theme.inputHoverColor};
            transition: all ease-in-out 0.5s;
          }
        }
      }
    }
    .inner-form {
      width: 100%;
      color: ${theme.textColor};
      //border: solid red 10px;
      label {
        font-weight: bold;
        margin-bottom: 0.8rem;
        :not(:first-of-type) {
          margin-top: 2rem;
        }
      }
      .inputs-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 50%;
        margin-right: 2rem;
        color: ${theme.textColor};

        input {
          border: solid 2px #ccc;
          color: ${theme.dark ? "#000000" : "#000000"};
          /* border: 2px solid ${theme.inputBorderColor}; */
          border-radius: ${theme.borderRadius};
          height: 40px;
          transition: all ease-in-out 0.5s;
          padding: 1rem;
          width: 100%;
        }
        .react-tel-input {
          max-width: unset;
        }
      }
      .message-container {
        //border: solid red 10px;
        display: flex;
        flex-direction: column;
        flex: 1 1 50%;
        margin-top: 2rem;
        margin-right: 2rem;

        textarea {
          box-sizing: border-box;
          border: 2px solid #ccc;
          ///border: none;
          border-radius: ${theme.borderRadius};
          height: 100%;
          padding: 1rem;
          transition: all ease-in-out 0.5s;
        }

        @media (max-width: 697px) {
          //border: red solid 5px;
          margin: 0;
        }
      }
    }
  }
  .lower-box {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: ${theme.dark ? "#fff" : "#000000"};

    @media (max-width: 653px) {
      margin-top: 2rem;
      //border: blue dotted 5px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    a {
      margin-left: 0.5ch;
    }
    button {
      :disabled {
        color: ${theme.secondaryTextColor};
        border-color: ${theme.secondaryTextColor};
        :hover {
          color: ${theme.secondaryTextColor};
          background-color: transparent;
        }
      }
    }
  }

  @media (max-width: 700px) {
    flex-grow: 1;
    width: 100%;
    padding: ${theme.paddingM};
    form {
      .inner-form {
        flex-direction: column;
        .inputs-container {
          margin-right: 0;
          margin-bottom: 2rem;
        }
      }
      button[type="submit"] {
        width: 100%;
      }
      .service-category {
        .select-container {
          display: flex;
          flex-direction: column;
          label {
            margin-right: 0 !important;
            text-align: center;
          }
        }
      }
      .lower-box {
        //flex-direction: column;
        align-items: flex-start;

        .captcha {
          margin: 0 auto 2rem auto;
        }
      }
    }
  }
`;

//eliminar phone style
export const phoneStyle = (theme) => css`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  //padding: ${theme.padding};
  padding-top: 14rem;
  //background-color: #79c942;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0px 1000px ${theme.inputBackgroundColor} inset;
    text-fill-color: ${theme.textColor};
  }
  .telLink {
    margin-top: 1rem;
    padding: 1rem;
    border: 2px solid ${theme.accentColor};
    border-radius: ${theme.borderRadius};
    background: linear-gradient(#79c942, #0574bb);
  }
  h3 {
    text-transform: uppercase;
    color: #0574bb;
  }
  hr {
    margin: 2rem 0;
    width: 50%;
    border-top: 2px dashed ${theme.accentColor};
    background-color: none;
  }
  .call-you-container {
    margin-top: 2rem;
    display: flex;
    width: fit-content;
    align-items: center;
    flex-direction: column;
    border-radius: ${theme.borderRadius};
    background: linear-gradient(#79c942, #0574bb);
    padding: ${theme.padding};
    padding-top: calc(${theme.padding} / 1.5);
    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      justify-content: center;

      .react-tel-input {
        width: 100%;
      }

      button[type="submit"] {
        width: 100%;
        min-width: fit-content;
        padding: 1rem;
        font-size: ${theme.p};
        cursor: pointer;
        color: ${theme.textColor};
        margin-left: 1rem;
        font-weight: bold;
        border: 2px solid ${theme.accentColor};
        border-radius: ${theme.borderRadius};
        background-color: transparent;
        transition: all ease-in-out 0.5s;

        &:hover {
          color: black;
          background-color: ${theme.accentColor};
          transition: all ease-in-out 0.5s;
        }
      }
    }
  }

  @media (max-width: 700px) {
    flex-grow: 1;
    width: 100%;
    padding: ${theme.paddingM};
    .call-you-container {
      padding: ${theme.paddingM};
      h3 {
        text-align: center;
      }
      form {
        flex-direction: column;
        .react-tel-input {
          max-width: 100%;
          .country-list {
            width: 100%;
          }
          .form-control {
            width: 100%;
          }
        }
        button[type="submit"] {
          margin: 0;
          margin-top: 1rem;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
`;
export const addressStyle = (theme) => css`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  .maps-text {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    max-width: ${theme.contentMaxWidth};
    width: 100%;
    flex: 1 1 0;
    iframe {
      border: none;
      flex: 1 1 200px;
      max-width: 50%;
      height: 400px;
      margin-right: 2rem;
    }
    .text-container {
      flex: 1 1 fit-content;
      display: flex;
      flex-direction: column;
      .column-container {
        h3 {
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
        div {
          :not(:first-of-type) {
            margin-top: 2rem;
          }
        }
      }
      .button--maps {
        text-decoration: none;
        justify-self: flex-end;
        margin-top: auto;
        div {
          transition: all ease-in-out 0.2s;
          width: fit-content;
          border-radius: ${theme.borderRadius};
          padding: 1rem;
          background-color: ${theme.inputBackgroundColor};

          :hover {
            background-color: ${theme.inputHoverColor};
          }
          :active {
            background-color: ${theme.inputBackgroundColor};
            box-shadow: inset 0 0 5px 1px #00000030;
          }
        }
      }
    }
  }

  .content {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${theme.padding};

    .inner-content {
      display: flex;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        max-width: 30%;
        margin-right: 2rem;
      }
      .text-container {
        max-width: 45ch;
      }
    }
  }

  @media (max-width: 700px) {
    padding: ${theme.paddingM};
    .maps-text {
      flex-direction: column;
      iframe {
        max-width: 100%;
        flex: 1 1 ${theme.width};
        margin-right: 0;
      }
      .text-container {
        .column-container {
          margin-top: 2rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          div {
            :first-of-type {
              margin-right: 2rem;
            }
          }
          @media (max-width: 470px) {
            flex-direction: column;
            div {
              :first-of-type {
                margin-bottom: 2rem;
              }
            }
          }
        }
        .button--maps {
          width: 100%;
          margin-top: 2rem;
          div {
            text-align: center;
            width: 100%;
          }
        }
      }
    }
    .content {
      padding: ${theme.paddingM};
      .inner-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 2rem;
        }
        .text-container {
          max-width: 100%;
        }
      }
    }
  }
`;

export const telInputStyle = (theme) => css`
  .focus {
    box-shadow: 0 0 0 2px ${theme.accentColor};
    transition: all ease-in-out 0.2s;
  }

  display: flex;
  width: 100%;
  max-width: 35ch;
  border-radius: ${theme.borderRadius};
  font-family: "URWDIN", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  .form-control {
    color: ${theme.textColor};
    border-radius: ${theme.borderRadius};
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    transition: all ease-in-out 0.5s;
    border: solid #ccc 2px;
    padding: 1rem;
    height: 100%;
    //background-color: ${theme.backgroundColorLighter};

    :focus-visible {
      box-shadow: none;
    }
  }
  .flag-dropdown {
    order: -1;
    border: none;
    background-color: transparent;
    position: unset;
    border-radius: ${theme.borderRadius};
    &.open {
      border-radius: ${theme.borderRadius};
    }
    .selected-flag {
      background-color: ${theme.backgroundColorHigh};
      border-top-left-radius: ${theme.borderRadius};
      border-bottom-left-radius: ${theme.borderRadius};
      :hover {
        background-color: ${theme.inputBackgroundColorActive};
      }
      .arrow {
        border-bottom-color: ${theme.textColor};
        border-top-color: ${theme.textColor};
      }
      &.open {
        border-radius: 0;
        background-color: ${theme.backgroundColorHigh};
        border-top-left-radius: ${theme.borderRadius};
        border-bottom-left-radius: ${theme.borderRadius};
      }
    }
  }

  .country-list {
    width: 35ch;
    border-radius: ${theme.borderRadius};
    background-color: ${theme.backgroundColorLighter};
    box-shadow: 0 0 10px -5px ${theme.dark ? "transparent" : theme.textColor};
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 12px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.dark
        ? theme.inputBackgroundColorActive
        : theme.inputBackgroundColor};
      border-radius: 6px;
      height: 40px;
      width: 5px;
    }
    .country {
      :hover:not(.highlight) {
        background-color: ${theme.inputBackgroundColorActive};
        .dial-code {
          color: ${theme.dark ? theme.textColor : "initial"};
        }
      }
      &.highlight {
        background-color: ${theme.accentColor};
      }
    }
  }

`;
