export let BASE_URL = "https://api.elitesoccer18.com/";
export let BASE_URL_IMG = "https://img.elitesoccer18.com/";




// Setear variable en dependencia del modo

// Códigos de modos
// 1 => Production
// 2 => Dev
// 3 => Dev con DNS
/* let DNS = "f2f7-83-39-205-111"; */

let DNS = "340f-88-17-129-159";
let DEVELPMENT_MODE = 1

if (DEVELPMENT_MODE === 2) {
  BASE_URL = "http:///192.168.1.46/elitesports17-ess18/";
  BASE_URL_IMG = "http://192.168.1.46/img.elitesoccer18/";
}

if (DEVELPMENT_MODE === 3) {
  BASE_URL = `http://${DNS}.ngrok-free.app/ESS18N/players.test?`;
  BASE_URL_IMG = `http://${DNS}.ngrok-free.app/imgs/`;
}
