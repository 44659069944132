/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { useTransition } from "@react-spring/core";
import { animated } from "@react-spring/web";

function Notification({ state }) {
  const style = (theme) => css`
    position: absolute;
    background-color: ${theme.accentColor};
    padding: 1rem 2rem;
    border-radius: ${theme.borderRadius};
    top: calc(100%);
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    margin: auto;
  `;

  const errorStyle = css`
    background-color: #ff4141;
    color: white;
  `;

  const transition = useTransition(state, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  return transition((animation, state) =>
    state.state === "success" ? (
      <animated.div style={animation} css={style}>
        {state.msg}
      </animated.div>
    ) : (
      state.state === "error" && (
        <animated.div style={animation} css={[style, errorStyle]}>
          {state.msg}
        </animated.div>
      )
    )
  );
}

export default Notification;
