/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import { youtubeEmbedStyle } from "./youtubeEmbedStyle";

const YoutubeEmbed = ({ embedID }) => (
  <div css={youtubeEmbedStyle}>
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedID}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      loading="lazy"
    />
  </div>
);

export default YoutubeEmbed;
