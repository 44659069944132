/** @jsxRuntime classic */
/** @jsx jsx */

import Button from "./Button";
import { cookies, buttonContainer } from "./cookiesStyle";
import { jsx } from "@emotion/react";
import useCookiesLS from "./Hooks/useCookiesLS";
import { animated } from "@react-spring/web";
import { useContext } from "react";
import { Context } from "./Context";
import parse from "html-react-parser";
function Cookies() {
  const [transition, handleClick] = useCookiesLS();
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  return transition(
    (props, cookiesAccepted) =>
      cookiesAccepted === null && (
        <animated.div style={props} css={cookies}>
          <h3>{dictionary.cookies_title}</h3>
          <div className="legal-text">{parse(dictionary.cookies_txt)}</div>
          <div css={buttonContainer}>
            <Button
              type="accept"
              clickHandle={() => handleClick(false)}
              link={false}
            >
              {dictionary.read_more}
            </Button>
            <Button
              type="accept"
              clickHandle={() => handleClick(true)}
              link={false}
            >
              {dictionary.footer_accept}
            </Button>
          </div>
        </animated.div>
      )
  );
}

export default Cookies;
