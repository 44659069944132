import { css } from "@emotion/react";
import footerImg from "../../src/assets/images/background-footer.jpg";

export const footer = (theme) => css`
  label: footer-content;
  width: 100%;
  background-image: url(${theme.dark ? null : footerImg});
  background-color: ${theme.dark ? "#303841" : null};

  li {
    margin-bottom: 1rem;
    color: #fff;
  }

  a {
    color: #fff;
  }

  .content {
    max-width: ${theme.contentMaxWidth};
    margin: auto;
    padding: ${theme.padding};
    margin-top: -20px;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .logo-details {
    color: #fff;
  }

  .media-icons {
    display: flex;
  }

  .social-media-icons {
    height: 40px;
    width: 40px;
    margin: 0 8px;
    color: #fff;
  }

  .link-boxes {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .box {
    border-left-style: solid;
    border-color: #79c942;
    padding-left: 1.6rem;
    border-width: 0.3rem;
  }

  .link_name {
    color: #fff;
    margin-bottom: 10px;
    postion: relative;
    text-transform: uppercase;
    font-weight: bold;
  }
  .link_office {
    font-size: 14px;
    color: gray;
  }

  .start_conversation {
    display: flex;
    margin-top: 20px;
    color: #fff;

    h2::after {
      content: "";
      background-color: #828282;
      display: inline-block;
      height: 2px;
      position: relative;
      vertical-align: middle;
      width: 90%;
      left: 11em;
      margin: -90%;
    }
  }

  .data {
    display: flex;
    margin-left: -20px;
    margin-top: 2rem;
    margin-bottom: -1rem;
    @media(max-width: 550px) {
      margin-top: 6rem;
    }
    p {
      color: #fff;
      font-size: 20px;
      @media (max-width: 464px) {
        font-size: 15px;
      }
    }
    
    .data-info {
      margin: 20px;
      display: flex;
      align-items: center;
      vertical-align: middle;
      justify-content: center;
    }
    .telephone{
      margin-right: 15.5rem;
    }
  }


  .footer_copy_right {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    padding: 1.5rem;

    .footer-copy_right_data {
      border-style: solid;
      color: #fff;
      border-width: 2px;
      width: 380px;
      border-color: #828282;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .footer-inner-data {
      display: flex;
      padding: 10px;
    }
  }

  @media (max-width: 426px) {
    .data {
      width: 100%;
      display: flex;
      flex-direction:column;
      
      .data-info {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        text-align: flex-start;
      }
    }
    .media-icons {
      display: flex;
      position: relative;
      right: 2.2rem;
    }
  }



  @media (max-width: 800px) {
    .top {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .media-icons {
      display: flex;
      padding: 2em;
    }
    .data {
      display: flex;
      flex-wrap: wrap;
    }

  }
  @media ( max-width: 1080px ) {
      .link-boxes{
        display: grid;
        grid-template-rows: (2, 1fr):
        grid-gap: 1rem;
      }
      .one {
        margin-bottom: 1rem;
        grid-column: 1/2;
        grid-row: 1;
      }
      .two {
        grid-column: 2/3;
        grid-row: 1;
      }
      .three {
        grid-column: 1/2;
        grid-row: 2;
      }
      .telephone {
        margin-right: 8rem !important;
      }
  }

  @media ( max-width: 890px ) {
      .link-boxes{
        display: flex;
        flex-direction: column;
      }
      .two {
        margin-bottom: 1rem;
      }

  }
`;
