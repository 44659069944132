import React, { useState } from "react";
import { Skeleton } from "@material-ui/lab";

const LoadingImg = ({ baseUrl }) => {
  const [loaded, setloaded] = useState(false);
  return (
    <>
      <div className="img-container">
        <img
          src={baseUrl}
          alt="player"
          loading="lazy"
          onLoad={() => setloaded(true)}
        />
      </div>
      {!baseUrl && !loaded && (
        // some element that would overlay our loading image and disappear once the lazyloaded image has loaded
        <Skeleton animation="wave" width={"910rem"} height={440} />
      )}
    </>
  );
};

export default LoadingImg;
