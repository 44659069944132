/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../Context";
import { newsCardStyle } from "./newsCardStyle";
import newsBackgroundImg from "../../../assets/images/img-ess18-logo_white.png";
import spinner from "../../../assets/images/loading.gif";

function NewsCard({ title, text, url, daytime, img }) {
  const {
    dictionary: { dictionary },
  } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simula una carga asincrónica del componente
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <a
      href={url}
      target="blank"
      css={newsCardStyle}
      className="news-card-container"
    >
      <div className="news-card">
        {/*         <div className="container-img">
        {isLoading ? (
        <img src={spinner} alt="Loading spinner" />
      ) : (
          <img src={img} alt="coachImg" loading="lazy" className="coachImg" />
      )}
          </div> */}

        <div className="newsText">
          <img
            src={newsBackgroundImg}
            alt="newsImg"
            className="newsTextImg"
            loading="lazy"
          />
          <h3>{title}</h3>
          <h4>
            {dictionary.news_published}:{" "}
            {new Date(daytime).toLocaleDateString()}
          </h4>
          <p>{text}</p>
        </div>
      </div>
    </a>
  );
}

export default NewsCard;
