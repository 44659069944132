import { css } from "@emotion/react";
import backgroundBody from "../../../assets/images/background-body.jpg";

export const headerStyle = (theme) => css`
  width: 100%;
  height: 100%;
  position: relative;
  top: 1rem;
  align-content: center;
  background-image: url(${theme.dark ? null : backgroundBody});
  background-size: cover;

  .socialTimeline {
    margin-top: 1.2rem;
    margin-left: 0.5rem;
    width: 14.5%;
  }

 @media(max-width: 1440px) {
   .socialTimeline{
     display: none;
   }
 }
 
  .newsHeaderImg {
    margin-top: 3rem;
    background: rgb(121,201,66);
    background: linear-gradient(315deg, rgba(121,201,66,0.768411759234944) 60%, rgba(5,116,187,1) 100%);

    .newsHeaderTitle {
      margin: auto;
      display: flex;
      flex-direction: column; 
      max-width: ${theme.contentMaxWidth};

      span {
        margin-top: 20px;
        margin-bottom: 20px;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        font-size: 50px;
        text-transform: uppercase;  
        color: #fff;
      }

    
      @media (max-width: 1024px) {
        span {
          display: flex;
          display: none;
          justify-content: center;    
          font-size: 110px;
         
      }    

    }

    @media (max-width: 500px) {
      span {
        font-size: 50px;
       
      }
    }
  }
`;

export const containerStyle = (theme) => css`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.2rem;

  max-width: ${theme.contentMaxWidth}; // no borrar, de esta forma centramos elementos
  .news {
    //flex: 1 1 5%;
    //border: solid green 8px;


    a {
      text-decoration: none;
    }

    .newsText {
      color: #fff;
      //border: solid red 3px;
      min-width: 300px;
      padding: 1rem;
      background-color: #01173e;
      min-height: 200px;
      background-size: cover;
      width: 100%;
      height:expand;

      h3 {
        text-transform: uppercase;
        position: relative;
      }
      h4 {
        position: relative;
      }

      p {
        position: relative;
        font-size: 2.2ch;
      }

      .newsTextImg {

        position: absolute;
        max-height: 7%;
        height: 5%;
        max-width: 70%;
        opacity: 0.1;

      }
    }

    .news-card {

      display: flex;
      width: 100%;


      @media (max-width: 1179px) {
        background-color: green;
        .container-img {
          display: none;
        }
      }

      .container-img {
        height:100%;

        .coachImg {
          min-height: 200px;
          width: 320px;
          height:100%;
          background-color: white;
          padding: 0px 0px 0px 0px;
          display:block;

        }
      }

      margin-bottom: 2rem;
      padding: 0;

      h4 {
        color: ${theme.secondaryTextColor};
      }
      :hover {
        cursor: pointer;
        h3 {
          text-decoration: underline;
          text-underline-offset: 0.2rem;
          text-decoration-color: ${theme.accentColor2};
        }
      }
      h3 {
        font-weight: bold;
      }
    }
  }
`;
