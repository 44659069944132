/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { config } from "react-spring";
import { useContext, useState } from "react";
import AnimatedNavLink from "./AnimatedNavLink";
import { linksContainer } from "./hoverLinksStyle";
import { jsx } from "@emotion/react";
import { Context } from "../Context";

const HoverLinks = React.forwardRef(({ names, setOpen }, ref) => {
  const configuration = {
    y: -2,
    rotation: 45,
    originalRotation: 45,
    springConfig: config.wobbly,
  };

  const links = names.map((name) => {
    return (
      <AnimatedNavLink
        name={name}
        config={configuration}
        key={name[0]}
        setOpen={setOpen}
        timeout={250}
      />
    );
  });

  return (
    <div ref={ref} css={linksContainer}>
      {links}
    </div>
  );
});

export default HoverLinks;
