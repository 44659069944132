import { css } from "@emotion/react";
import backgroundBody from "../../../assets/images/background-body.jpg";
import playerBackgroundImg from "../../../assets/images/img-player-container.png";
import backgroundPlayersImg from "../../../assets/images/background-body.jpg";
import playerBackground from "../../../assets/images/img-player-container.png";
import coachNameBackground from "../../../assets/images/img-home_coaches-container_name.png";





export const headerStyle = (theme) => `css
    width: 100%;
    height: 100%;
    position: relative;
    top: 0.4rem;
    
  
    .playersHeaderImage {    
      margin-top: 3rem;
      background: rgb(121,201,66);
      background: linear-gradient(189deg, rgba(121,201,66,0.768411759234944) 60%, rgba(5,116,187,1) 100%);
     
     .playerHeaderTitle {
      margin: auto;
      display: flex;
      flex-direction: column; 
      max-width: ${theme.contentMaxWidth};

      span {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        opacity: 0.8;
        font-size: 50px;
        text-transform: uppercase;  
        color: #fff;
     
      }
      // .span{

      //   @media( max-width: 900px){
      //     margin-top:10px;
      //     font-size:40px;
      //     width:90%;
      //     margin-left:20px;
      //   }
      // }

      
      @media (max-width: 1020px) {
        span {
          display: none;       
        }
      }
     
     }         
    
    }

    .spinner{
      position: relative;
    }
    .spinner-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .playersBackgroundImg {
      border: solid 0.2px ${theme.dark ? "#303841" : "#fff"};
      background-image: url(${theme.dark ? null : backgroundBody});
      background-size: cover;
     
    } 
    .playersTitle{
      margin-top:10px;
      font-size:40px;
      margin-bottom:30px;
     width:90%;
     margin-left:20px;
    }
    @media(min-width: 1024px) {
      .playersTitle{
        display: none;
     
      }
    }
    
  `;
export const style = (theme) => css`
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  // max-width: ${theme.contentMaxWidth};
  // padding: ${theme.padding};

  .players-at-home {
    margin: 0 auto;
    .image-players {
    }
  }

  @media (max-width: 700px) {
    padding: ${theme.paddingM};
  }
  .tab-menu {
    width: 65%;
    @media (max-width: 900px) {
      width: 90%;
    }
  }
  .go-back-btn {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 65%;

    @media (max-width: 900px) {
      width: 90%;
      margin-left: 20px;
    }
  }
  .less-width {
    @media (min-width: 900px) {
      width: 65% !important;
    }
    @media (max-width: 1650px) {
      h3 {
        font-size: 1.3rem !important;
      }
    }
    @media (max-width: 650px) {
      h3 {
        font-size: 1rem !important;
      }
    }
  }
  .players-container {
    .title-group {
      font-size: 36px;
      margin-top: 20px;
      margin-bottom: 40px;
      @media (min-width: 900px) {
        font-size: 50px;
      }
    }
    // margin-left: 0.5rem;
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    // max-width: ${theme.contentMaxWidth};
    overflow: hidden;
    @media (min-width: 900px) {
      width: 65%;
    }

    // .tab-menu {
    //   @media (max-width: 900px) {
    //   }
    // }
  }

  .back-button {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    /* margin-left: 0.5rem; */
    cursor: pointer;
    width: fit-content;
    color: ${theme.accentColor2};
    text-decoration: none;
    .icon-container {
      svg {
        margin-top: -1rem;
        display: block;
      }
    }
    p {
      //margin: 0.4rem 0.5rem;
      //margin-bottom: 4rem;
    }
  }

  .player-card {
    border-radius: ${theme.borderRadius};
    cursor: pointer;
    display: flex;
    position: absolute;
    //will-change: transform, width, height, opacity;
    padding: 1rem;
    z-index: auto;
    @media (max-width: 700px) {
      padding: 0.5rem;
      h3 {
        font-size: ${theme.p};
      }
    }

    @media (max-width: 550px) {
      h3 {
        display: none;
      }
    }

    h3 {
      font-weight: bold;
    }
    ::after {
      content: "+";
      position: absolute;
      color: white;
      top: 10px;
      right: 10px;
      background-color: ${theme.accentColor2};
      padding: 0.5rem 1rem;
      display: block;
      opacity: 0;
      border-radius: 100px;
      transform-origin: right;
      transform: translate3d(0, -10px, 0);
      transition: all ease-in-out 0.3s;

      @media (max-width: 410px) {
        content: "+";
        padding: 0;
        width: 2rem;
        height: 2rem;
        padding: 0.36rem;
        font-size: ${theme.p};
        text-align: center;
        min-width: fit-content;
      }
    }
    :hover {
      z-index: 20;
      // ::after {
      //   display: initial;
      //   opacity: 1;
      //   transform: translate3d(0, 0, 0);
      //   transition: all ease-in-out 0.3s;
      // }

    }
    .text-container {
      height: 52px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      background: url(/static/media/img-home_coaches-container_name.a6da8482.png);
      color: #fff;
      width: 100%;
      z-index: 2;
      position: absolute;
      bottom: -20px;
      left: 0;
      text-align: center;
      font-weight: bold;
    }
    @media (max-width: 700px) {
      .css-o0jic-Home .player-card .image-players img {
        transform: translateY(0px) !important;
      }
      .css-1uqhciu .player-card .image-players img {
        transform: translateY(0px) !important;
      }
      .css-j6bblv .player-card .image-players img{
        transform: translateY(0px) !important;
      }
      .css-i2bele-Home .player-card .image-players img {
        transform: translateY(0px) !important;
      }
    }
    

    .image-players {
      background-image: url(${playerBackgroundImg});
      background-size: cover;
      position: absolute;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: ${theme.borderRadius};
      overflow: hidden;
      ::after {
        content: "";
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media (min-width: 1700px) {
          background-image: linear-gradient(
            rgba(255, 255, 255, 0) 75%,
            ${theme.dark ? "black" : "#ffffffff"}
          );
        }
        // @media (min-width: 2100px) {
        //   background-image: linear-gradient(
        //     rgba(255, 255, 255, 0) 75%,
        //     ${theme.dark ? "black" : "#ffffffff"}
        //   );
        // }
        /*opacity: 0.8; */
      }

      // img {
      //   object-fit: cover;
      //   width: 120%;
      //   height: 120%;
      //   opacity: 1;
      //   .player-home {
      //     // transform: translateX(-20px) !important;
      //     transform: translateY(50px) !important;
      //   }
      //   .player-page {
      //     transform: translateX(-20px) !important;
      //     // transform: translateY(50px) !important;
      //   }
      //   @media (max-width: 700px) {
      //     transform: translateY(30px);
      //   }
      // }
    }

    .name-player {
      position: absolute;
      bottom: -30px;
      font-weight: bold;
    }
    .player-home {
      img {
        object-fit: cover;
        width: 120%;
        height: 100%;
        opacity: 1;
        transform: translate(-20px, 30px) !important;
        // transform: translateZ(-50px) !important;
        @media (max-width: 650px) {
          transform: translate(-5px, 40px) !important;
        }
      }
    }
    .player-page {
      display:flex;
      align-items:center;

      img {

        height:100%;
        width:155%;
        opacity: 1;
        margin-bottom:0px;
        // transform: translate(-40px, 50px) !important;

        // transform: translateY(50px) translateX(-40px) !important;
        transform: translateY(40px) !important;

        @media (max-width: 450px) {
          margin-left:90px;
          transform: translateY(40px) !important;
          margin-bottom:0px;

        }
        @media (max-width: 650px) {
          transform: translateY(40px) !important;
          margin-bottom:0px;

        }
        @media (max-width: 2000px) {
          margin-left:-50px;
          margin-bottom: 0px;
        }
        @media (min-width: 2000px) {
          margin-bottom:-75px;
          margin-left:-70px;
        }
      }
    }


    .new-player-card {
      background-image: url(${playerBackgroundImg});

      // .img {
      //   width: 300px;
      //   height: 140px;
      // }
    }
    .new-player-container {
      display: grid;
      grid-template-columns:
        1fr 1fr
        minmax(10px, 1fr) 3fr
        repeat(5, 1fr)
        50px auto 100px 1fr;
    }
  }

.img-container{
  object-fit: none;
  display: flex;
  justify-content:center;
  z-index: 1;
  border-radius: 6px;
    max-width: none;
    width: 100%;
    height: 100%;

}

`;
