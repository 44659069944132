import { useContext } from "react";
import { Context } from "../../Context";

function useTranslateNews(){
  const {dictionary:{dictionary}} = useContext(Context);
  const news = [
    {
      title: dictionary.news_1_title,
      brief:
      dictionary.news_1_txt,
      url: "https://esportbase.valenciaplaza.com/2017/08/10/empresario-rodney-meaker-presenta-su-ambicioso-proyecto-para-ontinyent-cf/",
      date: new Date("2017-09-10"),
    },
    {
      title: dictionary.news_2_title,
      brief:
        dictionary.news_2_txt,
      url: "https://www.sportcartagena.es/articulo/todo-futbol/presidente-ontinyent-tiene-solo-26-anos-representa-fondo-inversion-australia/20171129184823062217.html",
      date: new Date("2017-11-29"),
    },
    {
      title: dictionary.news_3_title,
      brief:
        dictionary.news_3_txt,
      url: "https://www.grada3.com/2017/12/29/ontinyent-se-confirma-equipo-revelacion-la-primera-vuelta/",
      date: new Date("2017-12-29"),
    },
    {
      title: dictionary.news_4_title,
      brief:
        dictionary.news_4_txt,
      url: "https://iusport.com/art/40094/el-ontinyent-consigue-un-agonico-ascenso-a-segunda-b-frente-al-alaves-b",
      date: new Date("2017-06-25"),
    },
    {
      title: dictionary.news_5_title,
      brief:
        dictionary.news_5_txt,
      url: "https://www.levante-emv.com/fotos/la-costera/2017/06/27/celebracion-ascenso-segunda-b-ontinyent-11619712.html#foto=1",
      date: new Date("2017-06-27"),
    },
    {
      title: dictionary.news_6_title,
      brief:
      dictionary.news_6_txt,
      url: "https://golsmedia.com/sin-categoria/2017/12/26/2017-ano-grandes-alegrias-ontinyent-cf/",
      date: new Date("2017-12-26"),
    },
    {
      title: dictionary.news_7_title,
      brief:
      dictionary.news_7_txt,
      url: "https://www.superdeporte.es/futbol/2018/03/15/ontinyent-historia-saguntino-queda-puertas/366622.html",
      date: new Date("2018-03-15"),
    },
    {
      title: dictionary.news_8_title,
      brief:
      dictionary.news_8_txt,
      url: "https://www.superdeporte.es/futbol/2018/05/13/saguntino-desciende-ontinyent-jugara-copa/372990.html",
      date: new Date("2017-07-12"),
    },
    {
      title: dictionary.news_9_title,
      brief:
      dictionary.news_9_txt,
      url: "https://www.superdeporte.es/futbol/2017/06/27/ontinyent-cf-suena-subir-primera/340398.html",
      date: new Date("2017-06-27"),
    },
    {
      title: dictionary.news_10_title,
      brief:
      dictionary.news_10_txt,
      url: "https://www.lasprovincias.es/ribera-costera/ontinyent-premia-fidelidad-20170712002953-ntvo.html?ref=https:%2F%2Fwww.google.com%2F",
      date: new Date("2017-06-27"),
    },
    {
      title: dictionary.news_11_title,
      brief:
      dictionary.news_11_txt,
      url: "https://www.yosoynoticia.es/futbol/oscuro-proceso-de-sad-firmas-falsificadas-y-deuda-de-mas-de-1-millon-de-euros-el-futuro-del-ontinyent-cf-en-juego",
      date: new Date("2017-06-27"),
    },
  ];
  return news;
}


export default useTranslateNews;
