/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import { Context } from "../../Context";
import ScrollToTopOnMount from "../../Helpers/ScrollToTopOnMount";
import Chapter from "./Chapter";
import { legalContainer } from "./style";
import parse from "html-react-parser";

const customCSS = css`
  label: custom;
  column-width: unset !important;
  max-width: 65ch;
`;
function PrivacyPolicy() {
  const {
    dictionary: { dictionary },
  } = useContext(Context);
  return (
    <div style={{ paddingTop: 100 }} css={legalContainer}>
      <ScrollToTopOnMount />
      <Chapter title={parse(dictionary.privacy_responsability_title)}></Chapter>
      <p>{dictionary.privacy_policy_introduction}</p>
      <ul style={{ listStyle: "inside" }}>
        <li>
          {dictionary.legal_tittle_of_website_company_name}: Elite Sports 17
          S.L. con CIF B 87817359 {dictionary.privacy_policy_titular_following}
          {/* <b>ELITE SPORTS 17, S.L.</b> (en adelante, "<b>el titular</b>") */}
        </li>
        <br />
        <li>
          Teléfono: 962 439334 - 641880697
          {/* {dictionary.legal_tittle_of_website_population}: C/Álvaro de Bazan
          17-3, 46010, Valencia */}
        </li>
        <br />
        <li>Dirección: C/ Álvaro de Bazán 17-3; 46010; VALENCIA</li>
        {/* <li>CIF: B87817359</li> */}
        <br />
        <li>
          {dictionary.legal_tittle_of_website_mail}:{" "}
          <a href="mailto:privacidad@elitesoccer18.com">
            privacidad@elitesoccer18.com{" "}
          </a>{" "}
          {/* {dictionary.legal_tittle_of_website_mail}:
          <a href="mailto:privacidad@elitesports17.com">
            privacidad@elitesports17.com
          </a> */}
        </li>
        <br />
      </ul>
      <Chapter
        title={dictionary.privacy_policy_titular_title}
        config={customCSS}
      >
        {/* <Chapter title={dictionary.privacy_data_title} config={customCSS}> */}
        {dictionary.privacy_policy_finality_of_data}
        <br />
        <br />
        {dictionary.privacy_policy_finality_of_data_text1}
        <br />
        <br />
        {dictionary.privacy_policy_finality_of_data_text2}
        {/* <ul style={{ listStyle: "inside" }}>
          <li>{parse(dictionary.privacy_list_data_txt1)}</li>
          <br />
          <li>{parse(dictionary.privacy_list_data_txt2)}</li>
          <br />
          <li>{parse(dictionary.privacy_list_data_txt3)}</li>
          <br />
          <li>{parse(dictionary.privacy_list_data_txt4)}</li>
          <br />
        </ul> */}
      </Chapter>
      <Chapter title={dictionary.privacy_policy_data_treatment_title}>
        <p>{dictionary.privacy_policy_data_treatment_text1}</p>
        {/* <Chapter title={dictionary.privacy_personal_title}> */}
        <ul style={{ listStyle: "inside" }}>
          <li>{dictionary.privacy_policy_data_treatment_list1_text1}</li>
          <br />
          <li>{dictionary.privacy_policy_data_treatment_list1_text2}</li>
          <br />
          <li>{dictionary.privacy_policy_data_treatment_list1_text3}</li>
        </ul>
        <br />
        <p>{dictionary.privacy_policy_data_treatment_text2}</p>
      </Chapter>
      <Chapter title={dictionary.privacy_policy_personal_data_title}>
        <p>{dictionary.privacy_policy_personal_data_text1}</p>
        <p>{dictionary.privacy_policy_personal_data_text2}</p>
        <p>{dictionary.privacy_policy_personal_data_text3}</p>
        {/* <Chapter title={dictionary.send_data_title}> */}
        {/* <p>{parse(dictionary.send_data_txt1)}</p> */}
      </Chapter>
      <Chapter title={dictionary.policy_links_title}>
        <p>{dictionary.policy_links_text1}</p>
        <p>{dictionary.policy_links_text2}</p>
        {/* <Chapter title={dictionary.protection_data_title}> */}
        {/* <p>{parse(dictionary.protection_data_txt1)}</p> */}
      </Chapter>
      <Chapter title={dictionary.personal_data_conservation_title}>
        <p>{dictionary.personal_data_conservation_text1}</p>
        <p>{dictionary.personal_data_conservation_text2}</p>
        {/* <Chapter title={dictionary.protection_rights_title}> */}
        {/* <p>{dictionary.protection_rights_title_continue}</p> */}
        {/* <ul style={{ listStyle: "inside" }}>
          <li>{parse(dictionary.protection_rights_title_txt1)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt2)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt3)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt4)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt5)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt6)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt7)}</li>
          <br />
        </ul> */}

        {/* <p>{parse(dictionary.protection_rights_txt2)}</p>
        <p>{parse(dictionary.protection_rights_txt3)}</p> */}
      </Chapter>
      <Chapter title={dictionary.secret_security_personal_data_title}>
        <p>{dictionary.secret_security_personal_data_text1}</p>
        <p>{dictionary.secret_security_personal_data_text2}</p>
        {/* <Chapter title={dictionary.modification_information_title}> */}
        {/* <p>{dictionary.modification_information_txt1}</p> */}
      </Chapter>
      <Chapter title={dictionary.destination_personal_data_title}>
        <p>{dictionary.destination_personal_data_text1}</p>
      </Chapter>
      <Chapter title={dictionary.right_users_title}>
        <p>{dictionary.right_users_text1}</p>
        {/* <p>{parse(dictionary.right_users_text2)}</p> */}
        <p>
          {dictionary.right_users_text3} C/ Álvaro de Bazán 17-3; 46010;
          VALENCIA {dictionary.right_users_text4}:{" "}
          <a href="mailto:privacidad@elitesoccer18.com">
            privacidad@elitesoccer18.com
          </a>
        </p>
        <p>
          {dictionary.right_users_text5}{" "}
          <a href="https://www.agpd.es" target="_blank">
            www.agpd.es
          </a>
          , Autoridad de Control de España.
        </p>
      </Chapter>
      {/* <Chapter></Chapter> */}
      <p>{parse(dictionary.legal_update)}</p>
      {/* <Chapter title={parse(dictionary.privacy_responsability_title)}></Chapter>
      <ul style={{ listStyle: "inside" }}>
        <li>
          {dictionary.legal_tittle_of_website_company_name}:{" "}
          <b>ELITE SPORTS SOCCER 18 S.L.</b>{" "}
          {parse(dictionary.legal_ttitle_titular)}
        </li>
        <br />
        <li>
          {dictionary.legal_tittle_of_website_population}: C/Álvaro de Bazan
          17-3, 46010, Valencia
        </li>
        <br />
        <li>CIF: B40530677</li>
        <br />
        <li>
          {dictionary.legal_tittle_of_website_mail !== undefined
            ? dictionary.legal_tittle_of_website_mail
            : "Email Address"}
          :{" "}
          <a href="mailto:privacidad@elitesoccer18.com">
            privacidad@elitesoccer18.com
          </a>
        </li>
        <br />
      </ul>
      <Chapter title={dictionary.privacy_data_title} config={customCSS}>
        <ul style={{ listStyle: "inside" }}>
          <li>{parse(dictionary.privacy_list_data_txt1)}</li>
          <br />
          <li>{parse(dictionary.privacy_list_data_txt2)}</li>
          <br />
          <li>{parse(dictionary.privacy_list_data_txt3)}</li>
          <br />
          <li>{parse(dictionary.privacy_list_data_txt4)}</li>
          <br />
        </ul>
      </Chapter>
      <Chapter title={dictionary.privacy_personal_title}>
        <ul>
          <li>{parse(dictionary.privacy_personal_txt1)}</li>
          <li>{parse(dictionary.privacy_personal_txt2)}</li>
        </ul>
      </Chapter>
      <Chapter title={dictionary.send_data_title}>
        <p>{parse(dictionary.send_data_txt1)}</p>
      </Chapter>
      <Chapter title={dictionary.protection_data_title}>
        <p>{parse(dictionary.protection_data_txt1)}</p>
      </Chapter>
      <Chapter title={dictionary.protection_rights_title}>
        <p>{dictionary.protection_rights_title_continue}</p>
        <ul style={{ listStyle: "inside" }}>
          <li>{parse(dictionary.protection_rights_title_txt1)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt2)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt3)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt4)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt5)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt6)}</li>
          <br />
          <li>{parse(dictionary.protection_rights_title_txt7)}</li>
          <br />
        </ul>

        <p>{parse(dictionary.protection_rights_txt2)}</p>
        <p>{parse(dictionary.protection_rights_txt3)}</p>
      </Chapter>
      <Chapter title={dictionary.modification_information_title}>
        <p>{dictionary.modification_information_txt1}</p>
      </Chapter>
      <p>{parse(dictionary.legal_update)}</p> */}
    </div>
  );
}

export default PrivacyPolicy;
